import { calcTEEDay, calcTEERKcalh } from "./patientCalcs";
import { blockCalc, blockIsMain, calcExerciseMinutes, calcRequeriments } from "./structuresCalcs";


export async function getStructureNt(structure, patient) {
    let blocks = [...structure?.blocks] || [];
    let kcals = 0;
    let carbohydrates = 0;
    let protein = 0;
    let fats = 0;
    let fiber = 0;
    let kcalsIntake = 0;
    let requeriments = 0;
    let totalExerciseTime = 0, totalBurnedKcals = 0, totalBurnedFats = 0;

    for (let i = 0; i < blocks.length; ++i) {
        let block = blocks[i];
        const calcs = await blockCalc(block, patient);

        block.kcals = calcs?.kcals;
        block.carbohydrates = calcs?.carbohydrates;
        block.proteins = calcs?.proteins;
        block.fats = calcs?.fats;
        block.fiber = calcs?.fiber;

        const blockForw = (i+1 !== blocks?.length) ? blocks[i+1] : null;
        if (block?.isIntake) {
            if (block?.name === blockForw?.name && !blockIsMain(block) && !blockIsMain(blockForw)) {
                let kcalsSum = block?.kcals;
                let carbohydratesSum = block?.carbohydrates;
                let proteinSum = block?.proteins;
                let fatsSum = block?.fats;
                let fiberSum = block?.fiber;
                let counter = 1;

                const name = block.name;
                for (let j = i+1; j < blocks?.length; ++j) {
                    let actBlock = blocks[j];
                    if (actBlock?.name === name && !blockIsMain(actBlock) && !blockIsMain(actBlock)) {
                        const calcsAct = await blockCalc(actBlock, patient);
                        
                        actBlock.kcals = calcsAct?.kcals;
                        actBlock.carbohydrates = calcsAct?.carbohydrates;
                        actBlock.proteins = calcsAct?.proteins;
                        actBlock.fats = calcsAct?.fats;
                        actBlock.fiber = calcsAct?.fiber;

                        kcalsSum += calcsAct.kcals;
                        carbohydratesSum += calcsAct.carbohydrates;
                        proteinSum += calcsAct.proteins;
                        fatsSum += calcsAct.fats;
                        fiberSum += calcsAct.fiber;
                        counter++;
                    } else break;
                }
                
                kcals += kcalsSum/counter;
                kcalsIntake += kcalsSum/counter;
                carbohydrates += carbohydratesSum/counter;
                protein += proteinSum/counter;
                fats += fatsSum/counter;
                fiber += fiberSum/counter;
                i += counter-1;
            } else {
                kcals += calcs?.kcals;
                kcalsIntake += calcs?.kcals;
                carbohydrates += calcs?.carbohydrates;
                protein += calcs?.proteins;
                fats += calcs?.fats;
                fiber += calcs?.fiber;
            }
        } else {
            kcals = kcals - calcs?.kcals;
            totalBurnedKcals += parseFloat(calcs?.kcals);
            totalBurnedFats += parseFloat(calcs?.fats);
            totalExerciseTime += calcExerciseMinutes(block);
        }
    }

    const teekcalh = calcTEERKcalh(patient?.teeRest || 0);

    requeriments = calcTEEDay(
        teekcalh || 0, 
        (totalExerciseTime || 0) / 60, 
        totalBurnedKcals || 0
    )

    const reqs = calcRequeriments(requeriments, patient, totalExerciseTime, totalBurnedFats)
    // console.log(reqs)
    // console.log(requeriments, patient, totalExerciseTime, totalBurnedFats)

    const newStructure = {
        ...structure,
        kcal: kcals,
        carbohydrates: carbohydrates,
        protein: protein,
        fats: fats,
        fiber: fiber,
        requeriments: requeriments,
        reqCho: reqs.reqCho,
        reqPro: reqs.reqPro,
        reqFat: reqs.reqFat,
        totalBurnedKcals: totalBurnedKcals,
        kcalsIntake
    };
    // setStructure(newStructure);
    // setRefresh(true);
    return {...newStructure}; 
}