import {
	Button,
	TextField,
	Tooltip,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { ArrowDownward, ArrowUpward, Delete, Edit, LocalDining } from "@material-ui/icons";
import _ from 'lodash';
import ConfirmDialog from "../app/components/dialogs/ConfirmDialog";
import MyAutocomplete from "../app/components/MyAutocomplete";
import Table, { buttonsStyle } from "../app/components/tables/table";
import { formatFloat } from "./helpers";
import { deleteBlockRecipesFromBD, descriptionFormatter, reCalcNutritionalValues, styleFormat } from "./structuresCalcs";
import { getUserStructuresById } from "../api/userStructures";
import { getUserDietsById } from "../api/userDiets";
import { alertError } from "./logger";
import { Card, CardBody } from "../_metronic/_partials/controls";
import { formattedValueNumber } from "./numbers";

export const DietInfo = (props) => {
    const { 
        openConfirmDialog, setOpenConfirmDialog, selectedStructure,
        setRefresh, refresh, userDiet, setUserDiet, blocks, setBlocks,
        setOpenEditBlockDialog, setSelectedBlock, structure, setStructure,
        setIsMeal, setSelectedDeleteBlock, saveDiet, disableLoadingData,
        dataNt, dataBlocks, updateSt, setChanges,
        selectedDeleteBlock, structuresOptions, structureHasChanged,
        setSelectedStructure,
    } = props;

	const numbersHeaderStlye = { width: '80px' };

    const blockColumns = [
		{
			dataField: "description",
			text: "description",
			formatter: descriptionFormatter,
			style: styleFormatter,
		},
		{
			dataField: "kcals",
			text: "kcal",
			style: styleFormatter,
			formatter: numbersFormatter,
			align: 'center',
			headerStyle: numbersHeaderStlye,
			headerAlign: 'center',
		},
		{
			dataField: "carbohydrates",
			text: "cho",
			style: styleFormatter,
			formatter: numbersFormatter,
			align: 'center',
			headerStyle: numbersHeaderStlye,
			headerAlign: 'center',
		},
		{
			dataField: "proteins",
			text: "pro",
			style: styleFormatter,
			formatter: numbersFormatter,
			align: 'center',
			headerStyle: numbersHeaderStlye,
			headerAlign: 'center',
		},
		{
			dataField: "fiber",
			text: "fiber",
			style: styleFormatter,
			formatter: numbersFormatter,
			align: 'center',
			headerStyle: numbersHeaderStlye,
			headerAlign: 'center',
		},
		{
			dataField: "fats",
			text: "fat",
			style: styleFormatter,
			formatter: numbersFormatter,
			align: 'center',
			headerStyle: numbersHeaderStlye,
			headerAlign: 'center',
		},
		{
			dataField: "number",
			text: "",
			formatter: blocksButtonFormatter,
			align: 'right',
			headerStyle: { width: '200px'},
			style: styleFormatter
		}
	];

    // function descriptionFormatter(cell, row) {
    //     console.log(cell, row);
    //     return cell;
    // }

	function styleFormatter(cell, row) {
		const block = blocks?.find((x) => x.number === row.number);
		return styleFormat(block);
	}

	function numbersFormatter(cell) {return cell.toFixed(1);}

    function typeFormatter(cell) {
		const block = blocks.find((x) => cell === x.number);
		return (block?.isIntake? block?.intake.isSnack? "Snack" : "Main" : "Exercise");
	}

    function blocksButtonFormatter(cell) {
		const index = blocks.findIndex((x) => x.number === cell);
		const block = blocks[index];

		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenEditBlockDialog(true);
							setSelectedBlock(block);
							setIsMeal(block.isIntake);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={index === 0}
						onClick={() => blockMove(block, index, index-1)}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={
							index >= (structure?.blocks?.length - 1 || 0)
						}
						style={buttonsStyle}
						onClick={() => blockMove(block, index, index+1)}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenConfirmDialog(4)
							setSelectedDeleteBlock(index);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		)
	}

    function blockMove(block, index, newIndex) {
		let blocks = [
			...structure.blocks
		]
		const blockUp = blocks.find(
			(x) => x.number === newIndex
		)
		
		blocks[newIndex] = {
			...block,
			number: newIndex
		};
		blocks[index] = {
			...blockUp,
			number: index
		}

		const data = reCalcNutritionalValues(blocks);
		const newStructure = {
			...structure,
			kcal: data.kcals,
			carbohydrates: data.carbohydrates,
			protein: data.protein,
			fats: data.fats,
			fatskg: data.fatskg,
			fatspercent: data.fatspercent,
			blocks: blocks,
		}
		saveDiet(newStructure)
		setStructure(newStructure);
		setBlocks(blocks);
		setRefresh(true);
	}

    function changeStructure(structureId) {
        getUserStructuresById(structureId)
			.then((res) => {
				if (res.status === 200) {
					let structureData = {...res.data};

					let blocks = [...structureData?.blocks];
					
					if (blocks?.length > 0) {
						for (let i = 0; i < blocks?.length; ++i) {
							let block = blocks[i];
							let recipes = block?.intake?.recipes;
							if (recipes?.length > 0) {
								// structureData.block[i].id = i;
								for (let j = 0; j < recipes?.length; ++j) {
									let actR = recipes[j];
									delete actR.recipe._id;
									actR.recipe.typeStructure = "userDiet"
									recipes[j] = actR;
								}
								block.recipes = recipes;
							}
							blocks[i] = block;
						}
					}
					
					structureData.blocks = blocks;
					setBlocks(structureData?.blocks);
					saveDiet(structureData)
					setStructure(structureData);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get structure.",
				});
				//history.push("/structures");
			});
    }

	async function refreshStructure(structure) {
		const diet = {
			...userDiet,
			diet: {...structure}
		}
		await updateSt(diet); //debe ser diet
		const data = await getUserDietsById(diet?._id)
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get user structures information, please recharge the page."
				})
			})
		
		const saveStructure = data?.data?.diet || structure;
		const newBlocks = saveStructure?.blocks;
		const calcs = reCalcNutritionalValues(newBlocks);
		const newStructure = {
			...saveStructure,
			kcal: calcs.kcals,
			carbohydrates: calcs.carbohydrates,
			protein: calcs.protein,
			fats: calcs.fats,
			fatskg: calcs.fatskg,
			fatspercent: calcs.fatspercent,
			blocks: newBlocks,
		}
		
		setBlocks([...newBlocks])
		saveDiet({...newStructure})
		setChanges(-1);
	}

	async function deleteBlock(index) {
		const res = await deleteBlockRecipesFromBD(userDiet.diet, index);
		if (res) {
			let newBlocks = blocks;
			newBlocks.splice(index, 1);

			for (let i = index; i < newBlocks?.length; ++i) newBlocks[i].number = i;

			const data = reCalcNutritionalValues(newBlocks);

			const newStructure = {
				...structure,
				kcal: data.kcals,
				carbohydrates: data.carbohydrates,
				protein: data.protein,
				fats: data.fats,
				fatskg: data.fatskg,
				fatspercent: data.fatspercent,
				blocks: newBlocks,
			}

			setBlocks([...newBlocks]);
			setSelectedBlock(-1);
			if (userDiet?._id) refreshStructure(newStructure);
			else {
				saveDiet({...newStructure})
				setStructure({...newStructure});
			}
			setRefresh(true);
		}
	}

    const handleChange = (element) => (event) => {
        const { value } = event.target;
        const formattedValue = formattedValueNumber(value, true);
        setUserDiet({...userDiet, [element]: formattedValue})

		setRefresh(true);
	};

    return (
        <>
            <Card>
                <ConfirmDialog
                    title={
                    "Are you sure you want to change the structure? You will lose all your changes"
                    }
                    open={openConfirmDialog === 1}
                    setOpen={setOpenConfirmDialog}
                    onConfirm={() => {
                        changeStructure(selectedStructure._id);
                        setRefresh(true);
                        setOpenConfirmDialog(-1);
                    }}
                />

                {/* <ConfirmDialog
                    title={
                    "Are you sure you want to change the competition? You will lose all your changes"
                    }
                    open={openConfirmDialog === 2}
                    setOpen={setOpenConfirmDialog}
                    onConfirm={() => {
                        actualizeCompetition(selectedCompetition)
                        setOpenConfirmDialog(-1);
                    }}
                /> */}
                <ConfirmDialog
                    title={
                        "Are you sure you want to delete the block? You will lose all your block information"
                    }
                    open={openConfirmDialog === 4}
                    setOpen={setOpenConfirmDialog}
                    onConfirm={() => {
                        deleteBlock(selectedDeleteBlock)
                    }}
                />
                <CardBody>
                    <Row>
                        <Col>
						<TextField
							id={"weight"}
							label={"Weight (kg)"}
							value={(userDiet?.weight)}
							onChange={handleChange('weight')}
							InputLabelProps={{shrink: true}}
							margin="normal"
							variant="standard"
							type="number"
							InputProps={{
								inputProps: {
									min: 0
								}
							}}
						/>
                        </Col>
                    </Row>
                    <br />
                    <h3> Diet of the day (provisional) </h3>
                    
                    {(!userDiet?._id || userDiet?.diet?.blocks?.length === 0) && (
                        <>
                            <MyAutocomplete
                                options={ structuresOptions }
                                getOptionLabel={(option) =>
                                    option.name
                                }
                                value={ structuresOptions.find(option => option._id === structure?._id) || null }
                                onChange={(event, selected) => {
                                    if (structureHasChanged && structure?.blocks?.length !== 0 && structure) {
                                        setOpenConfirmDialog(1)
                                        setSelectedStructure(selected);
                                    } else {
                                        changeStructure(selected._id);
                                        setRefresh(true);
                                    }
                                }}
                                placeholder="Select structure"
                                label={"Type of structure"}
                            />
                        </>
                    )}

                    <Row>
                        <Col>
                            <TextField
                                id={`kcals`}
                                label="KCAL"
                                value={formatFloat(dataNt?.kcals || 0)}
                                InputLabelProps={{
                                    shrink: true,
                                    min: 0,
                                }}
                                margin="normal"
                                variant="standard"
                                type="number"
                                className='readonly'
                            />
                        </Col>
                        <Col>
                            <TextField
                                id={`carbohydrates`}
                                label="CHO"
                                value={formatFloat(dataNt?.carbohydrates || 0)}
                                InputLabelProps={{
                                    shrink: true,
                                    min: 0,
                                }}
                                margin="normal"
                                variant="standard"
                                type="number"
                                className='readonly'
                            />
                        </Col>
                        <Col>
                            <TextField
                                id={`proteins`}
                                label="PRO"
                                value={formatFloat(dataNt?.proteins || 0)}
                                InputLabelProps={{
                                    shrink: true,
                                    min: 0,
                                }}
                                margin="normal"
                                variant="standard"
                                type="number"
                                className='readonly'
                            />
                        </Col>
                        <Col>
                            <TextField
                                id={`fats`}
                                label="FAT"
                                value={formatFloat(dataNt?.fats) || 0}
                                InputLabelProps={{
                                    shrink: true,
                                    min: 0,
                                }}
                                margin="normal"
                                variant="standard"
                                type="number"
                                className='readonly'
                            />
                        </Col>
                        <Col>
                            <TextField
                                id={`fiber`}
                                label="FIBER"
                                value={formatFloat(dataNt?.fiber || 0)}
                                InputLabelProps={{
                                    shrink: true,
                                    min: 0,
                                }}
                                margin="normal"
                                variant="standard"
                                type="number"
                                className='readonly'
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col><h3>Structure</h3></Col>
                        <Col className="text-right">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    setOpenEditBlockDialog(true);
                                    setSelectedBlock(null);
                                    setIsMeal(false);
                                }}
                            >
                                Add exercise
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                style={{marginLeft: 10}}
                                onClick={() => {
                                    setOpenEditBlockDialog(true);
                                    setSelectedBlock(null);
                                    setIsMeal(true)
                                }}
                            >
                                Add meal
                            </button>
                        </Col>
                    </Row>
                    {!refresh &&
                        blocks?.length > 0 && (
                            <Table
                                columns={blockColumns}
                                data={dataBlocks}
                            />
                        )}

                    {/* <Row>
                        <Col>
                            <TextField
                                id={`gastrointestinalSymptoms`}
                                label="gastrointestinalSymptoms (1 - 5)"
                                value={userDiet?.gastrointestinalSymptoms}
                                InputLabelProps={{
                                    shrink: true,
                                    min: 0,
                                }}
                                margin="normal"
                                placeholder="Not defined by the patient"
                                variant="standard"
                                required
                                type="number"
                                className='readonly'
                            />
                        </Col>
                    </Row> */}
                    <br/><br/><br/><br/><br/>
                </CardBody>
            </Card>
        </>
    )
}

export const NotesInfo = (props) => {
    const { userDiet, setUserDiet, setRefresh } = props;

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (!lang) {
			setUserDiet({...userDiet, [element]: event.target.value})
		}
		setRefresh(true);
	};

    function formatGastro(value) {
        switch (parseFloat(value)) { 
            case 1: return '😢';
            case 2: return '😟';
            case 3: return '😐';
            case 4: return '🙂';
            case 5: return '😄';
            default: return null;
        }
    }

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col>
                        <TextField
                            id={`notes`}
                            label="Private notes"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange('privateNotes', false)}
                            value={userDiet?.privateNotes}
                            margin="normal"
                            variant="standard"
                            placeholder="Enter notes for the patient..."
                            type="text"
                            multiline
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            id={`notes`}
                            label="Public notes"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange('publicNotes', false)}
                            value={userDiet?.publicNotes}
                            margin="normal"
                            variant="standard"
                            placeholder="Enter notes for the patient..."
                            type="text"
                            multiline
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            id={`patientNotes`}
                            label="Patient notes"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={(userDiet?.patientNotes)}
                            margin="normal"
                            variant="standard"
                            className='readonly'
                            placeholder="Not defined..."
                            type="text"
                            multiline
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            id={`gastroentinals`}
                            label="Gastroentinals (😢😟😐🙂😄)"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={formatGastro(userDiet?.gastrointestinalSymptoms)}
                            margin="normal"
                            variant="standard"
                            className='readonly'
                            placeholder="Not defined..."
                            type="text"
                            multiline
                        />
                    </Col>
                </Row>
                <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
                <br/> <br/> <br/> <br/>
            </CardBody>
        </Card>
    )
} 