import { API, authClient } from "../index";

export const getBrands = () => {
	return authClient().get(`${API}/brand?getDisabled=true`);
};

export const getBrandById = (id) => {
	return authClient().get(`${API}/brand/${id}`);
};

export const deleteBrand = (id) => {
	return authClient().delete(`${API}/brand/${id}`);
};

export const postBrand = async (brand) => {
	return authClient().post(`${API}/brand`, brand);
};

export const updateBrand = async (id, brand) => {
	return authClient().put(`${API}/brand/${id}`, brand);
};

export const changeStatusBrand = async (id, active) => {
	return authClient().put(`${API}/brand/change-status/${id}`, {
		active,
	});
};
