import {
	Button,
	MuiThemeProvider,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getFoods } from "../../../../api/food";
import {
	deleteFoodPlate,
	getFoodPlateById,
	postFoodPlate,
	updateFoodPlate,
} from "../../../../api/foodPlate";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { EditRecipeInfo, getEmptyFoodPlate } from "../../../../utils/recipeUtils";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

export default function EditFoodPlatesPage() {
	const [foodPlate, setFoodPlate] = useState(getEmptyFoodPlate());
	const [allFoods, setAllFoods] = useState([]);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
	const [selectedImage, setSelectedImage] = useState(null);
	const foodPlateId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const [refresh, setRefresh] = useState(false);

	const [changes, setChanges] = useState(-4);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		setRefresh(false)
	}, [refresh])

	useEffect(() => {
		if (allFoods.length === 0)
			getFoods()
				.then((res) => {
					if (res.status === 200) {
						setAllFoods(res.data)
						disableLoadingData();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get food plate."
					})
				})
		if (!foodPlateId) {
			disableLoadingData();
			return;
		}
		getFoodPlateById(foodPlateId)
			.then((res) => {
				if (res.status === 200) {
					let foodPlate = {
						...res.data,
						foods: res?.data?.foods,
					};
					setFoodPlate(foodPlate);
					setRefresh(true);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get food plate.",
				});
				history.push("/food-plates");
			});
	}, [foodPlateId, disableLoadingData, history]);

	function saveFoodPlate() {
		if (!foodPlate?.description)
			return alertError({
				error: null,
				customMessage:
					"The descripton is required in at least one of the languages.",
			});
		if (foodPlate?.foods.length === 0)
			return alertError({
				error: null,
				customMessage: "At least one food is required.",
			});

		if (!foodPlateId) {
			postFoodPlate(foodPlate, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Food plate successfully created.",
						});
						history.push("/food-plates");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save food plate.",
					});
				});
		} else {
			updateFoodPlate(foodPlateId, foodPlate, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/food-plates");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	
	
	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<Card>
				<CardHeader title={foodPlate?._id?'Recipe: ' + (foodPlate.description?foodPlate.description.es:''):'New recipe'}>
                <div className="mt-5">
                    <Button
                        onClick={() => {
                            if (changes > 0) setOpenConfirmDialog(1);
                            else history.push("/food-plates");
                        }}
                        variant="outlined"
                        style={{ marginRight: "20px" }}
                    >
                        Back
                    </Button>
                    <Button
                        onClick={() => saveFoodPlate()}
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: "20px" }}
                    >
                        Save recipe
                    </Button>
                    <ConfirmDialog
                        title={
                            "Are you sure you want to go back? You will lose all your changes"
                        }
                        open={openConfirmDialog === 1}
                        setOpen={setOpenConfirmDialog}
                        onConfirm={() => {
                            history.push("/food-plates")
                        }}
                    />
                    {foodPlateId && user?.role.includes("admin") && (
                        <>
                            <MuiThemeProvider theme={theme}>
                                <Button
                                    onClick={() => setOpenConfirmDialog(2)}
                                    variant="outlined"
                                    color="secondary"
                                    style={{ marginRight: "20px" }}
                                >
                                    Delete recipe
                                </Button>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "auto",
                                    }}
                                ></div>
                            </MuiThemeProvider>

                            <ConfirmDialog
                                title={
                                    "Are you sure you want to delete this food plate?"
                                }
                                open={openConfirmDialog === 2}
                                setOpen={setOpenConfirmDialog}
                                onConfirm={() => {
                                    deleteFoodPlate(foodPlateId)
                                        .then((res) => {
                                            if (
                                                res.status === 204 ||
                                                res.status === 200
                                            ) {
                                                alertSuccess({
                                                    title: "Deleted!",
                                                    customMessage:
                                                        "Food plate deleted successfully",
                                                });
                                                history.push("/food-plates");
                                            }
                                        })
                                        .catch((error) => {
                                            alertError({
                                                error: error,
                                                customMessage:
                                                    "Could not delete food plate.",
                                            });
                                        });
                                }}
                            />
                        </>
                    )}
                </div>
            	</CardHeader>
				{!refresh && 
					<CardBody>
						<EditRecipeInfo 
						foodPlate={foodPlate}
						setFoodPlate={setFoodPlate}
						allFoods={allFoods}
						selectedImage={selectedImage}
						setSelectedImage={setSelectedImage}
						foodPlateId={foodPlateId} 
						changes={changes}
						setChanges={setChanges}
						/>
					</CardBody>
				}
			</Card>
		);
}
