import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	TextField,
	Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alertError } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../MultilanguageTabBlock";
import Editor from "../../editor/Editor";
import Table, { buttonsStyle } from "../../tables/table";
import { ArrowDownward, ArrowUpward, Delete, Edit, ExpandLess, ExpandMore } from "@material-ui/icons";
import { Col, Row } from "react-bootstrap";
import { getFoods } from "../../../../api/food";
import { getFoodPlates } from "../../../../api/foodPlate";
import { getActivities } from "../../../../api/activity";
import { CardBody, Card } from "../../../../_metronic/_partials/controls";
import _ from 'lodash';
import EditBlockFoodDialog from "./EditBlockFoodDialog";
import { activityCalcs, activityTypes, blockCalc, blockIsMain, calcGramsHour, calcTotalGCho, calcTotalGChoH, calcTotalGrams, calcTotalMl, calcTotalMlH, calcUnitsNt, calcZValue, deleteStructureRecipe, errorCalc, runCalcs } from "../../../../utils/structuresCalcs";
import { formatFloat } from "../../../../utils/helpers";
import { useSkeleton } from "../../../hooks/useSkeleton";
import MyAutocomplete from "../../MyAutocomplete";
import { getEmptyFoodPlateStructure } from "../../../../utils/recipeUtils";
import ConfirmDialog from "../ConfirmDialog";
import { deleteFoodPlateStructure } from "../../../../api/foodPlateStructure";
import EditBlockIntensityDialog from "./EditBlockIntensityDialog";
import { formattedValueNumber } from "../../../../utils/numbers";
import OnlyTextMult from "../../textFields/OnlyTextMult";

function getEmptyNutritionalValues() {
	return {
		kcals: 0,
		proteins: 0,
		carbohydrates: 0,
		fats: 0,
		fiber: 0,
	}
}

const EditStructureBlockDialog = (props) => {
	const { title, data, open, setOpen, onSave, readOnly, 
		meal, typeStructure, structureId, actualizeStructure,
		patient
	} = props;

	const [block, setBlock] = useState(null);
	const [infoElements, setInfoElements] = useState([]);

	const [foods, setFoods] = useState([]);
	const [foodPlates, setFoodPlates] = useState([]);
	const [activities, setActivities] = useState([]);

	const [openEditMealDialog, setOpenEditMealDialog] = useState(false);
	const [selectedMeal, setSelectedMeal] = useState(null);
	const [isFood, setIsFood] = useState(false);

	const [openEditActivityDialog, setOpenEditActivityDialog] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState(null);

	const [refresh, setRefresh] = useState(false);
	const [isNew, setIsNew] = useState(false);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
	const [selectedDeleteMeal, setSelectedDeleteMeal] = useState(null);

	const [isExtra, setIsExtra] = useState(false);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	function getEmptyStructureBlock() {
		return {
			name: null,
			description: null,
			exercise: { activities: {}},
			isIntake: meal,
			number: -1,
			kcals: null,
			proteins: null,
			carbohydrates: null,
			fats: null,
			fiber: null
		};
	}

	const numbersHeaderStyle = { width: '80px' };

	const elementsTable = [
		{
			dataField: "type",
			style: styleFormat,
			text: "type",
			headerStyle: { width: '100px' }
		},
		{
			dataField: "fullName",
			text: "Name",
			headerAlign: 'left',
			style: styleFormat,
			align: 'left',
		},
		{
			dataField: "grams",
			text: "grams",
			headerAlign: "center",
			align: 'center',
			style: styleFormat,
			headerStyle: numbersHeaderStyle,
			formatter: quantityFormatter
		},
		{
			dataField: "kcals",
			style: styleFormat,
			text: "kcal",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: formatFloat
		},
		{
			dataField: "carbohydrates",
			style: styleFormat,
			text: "cho",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: formatFloat
		},
		{
			dataField: "proteins",
			style: styleFormat,
			text: "pro",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: formatFloat
		},
		{
			dataField: "fats",
			style: styleFormat,
			text: "fat",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: formatFloat
		},
		{
			dataField: "fiber",
			style: styleFormat,
			text: "fiber",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: formatFloat,
		},
		{
			dataField: "number",
			text: "",
			formatter: elementsButtonFormatter,
			align: 'right',
			headerAlign: 'center',
			headerStyle: { width: '200px'}
		},
	];

	function quantityFormatter(cell, row) {
		if (row?.type === "TOTAL") return "";
		if (row?.selectedPortion !== undefined && row?.selectedPortion !== -1) {
			const portionName = row?.portions?.find((x) => x._id === row.selectedPortion).name;
			return `${row?.portionQuant} ${portionName} (${cell} g)`
		}
		return (`${cell} g`);
	}

	function getActivityIntensityTitle(block) {
		const type = block?.exercise?.activities?.typeOfActivity;
		return (type === "Running" ? "Speed (km/h)": 
			type === "Cycling" ? "Power (W)" : 
			type === "Trailrunning" ? "" : "Intensity");
	}

	const activitiesTable = [
		{
			dataField: "intensity",
			text: `${getActivityIntensityTitle(block)}`,
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat,
		},
		{
			dataField: "time",
			text: "time (in mins)",
			headerAlign: 'center',
			align: 'center'
		},
		{
			dataField: "zValue",
			text: "z value",
			headerAlign: 'center',
			align: 'center'
		},
		{
			dataField: "kcals",
			text: "kcal",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat
		},	
		{
			dataField: "carbohydrates",
			text: "cho",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat
		},	
		{
			dataField: "fats",
			text: "fat",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat
		},	
		{
			dataField: "number",
			text: "",
			formatter: elementsButtonFormatter,
			align: 'right',
			headerStyle: { width: '200px'}
		},
	];

	useEffect(() => {
		setRefresh(false);
	}, [refresh])

	useEffect(() => {
		const fetchData = async() => {
			try {
				if (!data || !open) {
					setIsNew(true);
					const newBlock = await recalcBlockValues(getEmptyStructureBlock())
					setBlock(newBlock);
				}
				else {
					setIsNew(false);
					const dat = _.cloneDeep(data);
					if (meal && dat?.intake) {
						setBlock(await recalcBlockValues({
							...dat,
							intake: {
								...dat.intake,
								recipes: dat.intake.recipes,
								foods: dat.intake.foods,
								isSnack: (dat?.intake?.isSnack === true)? '1' : '0'
							}
						}))
					}
					else setBlock(await recalcBlockValues(dat));
				}
				setInfoElements(infoElementsData(data));
				disableLoadingData();
			}
			
			catch (e) {
				console.log("ERROR: ", e);
			}
		}

		fetchData();
	}, [data, open]);

	useEffect(() => {
		if (foods?.length === 0)
			getFoods()
				.then((res) => {
					if (res.status === 200) {
						const foods = res.data;
						setFoods(foods);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get foods.",
					});
				});
		if (foodPlates?.length === 0)
			getFoodPlates()
				.then((res) => {
					if (res.status === 200) {
						const foodPlates = res.data;
						setFoodPlates(foodPlates);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get food plates"
					});
				});
		
		if (activities?.length === 0)
			getActivities()
				.then((res) => {
					if (res.status === 200) {
						const activities = res.data;
						setActivities(activities);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get activities"
					});
				});
		
	}, [])

	useEffect(() => {
		setInfoElements(infoElementsData(block));
		setRefresh(true)
	}, [block?.intake])
	
	useEffect(() => {
		setRefresh(true);
	}, [block])

	function styleFormat(cell, row) {
		let style = { color: 'black' };
		if (row?.type === "TOTAL") style.fontWeight = 'bold';
		return style;
	}

	async function recalcBlockValues(block) {
		const auxBlock = _.cloneDeep(block);
		const calcs = await blockCalc(auxBlock, patient);
		let newBlock = {
			...auxBlock,
			kcals: calcs?.kcals,
			carbohydrates: calcs?.carbohydrates,
			proteins: calcs?.proteins,
			fats: calcs?.fats,
			fiber: calcs?.fiber,
			time: calcs?.exerciseTime
		}
		
		setInfoElements(infoElementsData(newBlock))

		if (blockIsMain(block))
			newBlock.errorCalc = await errorCalc(newBlock);
		else newBlock.errorCalc = 0;
		
		setRefresh(true);

		return newBlock;
	}

	function infoElementsData(block) {
		let data = [];
		
		if (block?.isIntake) {
			for (let i = 0; i < block?.intake?.recipes?.length; ++i) {
				let elem = {};
				const info = block.intake.recipes[i];
				
				elem.number = info?.number;
				elem.type = "Recipe";
				elem.fullName = info?.recipe?.description?.es || 
								info?.recipe?.fullName;
				elem._id = info?.recipe._id;
				elem.extraMeal = info?.extraMeal;

				data.push(elem);
			}

			for (let i = 0; i < block?.intake?.foods?.length; ++i) {
				let elem = {};
				const info = block.intake.foods[i];

				elem.number = info?.number;
				elem.type = "Food";
				elem.fullName = info?.food?.fullName?.es ||
								info?.food?.fullName;
				elem._id = info?.food._id;
				elem.grams = info?.grams;

				elem.portions = info?.food?.portions || [];
				elem.selectedPortion = (info?.selectedPortion >= 0)? info?.selectedPortion : -1;
				elem.portionQuant = info?.portionQuant || 0;
				elem.extraMeal = info?.extraMeal;

				data.push(elem);
			}
		} else {
			for (let i = 0; i < block?.exercise?.activities?.intensities?.length; ++i) {
				const info = block.exercise.activities.intensities[i];
				let elem = {
					...info,
					type: 'Activity'
				};				
				data.push(elem);
			}
		}

		data.sort(sortByNumber)

		setRefresh(true);
		return data;
	}

	async function elementMove(index, newIndex, block, extra) {
		function deepCopy(obj) {
			return JSON.parse(JSON.stringify(obj));
		}
	  
		function actualizeInfo(type, index, newIndex, newBlock, block) {
		  const updatedBlock = deepCopy(newBlock);
		  if (type === "Recipe") {
			const idx = block?.intake?.recipes?.findIndex((x) => (x.number === index && extra === x.extraMeal));
			updatedBlock.intake.recipes[idx].number = newIndex;
		  } else if (type === "Food") {
			const idx = block?.intake?.foods?.findIndex((x) => x.number === index && extra === x.extraMeal);
			updatedBlock.intake.foods[idx].number = newIndex;
		  } else if (type === "Activity") {
			const idx = block?.exercise?.activities?.intensities?.findIndex(
			  (x) => x.number === index
			);
			updatedBlock.exercise.activities.intensities[idx].number = newIndex;
		  }
		  return updatedBlock;
		}
	  
		const type = infoElements.find((x) => x.number === index && extra === x.extraMeal).type;
		const type2 = infoElements.find((x) => x.number === newIndex && extra === x.extraMeal).type;
	  
		let newBlock = deepCopy(block);
		newBlock = actualizeInfo(type, index, newIndex, newBlock, block);
		newBlock = actualizeInfo(type2, newIndex, index, newBlock, block);
	  
		setBlock(await recalcBlockValues(deepCopy(newBlock)));
		setRefresh(true);
	}

	async function deleteElem(cell, row) {
		// let elems = [...infoElements];
		const type = infoElements.find((x) => x.number === row?.number && x.extraMeal === row.extraMeal).type;

		let newBlock = {...block};
		if (meal) {
			if (type === 'Recipe') {
				const index = newBlock.intake.recipes.findIndex(
					(x) => (x.number === row?.number && x.extraMeal === row.extraMeal)
				)

				const recipe = {...newBlock.intake.recipes[index]};
				const res = await deleteStructureRecipe(recipe?.recipe);
				if (!res) return;
				
				newBlock.intake.recipes.splice(index, 1);
				if (recipe?.recipe?._id) actualizeStructure(newBlock);
			} else {
				const index = newBlock.intake.foods.findIndex(
					(x) => (x.number === row?.number && x.extraMeal === row.extraMeal)
				)

				newBlock.intake.foods.splice(index, 1);
			}

			for (let i = 0; i < newBlock.intake.recipes?.length; ++i) {
				const number = newBlock.intake.recipes[i].number;
				if (number > row?.number)
					newBlock.intake.recipes[i].number = number - 1;
			}
			for (let i = 0; i < newBlock.intake.foods?.length; ++i) {
				const number = newBlock.intake.foods[i].number;
				if (number > row?.number)
					newBlock.intake.foods[i].number = number - 1;
			}

			setBlock(await recalcBlockValues({...newBlock}));
		} else {
			const index = newBlock.exercise.activities.intensities.findIndex(
				(x) => x.number === row?.number
			);
			
			let actv = newBlock.exercise.activities.intensities;
			actv.splice(index, 1);
			newBlock.exercise.activities.intensities = actv;

			for (let i = 0; i < newBlock.exercise.activities.intensities?.length; ++i) {
				const number = newBlock.exercise.activities.intensities[i].number;
				if (number > row?.number)
					newBlock.exercise.activities.intensities[i].number = number - 1;
			}

			setBlock(await recalcBlockValues({...newBlock}));
		}

		setRefresh(true);
	}

	function elementsButtonFormatter(cell, row) {

		function disabledDown() {
			if (row?.type === "TOTAL") return true;
			if (blockIsMain(block)) {
				const sum = infoElements.reduce((total, x) => 
					{if (x?.extraMeal === row?.extraMeal) return total+1; 
						return total;}, 0) - 1;
						return cell >= sum;
			}
			return (cell >= infoElements?.length-1);
		}
				
		function disabledUp() {
			return cell === 0 || row?.type === "TOTAL";
		}

		const elemAct = infoElements.find((x) => (x.number === cell && 
			x.extraMeal === row.extraMeal))

		return (
			<>	
			<div >
				
			<Tooltip title="Edit" style={{width: 500}}>
					<Button
						disabled={row?.type === "TOTAL"}
						style={buttonsStyle}
						size="small"
						onClick={() => {
							if (meal) {
								setIsExtra(elemAct?.extraMeal);
								setOpenEditMealDialog(true);
								if (elemAct.type === 'Food') {
									// setSelectedMeal(block?.intake?.foods?.
									// 	find((x) => (x.number === cell && 
									// 		x.extraMeal === elemAct.extraMeal)));
									setSelectedMeal(elemAct);
									setIsFood(true);
								}
								else {
									const recipeInfo = block?.intake?.recipes?.find(
										(x) => (x?.number === elemAct.number &&
											x.extraMeal === elemAct.extraMeal)
									)
									setSelectedMeal({...recipeInfo?.recipe, 
										number: recipeInfo?.number, 
										extraMeal: recipeInfo?.extraMeal});
									setIsFood(false);
								}
							} else {
								setOpenEditActivityDialog(true);
								setSelectedActivity(block?.exercise?.activities?.intensities?.
									find((x) => x.number === cell));
								// setSelectedActivity(infoElements[cell]);
							}
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={disabledUp()}
						onClick={() => elementMove(cell, cell-1, block, row.extraMeal)}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={disabledDown()}
						style={buttonsStyle}
						onClick={() => elementMove(cell, cell+1, block, row.extraMeal)}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						disabled={row?.type === "TOTAL"}
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let elems = [...infoElements];
							const type = elems[cell].type;
							if (type === 'Recipe') {
								const recipeId = block?.intake?.recipes?.find(
									(x) => x.number === cell)?._id;
								if (recipeId) {
									setOpenConfirmDialog(1)
									setSelectedDeleteMeal({cell: cell, row: row})
								} else deleteElem(cell, row);
							} else deleteElem(cell, row);
						}
							// deleteElem(cell, row)
						}
					>
						<Delete />
					</Button>
				</Tooltip>
			</div>
			
			</>
		)
	}

	const handleChange = (element, lang, isNumber) => (event) => {
		const { value } = event.target;
		let formattedValue = formattedValueNumber(value, isNumber);
		if (formattedValue === " ") return;
		if (lang) {
			if (!block[element]) block[element] = {};
			let newText = block[element];
			newText[lang] = formattedValue;
			setBlock({ ...block, [element]: newText });
		} else setBlock({ ...block, [element]: formattedValue });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value?.trim() !== "") {
			if (lang) {
				if (!block[element]) block[element] = {};
				let newText = block[element];
				newText[lang] = value;
				setBlock({ ...block, [element]: newText });
			} else {
				setBlock({ ...block, [element]: value });
			} 
		}
	};

	const handleChangeCubes = (element, lang) => async (event) => {
		const { value } = event.target;
		let formattedValue = formattedValueNumber(value, true);
		const intake = {...block?.intake, [element]: formattedValue};
		setBlock(await recalcBlockValues({...block, intake: intake}));
	}
	
	const mealOptions = [
		{ _id: '0', fullName: 'Main' },
		{ _id: '1', fullName: 'Snack' },
	  ];
	
	function sortByNumber(a, b) {
		if (a.number < b.number) return -1;
		if (a.number > b.number) return 1;
		return 0;
	}

	function getInfoElementsData(elems, block) {

		let data = [];

		let totalKcals = 0;
		let totalCho = 0;
		let totalPro = 0;
		let totalFat = 0;
		let totalFib = 0;

		function sumElemValues(elem) {
			if (!elem.extraMeal) {
				totalKcals += elem.kcals;
				totalCho += elem.carbohydrates;
				totalPro += elem.proteins;
				totalFat += elem.fats;
				totalFib += elem.fiber;
			}
		}

		for (let i = 0; i < elems?.length; ++i) {
			let elem = {};
			let info = elems[i];
			const type = info?.type;
			elem.type = type;
			elem.fullName = info?.fullName;
			elem.number = info?.number;
			
			if (!info?.extraMeal) {
					if (type === 'Activity') {
						elem = {
							...info
						}
						elem.intensity = runCalcs(info)?.speed;
						const actC = activityCalcs(block.exercise.activities, elem, patient);
						elem.kcals = actC.kcals || 0;
						elem.carbohydrates = actC.carbohydrates;
						elem.fats = actC.fats;
					} else if (type === 'Food') {
						const complFood = foods.find((x) => x._id === info?._id);
						const calc = parseFloat(info?.grams) / 100 || 0;
						elem.kcals = parseFloat(complFood?.kcals) * calc;
						elem.proteins = parseFloat(complFood?.proteins) * calc;
						elem.carbohydrates = parseFloat(complFood?.carbohydrates) * calc;
						elem.fats = parseFloat(complFood?.fats) * calc;
						elem.fiber = parseFloat(complFood?.fiber) * calc;
						elem.extraMeal = info?.extraMeal;
		
						elem.selectedPortion = info?.selectedPortion !== undefined ? info?.selectedPortion : -1;
						elem.portions = complFood?.portions;
						elem.portionQuant = info?.portionQuant || 0;
						elem.grams = info?.grams || 0;
		
						sumElemValues(elem);
					} else {
						const complRecipe = block?.intake?.recipes?.find((x) => x.number === info?.number)?.recipe;
						elem.kcals = parseFloat(complRecipe?.totalKcals);
						elem.proteins = parseFloat(complRecipe?.totalProteins);
						elem.carbohydrates = parseFloat(complRecipe?.totalCarbohydrates);
						elem.fats = parseFloat(complRecipe?.totalFats);
						elem.fiber = parseFloat(complRecipe?.totalFiber);
						elem.extraMeal = info?.extraMeal;
						
						sumElemValues(elem);
					}
		
					elem.errorCalc = block?.errorCalc || false;
					data.push(elem);
			}
		}

		if (data.length > 0 && blockIsMain(block))
			data.push({
				type: 'TOTAL',
				fullName: '',
				kcals: totalKcals,
				carbohydrates: totalCho,
				proteins: totalPro,
				fats: totalFat,
				fiber: totalFib,
				number: ''
			});
		
		return data;
	}

	function getInfoElementsDataExtra(elems, block) {

		let data = [];

		let totalKcals = 0;
		let totalCho = 0;
		let totalPro = 0;
		let totalFat = 0;
		let totalFib = 0;

		function sumElemValues(elem) {
			if (!elem.extraMeal) {
				totalKcals += elem.kcals;
				totalCho += elem.carbohydrates;
				totalPro += elem.proteins;
				totalFat += elem.fats;
				totalFib += elem.fiber;
			}
		}

		for (let i = 0; i < elems?.length; ++i) {
			let elem = {};
			const info = elems[i];
			const type = info?.type;
			elem.type = type;
			elem.fullName = info?.fullName;
			elem.number = info?.number;
			
			if (info?.extraMeal) {
				if (type === 'Food') {
					const complFood = foods.find((x) => x._id === info?._id);
					const calc = parseFloat(info?.grams) / 100 || 0;
					elem.kcals = parseFloat(complFood?.kcals) * calc;
					elem.proteins = parseFloat(complFood?.proteins) * calc;
					elem.carbohydrates = parseFloat(complFood?.carbohydrates) * calc;
					elem.fats = parseFloat(complFood?.fats) * calc;
					elem.fiber = parseFloat(complFood?.fiber) * calc;
					elem.extraMeal = info?.extraMeal;

					elem.selectedPortion = info?.selectedPortion !== undefined ? info?.selectedPortion : -1;
					elem.portions = complFood?.portions;
					elem.portionQuant = info?.portionQuant || 0;
					elem.grams = info?.grams || 0;

					sumElemValues(elem);
				} else {
					const complRecipe = block?.intake?.recipes?.find((x) => x.number === info?.number)?.recipe;
					elem.kcals = parseFloat(complRecipe?.totalKcals);
					elem.proteins = parseFloat(complRecipe?.totalProteins);
					elem.carbohydrates = parseFloat(complRecipe?.totalCarbohydrates);
					elem.fats = parseFloat(complRecipe?.totalFats);
					elem.fiber = parseFloat(complRecipe?.totalFiber);
					elem.extraMeal = info?.extraMeal;
					
					sumElemValues(elem);
				}
				
				elem.errorCalc = block?.errorCalc || false;
				data.push(elem);
			}
		}
			
		return data;
	}

	async function saveNewFood(meal) {

		const newNumber = generateNewNumber(meal?.extraMeal);
		
		const index = block?.intake?.foods?.findIndex(
			(x) => x.number === meal?.number);
		
		const completeFood = foods?.find((x) => meal._id === x._id);
		
		if (index !== -1 && index !== undefined) {

			const newFood = {
				food: {
					...completeFood
				},
				number: meal?.number,
				grams: meal?.grams,
				selectedPortion: (meal?.selectedPortion >= 0)? meal?.selectedPortion : -1,
				portionQuant: meal?.portionQuant || 0,
				extraMeal: meal?.extraMeal || false
			}

			let newFoods = _.cloneDeep(block?.intake?.foods);
			newFoods[index] = newFood;

			let newBlock = {
				...block,
				intake: {
					...block.intake,
					foods: newFoods
				},
			};
			
			setBlock(await recalcBlockValues(newBlock));
			let newElems = infoElements;
			newElems[meal?.number] = {
				number: meal?.number,
				type: meal?.type,
				fullName: meal?.fullName,
				_id: meal?._id,
				grams: meal?.grams,
				portions: meal?.portions || [],
				selectedPortion: (meal?.selectedPortion >= 0)? meal?.selectedPortion : -1,
				portionQuant: meal?.portionQuant || 0,
				extraMeal: meal?.extraMeal || false
			}
			// setInfoElements(newElems);
		}
		else { //Nueva food

			let newFoods = _.cloneDeep(block?.intake?.foods) || [];
			newFoods.push({
				food: {
					...completeFood
				},
				number: newNumber,
				grams: meal?.grams,
				selectedPortion: (meal?.selectedPortion >= 0)? meal?.selectedPortion : -1,
				portionQuant: meal?.portionQuant || 0,
				extraMeal: meal?.extraMeal || false
			})

			let newBlock = {
				...block,
				intake: {
					...block?.intake,
					foods: newFoods
				},
			}

			setBlock(await recalcBlockValues(newBlock));

			let newElems = infoElements;
			newElems.push({
				number: newNumber,
				type: 'Food',
				fullName: meal?.fullName,
				_id: meal?._id,
				grams: meal?.grams,
				portions: meal?.portions || [],
				selectedPortion: (meal?.selectedPortion >= 0)? meal?.selectedPortion : -1,
				portionQuant: meal?.portionQuant || 0,
				extraMeal: meal?.extraMeal || false
			})
			// setInfoElements(newElems);
		}

		setRefresh(true);
	}

	function generateNewNumber(extra) {
		if (!block?.isIntake) return block?.exercise?.activities?.intensities?.length || 0;
		if (!blockIsMain(block)) return (block?.intake?.foods?.length || 0) + (block?.intake?.recipes?.length || 0);
		const sum = block.intake.foods.reduce((total, x) => {if (x?.extraMeal === extra) return (total + 1); return total;}, 0)
		return sum + (isExtra ? 0 : (block?.intake?.recipes?.length || 0));
	}

	async function saveNewRecipe(meal) {

		const newNumber = generateNewNumber(false);

		const index = block?.intake?.recipes?.findIndex(
			(x) => x.number === meal?.number);

		if (index !== -1 && index !== undefined) {
			const newRecipe = {
				recipe: {
					...meal
				},
				number: meal?.number,
				extraMeal: meal?.extraMeal || false
			}

			let newRecipes = _.cloneDeep(block?.intake?.recipes);
			newRecipes[index] = newRecipe;

			let newBlock = {
				...block,
				intake: {
					...block.intake,
					recipes: newRecipes
				},
			};

			setBlock(await recalcBlockValues(newBlock))

			let newElems = infoElements;
			newElems[meal?.number] = {
				number: meal?.number,
				type: 'Recipe',
				fullName: meal?.description?.es,
				_id: meal?._id,
				extraMeal: meal?.extraMeal || false
			}
			// setInfoElements(newElems);
		} else {
			let newRecipes = _.cloneDeep(block?.intake?.recipes) || [];
			newRecipes.push({
				recipe: {
					...meal
				},
				number: newNumber,
				extraMeal: meal?.extraMeal || false
			})

			let newBlock = {
				...block,
				intake: {
					...block.intake,
					recipes: newRecipes
				},
			};
			setBlock(await recalcBlockValues(newBlock));

			let newElems = infoElements;
			newElems.push({
				number: newNumber,
				type: 'Recipe',
				fullName: meal?.description?.es,
				_id: meal?._id,
				extraMeal: meal?.extraMeal || false
			})
			// setInfoElements(newElems);
		}

		setRefresh(true);
	}

	function saveNewIntensity(intensity, intensityAnt) {
		const newKcal = intensity?.kcals;
		
		const newNumber = infoElements?.length || 0;
		
		const index = block?.exercise?.activities?.intensities?.findIndex(
			(x) => x.number === intensity?.number);

		if (index !== -1 && index !== undefined) {
			const kcalsAnt = intensityAnt?.kcals;

			const newIntensity = {
				...intensity
			}

			let newIntensities = _.cloneDeep(block?.exercise?.activities?.intensities);
			newIntensities[index] = newIntensity;

			const newBlock = {
				...block,
				exercise: {
					...block.exercise,
					activities: { 
						...block.exercise.activities,
						intensities: newIntensities
					}
				},
				kcals: block?.kcals - kcalsAnt + newKcal,
			};

			actualizeBlock(newBlock);

			let newElems = infoElements;
			newElems[intensity?.number] = {
				...intensity,
				number: intensity?.number,
				type: 'Activity',
			}
			// setInfoElements(newElems);
		} else {
			let newIntensities = _.cloneDeep(block?.exercise?.activities?.intensities) || [];
			newIntensities.push({
				...intensity,
				number: newNumber
			})

			const newBlock = {
				...block,
				exercise: {
					...block.exercise,
					activities: {
						...block.exercise.activities,
						intensities: newIntensities
					}
				},
				kcals: block?.kcals + newKcal,
			};

			actualizeBlock(newBlock);
			
			let newElems = [...infoElements];
			newElems.push({
				...intensity,
				number: newNumber,
				speed: parseFloat(intensity?.speed),
				type: 'Activity',
			})
			setInfoElements(newElems);
		}
		setRefresh(true);
	}

	async function actualizeBlock(block) {
		const newBlock = await recalcBlockValues({
			...block,
		});
		setBlock({...newBlock});
	}

	function addNewMeal(isFood, isExtra) {
		// const numberOfMeals = (block?.intake?.foods?.length || 0) + (block?.intake?.recipes?.length || 0);
		// if (numberOfMeals >= 3) {
		// 	alertError({
		// 		error: null,
		// 		customMessage: "Maximum 3 foods on Snack blocks"
		// 	})
		// 	return;
		// }
		setOpenEditMealDialog(true);
		setSelectedMeal(isFood ? null : getEmptyFoodPlateStructure(structureId ? structureId : null, typeStructure));
		setIsFood(isFood);
		setIsExtra(isExtra);
		setRefresh(true)
	}

	function addNewIntensity() {
		setOpenEditActivityDialog(true);
		setSelectedActivity(null);
		setRefresh(true);
	}

	const handleChangeExercise = (element) => (event) => {
		const { value } = event.target;
		let formattedValue = formattedValueNumber(value, true);
		let newBlock = {
			...block,
			exercise: {
				activities: {
					...block.exercise.activities,
					[element]: element !== "exerciseNotes" ? formattedValue : value
				}
			}
		}

		switch (element) {
			case "gramsHour": newBlock.exercise.activities.totalGrams = calcTotalGrams(newBlock); break;
			case "totalGrams": newBlock.exercise.activities.gramsHour = calcGramsHour(newBlock); break;
			case "totalGChoH": newBlock.exercise.activities.totalGCho = calcTotalGCho(newBlock); break;
			case "totalGCho": newBlock.exercise.activities.totalGChoH = calcTotalGChoH(newBlock); break;
			case "totalMlh": newBlock.exercise.activities.totalMl = calcTotalMl(newBlock); break;
			case "totalMl": newBlock.exercise.activities.totalMlh = calcTotalMlH(newBlock); break;
		}

		setBlock({...newBlock})
	}

	function PatientFeedback() {
		const [info, setInfo] = useState(false);
		const actA = block?.exercise?.activities
		const arrowStyle = {color: '#000'};

		if (!actA?.perceivedEffort && !actA?.gastroentinalDistress)
		return (<></>)
		return (
			<>
				{/* <Row style={{backgroundColor: '#E1F2CE'}}>
					<Col sm={11} style={{marginTop: 10}}>
						<h5 style={{fontWeight: 'bold'}}>Patient Feedback</h5>
					</Col>
					<Col sm={1}>
						<Tooltip title={"Open feedback information"}>
						<Button
							size="small"
							style={{backgroundColor: 'transparent', borderColor: 'transparent', marginLeft: 40}}
							onClick={() => setInfo(!info)}
						>
							{info? (<ExpandLess style={arrowStyle}/>) : 
							(<ExpandMore style={arrowStyle}/>)}
						</Button>
						</Tooltip>
					</Col>
				</Row> */}
				<Row>
					<Col>
					<TextField
						id={`perceivedEffort`}
						label="Perceived effort"
						InputProps={{
							inputProps: {
								min: 0
							}
						}}
						inputProps={{ readOnly: true }}
						value={formatFloat(actA?.perceivedEffort)}
						margin="normal"
						variant="standard"
						type="number"
						className='readonly'
					/>
					</Col>
					<Col>
					<TextField
						id={`gastroentinalDistress`}
						label="Gastroentinal distress"
						InputProps={{
							inputProps: {
								min: 0
							}
						}}
						inputProps={{ readOnly: true }}
						value={formatFloat(actA?.gastroentinalDistress)}
						margin="normal"
						variant="standard"
						type="number"
						className='readonly'
					/>
					</Col>
				</Row>
			</>
		)
	}
	
	if (isLoadingData) return (
		<>
			<Dialog disableBackdropClick={true}
				fullWidth={true}
				open={open}
				onClose={() => setOpen(false)}
				maxWidth="lg"
				aria-labelledby="table-dialog"
			>
				<DialogContent>
					
				<ContentSkeleton />
				</DialogContent>
			</Dialog>
		</>
)
	return (
		<Dialog disableBackdropClick={true}
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				<Card>
					<>
					{meal && (
						<>
							<Row>
								<Col>
								<MyAutocomplete
									options={mealOptions}
									getOptionLabel={(option) =>
									option.fullName
									}
									value={mealOptions.find(option => option._id === block?.intake?.isSnack) || null}
									onChange={(event, selected) => {
										let newBlock = {...block};
										newBlock.intake = newBlock.intake || {};
										newBlock.intake.isSnack = selected?._id;
										if (selected?._id === '1')
											actualizeBlock({...newBlock})
										else {
											newBlock = {
												...newBlock,
												intake: {
													...newBlock.intake,
													cubeVegetables: '0',
													cubeStarches: '0',
													cubeProteins: '0',
													cubeFats: '0',
												}
											};
											actualizeBlock(newBlock);		
										}						
										// setRefresh(true);
									}}
									placeholder="Select type of block"
									label={"Type of block"}
								/>
								</Col>
							</Row>
						</>
					)}

					{!meal && (
						<>
							<h2>Type: Exercise</h2>
							<br />
							{ typeStructure === "userDiet" && 
							<PatientFeedback />
							}
						</>
					)}
					
					<Row>
						<Col>
						<TextField
							id={`title`}
							label="Title"
							value={(block?.name) || ""}
							onChange={handleChange("name", false, false)}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="standard"
							required
							disabled={readOnly}
						/>
						</Col>
					</Row>
					{(isNew || (!isNew && block?.description) || block?.name) && (
						<Editor
							disabled={readOnly}
							body={block?.description || ""}
							setBody={(new_body) => {
								handleChangeEditor("description", new_body, false)}
							}
							className="max-height"
							placeholder={"Enter block description here..."}
							name="Description"
						/>
					)
					}	
					<br/>
					{!meal && (
						<>
							<br />
							
							<Row>
								<Col>
									<TextField
										id={`kcals`}
										label="KCAL"
										InputProps={{
											inputProps: {
												min: 0
											}
										}}
										inputProps={{ readOnly: true }}
										value={formatFloat(block?.kcals) || 0}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
								<Col>
									<TextField
										id={`carbohydrates`}
										label="CHO"
										InputProps={{
											inputProps: {
												min: 0
											}
										}}
										inputProps={{ readOnly: true }}
										value={formatFloat(block?.carbohydrates) || 0}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
								<Col>
									<TextField
										id={`fats`}
										label="FAT"
										InputProps={{
											inputProps: {
												min: 0
											}
										}}
										inputProps={{ readOnly: true }}
										value={formatFloat(block?.fats) || 0}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
								<Col>
									<TextField
										id={`totalTime`}
										label="Exercise time (min)"
										InputProps={{
											inputProps: {
												min: 0
											}
										}}
										inputProps={{ readOnly: true }}
										value={formatFloat(block?.time) || 0}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
							</Row>

							<Row>
								<Col>
									<MyAutocomplete 
										options={activityTypes}
										getOptionLabel={(option) => option}
										value={activityTypes.find(option => option === block?.exercise?.activities?.typeOfActivity) || null}
										onChange={(event, selected) => {
											let newBlock = {...block};
											newBlock.exercise.activities.typeOfActivity = selected;
											newBlock.exercise.activities.intensities = [];
											actualizeBlock({...newBlock})
										}}
										placeholder="Select type of activity"
										label={"Type of activity"}
									/>
								</Col>
							</Row>
							
							{block?.exercise?.activities?.typeOfActivity === "Others" &&
								<Row>
									<Col>
									<MyAutocomplete 
									options={activities}
									getOptionLabel={(option) => option.fullName.es}
									value={activities.find(option => option === block?.exercise?.activities?.typeOfActivity) || null}
									onChange={(event, selected) => {
										let newBlock = {...block};
										// newBlock.exercise.activities.activity = selected;
										actualizeBlock({...newBlock})
									}}
									placeholder="Select activity"
									label={"Activity"}
									/>
									</Col>
								</Row>
							}

							<Row>
								<Col>
									<TextField
										id={"gramsHour"}
										label={"Grams/Hour"}
										value={block?.exercise?.activities?.gramsHour}
										onChange={handleChangeExercise("gramsHour")}
										InputLabelProps={{
										shrink: true
										}}
										margin="normal"
										variant="standard"
										type='text'
										required
										multiline
									/>
								</Col>
								<Col>
									<TextField
										id={"gramsHourRes"}
										label={"Total grams"}
										value={block?.exercise?.activities?.totalGrams}
										onChange={handleChangeExercise("totalGrams")}
										InputLabelProps={{
										shrink: true
										}}
										margin="normal"
										variant="standard"
										type='text'
										required
										multiline
									/>
								</Col>
								<Col>
									<TextField
										id={"gramsChoHourRes"}
										label={"gCHO/h"}
										value={block?.exercise?.activities?.totalGChoH}
										onChange={handleChangeExercise("totalGChoH")}
										InputLabelProps={{
										shrink: true
										}}
										margin="normal"
										variant="standard"
										type='text'
										required
										multiline
									/>
								</Col>
								<Col>
									<TextField
										id={"gramsChoHourRes"}
										label={"Total gCHO"}
										value={block?.exercise?.activities?.totalGCho}
										onChange={handleChangeExercise("totalGCho")}
										InputLabelProps={{
										shrink: true
										}}
										margin="normal"
										variant="standard"
										type='text'
										required
										multiline
									/>
								</Col>
								<Col>
									<TextField
										id={"mlH"}
										label={"mL/h"}
										value={block?.exercise?.activities?.totalMlh}
										onChange={handleChangeExercise("totalMlh")}
										InputLabelProps={{
										shrink: true
										}}
										margin="normal"
										variant="standard"
										type='text'
										required
										multiline
									/>
								</Col>
								<Col>
									<TextField
										id={"mlH"}
										label={"Total mL"}
										value={block?.exercise?.activities?.totalMl}
										onChange={handleChangeExercise("totalMl")}
										InputLabelProps={{
										shrink: true
										}}
										margin="normal"
										variant="standard"
										type='text'
										required
										multiline
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<TextField
										id={"notes"}
										label={"Notes"}
										value={block?.exercise?.activities?.exerciseNotes}
										onChange={handleChangeExercise("exerciseNotes")}
										InputLabelProps={{
										shrink: true
										}}
										margin="normal"
										variant="standard"
										type='text'
										required
										multiline
									/>
								</Col>
							</Row>
							
							{block?.exercise?.activities?.typeOfActivity &&
								<>
									<Card>
										<CardBody>
											<Row>
												<Col><h3>Activity intensities</h3></Col>
												<Col className="text-right">
													<button
														type="button"
														className="btn btn-primary"
														style={{marginLeft: 10}}
														onClick={() => addNewIntensity()}
													>
														Add intensity
													</button>
												</Col>
											</Row>
											{!refresh &&
												infoElements?.length > 0 && (
													<Table
														columns={activitiesTable}
														data={getInfoElementsData(infoElements, block)}
													/>
												)}
										</CardBody>
										<EditBlockIntensityDialog
											open={openEditActivityDialog}
											setOpen={setOpenEditActivityDialog}
											data={selectedActivity}
											readOnly={false}
											patient={patient}
											activity={block.exercise.activities}
											type={block.exercise.activities.typeOfActivity}
											activities={activities}

											onSave={(intensity, intensityAnt) => {
												saveNewIntensity(intensity, intensityAnt);
											}}
										/>
									</Card>
								</>
							}
							<br /><br /><br /><br /><br /><br /><br /><br />
						</>
					)}

					{meal && (
						<>
							<Row>
								<Col>
									<TextField
										id={`kcals`}
										label="KCAL"
										InputProps={{
											inputProps: {
												min: 0
											},
											style: {
												color: block?.errorCalc?.kcals ? 'red' : 'black',
											},
										}}
										inputProps={{ readOnly: true }}
										value={formatFloat(block?.kcals) || 0}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
								<Col>
									<TextField
										id={`carbohydrates`}
										label="CHO"
										InputProps={{
											inputProps: {
												min: 0
											},
											style: {
												color: block?.errorCalc?.carbohydrates ? 'red' : 'black',
											},
										}}
										inputProps={{ readOnly: true }}
										value={formatFloat(block?.carbohydrates) || 0}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
								<Col>
									<TextField
										id={`proteins`}
										label="PRO"
										InputProps={{
											inputProps: {
												min: 0
											},
											style: {
												color: block?.errorCalc?.proteins ? 'red' : 'black',
											},
										}}
										inputProps={{ readOnly: true }}
										value={formatFloat(block?.proteins) || 0}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
								<Col>
									<TextField
										id={`fats`}
										label="FAT"
										InputProps={{
											inputProps: {
												min: 0
											},
											style: {
												color: block?.errorCalc?.fats ? 'red' : 'black',
											},
										}}
										inputProps={{ readOnly: true }}
										value={formatFloat(block?.fats) || 0}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
								<Col>
									<TextField
										id={`fiber`}
										label="FIBER"
										InputProps={{
											inputProps: {
												min: 0
											},
											style: {
												color: block?.errorCalc?.fiber ? 'red' : 'black',
											},
										}}
										inputProps={{ readOnly: true }}
										value={formatFloat(block?.fiber) || 0}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
							</Row>
							
							{typeStructure === "userStructure" && (
								<Row>
									<Col />
									<Col >
										<TextField
											id={`carbohydrates`}
											label={`CHO (g/kg)`}
											value={calcUnitsNt(
												block?.carbohydrates, 
												"g/kg", 
												"carbohydrates", 
												block?.kcal, 
												patient?.selectedWeight || 1
											)}
											InputLabelProps={{
											shrink: true
											}}
											margin="normal"
											variant="standard"
											required
											type="number"
											className='readonly'
										/>
									</Col>
									<Col >
										<TextField
											id={`protein`}
											label={`PRO (g/kg)`}
											value={calcUnitsNt(
												block?.proteins, 
												"g/kg", 
												"proteins", 
												block?.kcal, 
												patient?.selectedWeight || 1
											)}
											InputLabelProps={{
											shrink: true
											}}
											margin="normal"
											variant="standard"
											required
											type="number"
											className='readonly'
										/>
									</Col>
									<Col >
										<TextField
											id={`fats`}
											label={`FAT (g/kg)`}
											value={calcUnitsNt(
												block?.fats, 
												"g/kg", 
												"fats", 
												block?.kcal, 
												patient?.selectedWeight || 1
											)}
											InputLabelProps={{
											shrink: true
											}}
											margin="normal"
											variant="standard"
											required
											type="number"
											className='readonly'
										/>
									</Col>
									<Col />
								</Row>
							)}
							
							{block?.intake?.isSnack === '0' && (
								<>
									<Row>
										<Col sm={3}>
											<TextField
												id={`cubeVegetables`}
												label={"Vegetables"}
												value={block?.intake?.cubeVegetables}
												onChange={handleChangeCubes("cubeVegetables")}
												InputLabelProps={{
												shrink: true
												}}
												margin="normal"
												variant="standard"
												type='number'
												required
											/>
										</Col>
										<Col sm={3}>
											<TextField
												id={`cubeStarches`}
												label={"Starches"}
												value={block?.intake?.cubeStarches}
												onChange={handleChangeCubes("cubeStarches")}
												InputLabelProps={{
												shrink: true
												}}
												margin="normal"
												variant="standard"
												type='number'
												required
											/>
										</Col>
										<Col sm={3}>
											<TextField
												id={`cubeProteins`}
												label={"Protein"}
												value={block?.intake?.cubeProteins}
												onChange={handleChangeCubes("cubeProteins")}
												InputLabelProps={{
												shrink: true
												}}
												margin="normal"
												variant="standard"
												type='number'
												required
											/>
										</Col>
										<Col sm={3}>
											<TextField
												id={`cubeFats`}
												label={"Fat"}
												value={block?.intake?.cubeFats}
												onChange={handleChangeCubes("cubeFats")}
												InputLabelProps={{
												shrink: true
												}}
												margin="normal"
												variant="standard"
												type='number'
												required
											/>
										</Col>
									</Row>
									<br />
									<Row>
										<Col><h3>Extra ingredients</h3></Col>
										<Col className="text-right">
											<button
												type="button"
												className="btn btn-primary"
												style={{marginLeft: 10}}
												onClick={() => addNewMeal(true, true)}
											>
												Add extra food
											</button>
										</Col>
									</Row>
									{!refresh &&
										infoElements?.length > 0 && (
											<Table
												columns={elementsTable}
												data={getInfoElementsDataExtra(infoElements, block)}
											/>
										)
									}
									<br/><br/>
								</>
							)} 
							<>
							<br />
								<Row>
									<Col><h3>Ingredients</h3></Col>
									<Col className="text-right">
										<button
											type="button"
											className="btn btn-primary"
											style={{marginLeft: 10}}
											onClick={() => addNewMeal(true, false)}
										>
											Add food
										</button>
										<button
											type="button"
											className="btn btn-primary"
											style={{marginLeft: 10}}
											onClick={() => addNewMeal(false, false)}
										>
											Add recipe
										</button>
									</Col>
								</Row>
								{!refresh &&
									infoElements?.length > 0 && (
										<Table
											columns={elementsTable}
											data={getInfoElementsData(infoElements, block)}
										/>
									)}
							</>
							<EditBlockFoodDialog
								open={openEditMealDialog}
								setOpen={setOpenEditMealDialog}
								data={selectedMeal}
								readOnly={false}
								isFood={isFood}
								isExtra={isExtra}
								foods={foods}
								foodPlates={foodPlates}
								main={blockIsMain(block)}
								typeStructure={typeStructure}

								onSave={(meal) => {
									if (isFood) saveNewFood(meal);
									else saveNewRecipe(meal);
								}}
							/>
						</>
					)}			

					<br />
					</>
				</Card>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setBlock(null);
						setInfoElements([]);
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (meal && (block?.intake?.isSnack === undefined ||
							block?.intake?.isSnack === null)) {
							alertError({
								error: null,
								customMessage:
									"The type is required",
							});
						} else if (!block?.name) {
							alertError({
								error: null,
								customMessage:
									"The title is required",
							});
						} else {
							setOpen(false);

							let saveBlock = block;
							if (meal) {
								saveBlock.intake.isSnack = (saveBlock.intake.isSnack === '1')
							}

							onSave(saveBlock, meal);
							setBlock(null);
							setInfoElements([]);
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
			<ConfirmDialog
				title={
					"Are you sure you want to delete the recipe?"
				}
				open={openConfirmDialog === 1}
				setOpen={setOpenConfirmDialog}
				onConfirm={() => {
					if (selectedDeleteMeal?.cell || selectedDeleteMeal?.cell === 0) deleteElem(selectedDeleteMeal.cell, selectedDeleteMeal?.row)
					setSelectedDeleteMeal(null);
				}}
			/>
		</Dialog>
	);
	
};

export default EditStructureBlockDialog;