import { Button, MuiThemeProvider, TextField, Tooltip, createMuiTheme, } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import { shallowEqual, useSelector } from 'react-redux';
import OnlyTextDate from '../../../../components/textFields/OnlyTextDate';
import { Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls';
import { deleteUserIsakTests, postUserIsakTests, updateUserIsakTests } from '../../../../../api/userIsakTests';
import { alertError, alertSuccess } from '../../../../../utils/logger';
import { 
    getType, 
    calcError,
    calcsIsakTest,
    dcCalcOptions,
    calcDCThings,
    calcSpecificElement
} from '../../../../../utils/mesuresCalcs';
import EfficientlessText from '../../../../components/textFields/EfficientlessText';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import MyAutocomplete from '../../../../components/MyAutocomplete';
import OnlyNumberReadDec from '../../../../components/textFields/OnlyNumberReadDec';
import Plot from 'react-plotly.js';

const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyTest(patientId, gender) {
    const today = new Date();
    const date = new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().split('.')[0];
    return {
        userId: patientId,
        gender: gender,
        date: date,
        weight: 0,
		height: 0,
		sitHeight: 0,
		//Fold plecs
		tricepsFold: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		subcapularFold: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		bicepsFold: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		iliacCrestFold: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		supraspinalFold: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		abdominalFold: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		thighFold: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		twinFold: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		//Perimetral
		relaxedArmPerimetral: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		contractedArmPerimetral: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		waistPerimetral: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		hipPerimetral: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		thighPerimetral: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		twinPerimetral: [{measure1: 0, measure2: 0, error: 0 , measure3: 0, total: null, zValue: null}],
		//Diameter
		humerusDiameter: 0,
		bistyloidDiameter: 0,
		femurDiameter: 0,

        //Calcs
        zWeight: 0,
        zSitHeight: 0,
        zHumerus: 0,
        zBistyloid: 0,
        zFemur: 0,
        
        imc: 0,
        correctedArm: 0,
        correctedThigh: 0,
        correctedTwin: 0,
        sumSpecificPlecs: 0,
        sumPlecs: 0,
        waistHipIndex: 0,
        dc: 0,
        mg: 0,
        mG: 0,
        mLG: 0,
        zFatMass: 0,
        zFatFreeMass: 0,
        
        endomorphic: 0,
        mesomorphic: 0,
        ectomorphic: 0,
    }
}

export default function EditPatientIsakTest(props) {
    const { patientId, patient, isakTest, setIsakTest, setOpen } = props;

    const [basicMeasuresInfo, setBasicMeasuresInfo] = useState(false);
    const [foldsInfo, setFoldsInfo] = useState(false);
    const [perimetersInfo, setPerimetersInfo] = useState(false);
    const [diametersInfo, setDiametersInfo] = useState(false);
    const [othersInfo, setOthersInfo] = useState(false);
    const [corporalInfo, setCorporalInfo] = useState(false);
    const [somatotipInfo, setSomatotipInfo] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
    const [changes, setChanges] = useState(-2);

    const [refresh, setRefresh] = useState(false);

    const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

    useEffect(() => {
        if (!isakTest) setIsakTest(getEmptyTest(patientId, patient?.gender ? patient.gender : "male"));
        else {
            let newIsakTest = {...isakTest}
            newIsakTest.date = getDateFormat(isakTest);
            newIsakTest = refreshCalcs(newIsakTest);
            if (patient?.gender) newIsakTest.gender = patient.gender;
            setIsakTest(calcsIsakTest({...newIsakTest}));
        }
    }, [])

    useEffect(() => {
        if (changes <= 0) setChanges(changes+1)
            console.log(isakTest);
    }, [isakTest])

    useEffect(() => {
        setRefresh(false);
    }, [refresh])

    function floatFormatter(value) {
        const floatValue = parseFloat(value);
        if (Number.isInteger(floatValue)) return floatValue;
        else if (Math.round(floatValue*10)/10 === floatValue) return floatValue.toFixed(1);
        else return floatValue.toFixed(2);
    }

    const TextCalcField = React.useCallback(({title, element, number, data, type}) => {
        if (!data || !data[element]) return(<></>)
        else if ((number === "total" || number === "zValue") && !data[element][0][number]) return(
            <TextField 
                id={`${element}`}
                label={title}
                value={"Put the first ones"}
                InputLabelProps={{
                shrink: true
                }}
                inputProps={{readOnly: true}}
                margin="normal"
                variant="standard"
                className='readonly'
                type='text'
                required
            />
        )

        if ((number === "measure3") || number === "error" || number === "total" || number === "zValue") {
            const type = getType(element);
            const error = data[element][0]["error"];
            const errorAccepted = type === "Fold" ? error <= 5 : type === "Perimetral" ? error <= 1 : false;
            if (errorAccepted || number === "error" || number === "total" || number === "zValue") {
                return(
                    <TextField 
                        id={`${element}`}
                        label={title}
                        value={floatFormatter(data[element][0][number])}
                        InputLabelProps={{
                        shrink: true
                        }}
                        inputProps={{readOnly: true}}
                        margin="normal"
                        variant="standard"
                        className='readonly'
                        type='text'
                        required
                    />
                )
            }
        }
        return (
            <TextField
                id={`${element}`}
                label={title}
                value={floatFormatter(data[element][0][number])}
                onChange={handleCalcsChange(element, number)}
                InputLabelProps={{
                shrink: true
                }}
                margin="normal"
                variant="standard"
                type={type || "text"}
                required
            />
        )
    }, [])

    const SomatotipPlot = () => {
        const test = isakTest;
        const ect = parseFloat(test?.ectomorphic);
        const mes = parseFloat(test?.mesomorphic);
        const end = parseFloat(test?.endomorphic);

        if (ect && mes && end) {
            const x = ect - end;
            const y = 2*mes - (end + ect);
            const intX = parseInt(Math.abs(x))*2, intY = parseInt(Math.abs(y))*2;
            return (
                <Plot
                    data={[
                        {
                            type: 'scatter',
                            mode: 'markers',
                            x: [x],
                            y: [y],
                            marker: { size: 10, color: 'red' },
                            text: [`(${x.toFixed(1)}, ${y.toFixed(1)})`],
                            hoverinfo: 'text',
                        },
                    ]}
                    layout={{
                        width: 500,
                        height: 500,
                        title: 'Somatotip Graphic',
                        xaxis: {
                            range: [-intX, intX],
                            zeroline: true,
                            showgrid: true,
                            title: '',
                            nticks: 20
                        },
                        yaxis: {
                            range: [-intY, intY],
                            zeroline: true,
                            showgrid: false,
                            title: '',
                            nticks: 20
                        },
                        annotations: [
                            {
                                x: 0,
                                y: intY,
                                xref: 'x',
                                yref: 'y',
                                text: 'MESOMORPHIC',
                                showarrow: false,
                                font: {
                                size: 10,
                                color: 'black',
                                },
                                align: 'center',
                            },
                            {
                                x: -intX + intX/10, // Coordenadas para la anotación en la esquina inferior izquierda
                                y: -intY,
                                xref: 'x',
                                yref: 'y',
                                text: 'ENDOMORPHIC',
                                showarrow: false,
                                font: {
                                size: 10,
                                color: 'black',
                                },
                                align: 'left',
                            },
                            {
                                x: intX - intX/10, // Coordenadas para la anotación en la esquina inferior derecha
                                y: -intY,
                                xref: 'x',
                                yref: 'y',
                                text: 'ECTOMORPHIC',
                                showarrow: false,
                                font: {
                                size: 10,
                                color: 'black',
                                },
                                align: 'right',
                            },
                        ],
                    }}
                    config={{
                        staticPlot: false,
                        displayModeBar: false,
                        scrollZoom: false,
                        doubleClick: false,
                        displaylogo: false,
                    }}
                    />
            );
        }
        return (<></>);
    }

    const FoldPlecsPlot = () => {
        const test = isakTest;
        const triceps = parseFloat(test?.tricepsFold[0]?.total);
        const subcapular = parseFloat(test?.subcapularFold[0]?.total);
        const biceps = parseFloat(test?.bicepsFold[0]?.total);
        const iliacCrest = parseFloat(test?.iliacCrestFold[0]?.total)
        const supraspinal = parseFloat(test?.supraspinalFold[0]?.total);
        const abdominal = parseFloat(test?.abdominalFold[0]?.total);
        const thigh = parseFloat(test?.thighFold[0]?.total);
        const twin = parseFloat(test?.twinFold[0]?.total);

        if (triceps && subcapular && biceps && iliacCrest && 
        supraspinal && abdominal && thigh && twin) {
            
            const xNames = [
                "Triceps", "Subcapular", "Biceps", "Iliac Crest", "Supraspinal",
                "Abdominal", "Thigh", "Twin"
            ];   
            const yValues = [
                triceps, subcapular, biceps, iliacCrest, supraspinal,
                abdominal, thigh, twin
            ];

            return (
                <Plot
                    data={[
                        {
                            type: 'scatter',
                            mode: 'lines+markers',
                            x: xNames,
                            y: yValues,
                            marker: { size: 10, color: 'blue' },
                            line: { color: 'blue', width: 2 },
                            // name: '',
                            // showlegend: true,
                        },
                        // {
                        //     type: 'scatter',
                        //     mode: 'lines+markers',
                        //     x: ['Point A', 'Point B', 'Point C', 'Point D', 'Point E'],
                        //     y: [8, 10, 15, 12, 16],
                        //     marker: { size: 10, color: 'orange' },
                        //     line: { color: 'orange', width: 2 },
                        //     name: 'Data Series 2',
                        //     showlegend: true,
                        // }
                    ]}
                    layout={{
                        width: 600,
                        height: 400,
                        title: '',
                        xaxis: {
                            title: 'X Axis with Specific Names',
                            showgrid: false,
                        },
                        yaxis: {
                            title: 'Fold with (mm)',
                            showgrid: true,
                        },
                    }}
                    config={{
                        staticPlot: false,
                        displayModeBar: false,
                        scrollZoom: false,
                        doubleClick: false,
                        displaylogo: false,
                    }}
                />
            )
        }
        return (<></>)
    }

    const backgroundStyle = {backgroundColor: '#A7D971'}

    function OpenInformation(title, arrowTitle, info, setInfo) {
        const arrowStyle = {color: '#E1F2CE'}
        return (
            <>
                <br/>
                    <Row style={backgroundStyle}>
                        <Col sm={11} style={{marginTop: 12}}>
                            <h5 style={{fontWeight: 'bold'}}>{title}</h5>
                        </Col>
                        <Col sm={1}>
                            <Tooltip title={arrowTitle}>
                            <Button
								size="small"
                                style={{backgroundColor: 'transparent', borderColor: 'transparent', marginLeft: 40}}
								onClick={() => setInfo(!info)}
							>
                                {info? (<ExpandLess style={arrowStyle}/>) : 
                                (<ExpandMore style={arrowStyle}/>)}
							</Button>
                            </Tooltip>
                        </Col>
                    </Row>
                <br/>
            </>
        )
    }

    function getDateFormat(test) {
        const testDate = new Date(test.date);
        const date = new Date(testDate.getTime() - testDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
        return date;
    }

    function refreshCalcs(test) {
        return test;
    }

    const handleCalcsChange = (element, number) => (event) =>{
        const value = parseFloat(event.target.value);
        setIsakTest((prevIsakTest) => {
            let updatedElement = [...prevIsakTest[element]];
            updatedElement[0] = { ...updatedElement[0], [number]: value };
            if (number !== "total") {
                const res = calcError(updatedElement[0], getType(element));
                updatedElement[0] = { ...updatedElement[0], error: res.error, total: res.total };
            }
            const test = calcSpecificElement({...prevIsakTest, [element]: updatedElement}, element, number);
            return test;
        });
    } 

    const handleChange = (element) => (event) =>{
        const value = event.target.value;
        setIsakTest((prevIsakTest) => {
            let test = {...isakTest, [element]: value};  
            test = calcSpecificElement(test, element, null);
            return test;
        });
    } 

    function saveTest(test) {
        function alert(required) {
            alertError({
                error: null,
                customMessage: `${required} path is required` 
            })
        }
        if (test?._id)
            updateUserIsakTests(test?._id, test)
                .then((res) => {
                    alertSuccess({
                        title: "Saved!",
                        customMessage: "Isak test updated."
                    })
                    onClose();
                })
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not update isak test."
                    })
                })
        else postUserIsakTests(test)
                .then((res) => {
                    alertSuccess({
                        title: "Saved!",
                        customMessage: "Isak test updated."
                    })
                    onClose();
                })
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not update isak test."
                    })
                })
    }

    function onClose() {
        setOpen(false);
        setChanges(0);
    }

    return (
        <Card>
            <CardHeader title="Edit isak test">
                <div className="mt-5">
                    <Button
                        onClick={() => {
                            if (changes > 0) setOpenConfirmDialog(1);
                            else onClose();
                        }}
                        variant="outlined"
                        style={{ marginRight: "20px" }}
                    >
                        Back
                    </Button>
                    <Button
                        onClick={() => saveTest(isakTest)}
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: "20px" }}
                    >
                        Save isak test
                    </Button>
                    <ConfirmDialog
                        title={
                            "Are you sure you want to go back? You will lose all your changes"
                        }
                        open={openConfirmDialog === 1}
                        setOpen={setOpenConfirmDialog}
                        onConfirm={() => {
                            onClose();
                        }}
                    />
                    {isakTest?._id && user?.role.includes("admin") && (
                        <>
                            <MuiThemeProvider theme={theme}>
                                <Button
                                    onClick={() => setOpenConfirmDialog(2)}
                                    variant="outlined"
                                    color="secondary"
                                    style={{ marginRight: "20px" }}
                                >
                                    Delete isak test
                                </Button>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "auto",
                                    }}
                                ></div>
                            </MuiThemeProvider>

                            <ConfirmDialog
                                title={
                                    "Are you sure you want to delete this isak test?"
                                }
                                open={openConfirmDialog === 2}
                                setOpen={setOpenConfirmDialog}
                                onConfirm={() => {
                                    deleteUserIsakTests(isakTest?._id)
                                        .then((res) => {
                                            if (res.status === 204 || res.status === 200) {
                                                alertSuccess({
                                                    title: "Deleted!",
                                                    customMessage:
                                                        "Isak test deleted successfully.",
                                                });
                                                onClose();
                                            }
                                        })
                                        .catch((error) => {
                                            alertError({
                                                error: error,
                                                customMessage:
                                                    "Could not delete isak test.",
                                            });
                                        });
                                }}
                            />
                        </>
                    )}
                </div>
            </CardHeader>
            <CardBody>
                
                {/* INFORMACION BASICA */}
                <Row>
                    <Col sm={6}><OnlyTextDate title={"Date"} element={"date"} handleChange={handleChange} data={isakTest} specific={false}/> </Col>
                    <Col sm={6}><EfficientlessText title={"Observation"} element={"observations"} handleChange={handleChange} data={isakTest} type={"text"}/></Col>
                </Row>

                {OpenInformation("BASIC MEASURES", "Open basic measures", basicMeasuresInfo, setBasicMeasuresInfo)}
                    {basicMeasuresInfo && (
                        <>
                            <Row>
                                <Col sm={2}><EfficientlessText title={"Weight (kg)"} element={"weight"} handleChange={handleChange} data={isakTest} type={"number"}/></Col>
                                <Col sm={2}><EfficientlessText title={"Height (cm)"} element={"height"} handleChange={handleChange} data={isakTest} type={"number"}/></Col>
                                <Col sm={2}><EfficientlessText title={"Sitting height (cm)"} element={"sitHeight"} handleChange={handleChange} data={isakTest} type={"number"}/></Col>
                                <Col sm={2}><OnlyNumberReadDec title={"IMC (kg/m2)"} element={"imc"} data={isakTest} type={"number"}/></Col>
                                <Col sm={2}><OnlyNumberReadDec title={"Z Weight"} element={"zWeight"} data={isakTest} type={"number"}/></Col>
                                <Col sm={2}><OnlyNumberReadDec title={"Z Sitting height"} element={"zSitHeight"} data={isakTest} type={"number"}/></Col>
                            </Row>
                        </>
                    )}

                {OpenInformation("SKIN FOLDS", "Open skin folds", foldsInfo, setFoldsInfo)}
                {foldsInfo && (
                    <>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Triceps measure 1"} element={"tricepsFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Triceps measure 2"} element={"tricepsFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Triceps error"} element={"tricepsFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Triceps measure 3"} element={"tricepsFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Triceps value"} element={"tricepsFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Triceps"} element={"tricepsFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Subcapular measure 1"} element={"subcapularFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Subcapular measure 2"} element={"subcapularFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Subcapular error"} element={"subcapularFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Subcapular measure 3"} element={"subcapularFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Subcapular value"} element={"subcapularFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Subcapular"} element={"subcapularFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Biceps measure 1"} element={"bicepsFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Biceps measure 2"} element={"bicepsFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Biceps error"} element={"bicepsFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Biceps measure 3"} element={"bicepsFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Biceps value"} element={"bicepsFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Biceps"} element={"subcapularFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Iliac crest measure 1"} element={"iliacCrestFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Iliac crest measure 2"} element={"iliacCrestFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Iliac error"} element={"iliacCrestFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Iliac crest measure 3"} element={"iliacCrestFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Iliac crest value"} element={"iliacCrestFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Iliac crest"} element={"iliacCrestFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Supraspinal measure 1"} element={"supraspinalFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Supraspinal measure 2"} element={"supraspinalFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Supraspinal error"} element={"supraspinalFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Supraspinal measure 3"} element={"supraspinalFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Supraspinal value"} element={"supraspinalFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Supraspinal"} element={"supraspinalFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Abdominal measure 1"} element={"abdominalFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Abdominal measure 2"} element={"abdominalFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Abdominal error"} element={"abdominalFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Abdominal measure 3"} element={"abdominalFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Abdominal value"} element={"abdominalFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Abdominal"} element={"abdominalFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Thigh measure 1"} element={"thighFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Thigh measure 2"} element={"thighFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Thigh error"} element={"thighFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Thigh measure 3"} element={"thighFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Thigh value"} element={"thighFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Thigh"} element={"thighFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Twin measure 1"} element={"twinFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Twin measure 2"} element={"twinFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Twin error"} element={"twinFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Twin measure 3"} element={"twinFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Twin value"} element={"twinFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Twin"} element={"twinFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={2} />
                            <Col sm={9}><FoldPlecsPlot /></Col>
                        </Row>
                    </>
                )}

                {OpenInformation("PERIMETERS", "Open perimeters info", perimetersInfo, setPerimetersInfo)}
                {perimetersInfo && (
                    <>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Relaxed Arm measure 1"} element={"relaxedArmPerimetral"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Relaxed Arm measure 2"} element={"relaxedArmPerimetral"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Relaxed Arm error"} element={"relaxedArmPerimetral"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Relaxed Arm measure 3"} element={"relaxedArmPerimetral"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Relaxed Arm value"} element={"relaxedArmPerimetral"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Relaxed arm"} element={"relaxedArmPerimetral"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Contracted Arm measure 1"} element={"contractedArmPerimetral"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Contracted Arm measure 2"} element={"contractedArmPerimetral"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Contracted Arm error"} element={"contractedArmPerimetral"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Contracted Arm measure 3"} element={"contractedArmPerimetral"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Contracted Arm value"} element={"contractedArmPerimetral"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Contracted arm"} element={"contractedArmPerimetral"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Waist measure 1"} element={"waistPerimetral"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Waist measure 2"} element={"waistPerimetral"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Waist error"} element={"waistPerimetral"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Waist measure 3"} element={"waistPerimetral"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Waist value"} element={"waistPerimetral"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Waist"} element={"waistPerimetral"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Hip measure 1"} element={"hipPerimetral"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Hip measure 2"} element={"hipPerimetral"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Hip error"} element={"hipPerimetral"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Hip measure 3"} element={"hipPerimetral"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Hip value"} element={"hipPerimetral"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Hip"} element={"hipPerimetral"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Thigh measure 1"} element={"thighPerimetral"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Thigh measure 2"} element={"thighPerimetral"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Thigh error"} element={"thighPerimetral"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Thigh measure 3"} element={"thighPerimetral"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Thigh value"} element={"thighPerimetral"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Thigh"} element={"thighPerimetral"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={2}><TextCalcField title={"Twin measure 1"} element={"twinPerimetral"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Twin measure 2"} element={"twinPerimetral"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Twin error"} element={"twinPerimetral"} number={"error"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Twin measure 3"} element={"twinPerimetral"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Twin value"} element={"twinPerimetral"} number={"total"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><TextCalcField title={"Z Twin"} element={"twinPerimetral"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={4}><OnlyNumberReadDec title={"Corrected arm perimeter"} element={"correctedArm"} data={isakTest} type={"number"}/></Col>
                            <Col sm={4}><OnlyNumberReadDec title={"Corrected thigh perimeter"} element={"correctedThigh"} data={isakTest} type={"number"}/></Col>
                            <Col sm={4}><OnlyNumberReadDec title={"Corrected twin perimeter"} element={"correctedTwin"} data={isakTest} type={"number"}/></Col>
                        </Row>
                    </>
                )}

                {OpenInformation("DIAMETERS", "Open diameters info", diametersInfo, setDiametersInfo)}
                {diametersInfo && (
                    <>
                        <Row>
                            <Col sm={2}><EfficientlessText title={"Humerus (cm)"} element={"humerusDiameter"} handleChange={handleChange} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><OnlyNumberReadDec title={"Z Humerus"} element={"zHumerus"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><EfficientlessText title={"Bistyloid (cm)"} element={"bistyloidDiameter"} handleChange={handleChange} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><OnlyNumberReadDec title={"Z Bistyloid"} element={"zBistyloid"} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><EfficientlessText title={"Femur (cm)"} element={"femurDiameter"} handleChange={handleChange} data={isakTest} type={"number"}/></Col>
                            <Col sm={2}><OnlyNumberReadDec title={"Z Femur"} element={"zFemur"} data={isakTest} type={"number"}/></Col>
                        </Row>
                    </>
                )}
                
                {OpenInformation("OTHERS", "Open others", othersInfo, setOthersInfo)}
                {othersInfo && (
                    <>
                        <Row>
                            <Col sm={4}><OnlyNumberReadDec title={"6 PLECS (mm)"} element={"sumSpecificPlecs"} data={isakTest} type={"number"}/></Col>
                            <Col sm={4}><OnlyNumberReadDec title={"8 PLECS (mm)"} element={"sumPlecs"} data={isakTest} type={"number"}/></Col>
                            <Col sm={4}><OnlyNumberReadDec title={"Waist-Hip Index"} element={"waistHipIndex"} data={isakTest} type={"number"}/></Col>
                        </Row>
                    </>
                )}

                {OpenInformation("CORPORAL COMPOSATION", "Open corporal info", corporalInfo, setCorporalInfo)}
                {corporalInfo && (
                    <>
                        <Row>
                            <Col sm={4}>
                                <MyAutocomplete
                                options={ dcCalcOptions }
                                getOptionLabel={(option) => option.name}
                                value={
                                    dcCalcOptions?.find(
                                        (x) => x._id === isakTest?.dcCalcId
                                    ) || null
                                }
                                onChange={(event, selected) => {
                                    setIsakTest(calcDCThings({
                                        ...isakTest,
                                        dcCalcId: selected?._id
                                    }))
                                    setRefresh(true);
                                }}
                                placeholder="Select DC calc type"
                                label={"DC calc type"}
                                />
                            </Col>
                            <Col sm={4}><OnlyNumberReadDec title={"DC (kg/m3)"} element={"dc"} data={isakTest} type={"number"}/></Col>
                            <Col sm={4}><OnlyNumberReadDec title={"Greix (%)"} element={"greix"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={4}><OnlyNumberReadDec title={"MG (kh)"} element={"mG"} data={isakTest} type={"number"}/></Col>
                            <Col sm={4}><OnlyNumberReadDec title={"MLG (kg)"} element={"mLG"} data={isakTest} type={"number"}/></Col>
                            <Col sm={4}><OnlyNumberReadDec title={"Z Fat mass"} element={"zFatMass"} data={isakTest} type={"number"}/></Col>
                        </Row>
                    </>
                )}

                {OpenInformation("SOMATOTIP", "Open somatotip info", somatotipInfo, setSomatotipInfo)}
                {somatotipInfo && (
                    <>
                        <Row>
                            <Col sm={4}><OnlyNumberReadDec title={"Endomorphic"} element={"endomorphic"} data={isakTest} type={"number"}/></Col>
                            <Col sm={4}><OnlyNumberReadDec title={"Mesomorphic"} element={"mesomorphic"} data={isakTest} type={"number"}/></Col>
                            <Col sm={4}><OnlyNumberReadDec title={"Ectomorphic"} element={"ectomorphic"} data={isakTest} type={"number"}/></Col>
                        </Row>
                        <Row>
                            <Col sm={3}></Col>
                            <Col sm={9}><SomatotipPlot /></Col>
                        </Row>
                    </>
                )}
            </CardBody>
            
        </Card>
    )
}