import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { checkIsEmpty } from "../../../utils/helpers";
import { alertError } from "../../../utils/logger";
import MultilanguageTabBlock from "../MultilanguageTabBlock";
import Editor from "../editor/Editor";
import { Col, Row } from "react-bootstrap";
import { formattedValueNumber } from "../../../utils/numbers";

function getEmptyTram() {
	return {
		number: 0,
		name: "",
		longitude: "",
		positiveUnevenness: "",
		negativeUnevenness: "",
		extraInfo: {es: ""}
	}
}

const EditTramDialog = (props) => {
	const { title, data, open, setOpen, onSave, readOnly } = props;

	const [tram, setTram] = useState(getEmptyTram());

	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

	useEffect(() => {
		if (!open || !data) setTram(getEmptyTram());
		else setTram(data);
	}, [data, open]);

	const handleChange = (element, lang) => (event) => {
		const { value } = event.target;
		if (value === " ") return;
		if (element == "name") setTram({ ...tram, [element]: value });
		else if (element == "extraInfo") setTram({...tram, extraInfo: {es: value}})
		else {
			let formattedValue = formattedValueNumber(value, true);
			if (!tram[element]) tram[element] = {};
			let newText = tram[element];
			newText = formattedValue;
			setTram({ ...tram, [element]: newText });
		}
	};

	const handleChangeEditor = (element, value, lang) => {
        //Element = "description", Value = <p>A</p>, lang = "es"
        
		if (value?.trim() !== "") {
			if (lang) {
				if (!tram[element]) tram[element] = {};
				let newText = tram[element];
				newText[lang] = value;
				setTram({ ...tram, [element]: newText });
			} else setTram({ ...tram, [element]: value });
		}
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`name`}
					label="Name"
					value={(tram?.name) || ""}
					onChange={handleChange("name", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
					disabled={readOnly}
				/>
				<br />
				<TextField
					id={`extraInfo`}
					label="Extra information"
					value={(tram?.extraInfo?.es) || ""}
					onChange={handleChange("extraInfo", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
					multiline
					disabled={readOnly}
				/>
				{/* <Editor
					disabled={readOnly}
					body={
						tram?.extraInfo[lang] || ""
					}
					setBody={(new_body) => {
						handleChangeEditor("extraInfo", new_body, lang);
					}}
					className="max-height"
					placeholder={"Put the section information here..."}
					name="Extra information"
				/> */}
			</>
		);
	};

	return (
		<Dialog disableBackdropClick={true}
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				<MultilanguageTabBlock
					multilanguageTabContent={renderMultilanguageTabContent}
				/>
				<Row>
					<Col >
						<TextField
							id={"longitude"}
							label={"Cumulative distance (km)"}
							value={(tram.longitude)}
							onChange={handleChange('longitude', false)}
							InputLabelProps={{shrink: true}}
							margin="normal"
							variant="standard"
							type="number"
							InputProps={{
								inputProps: {
									min: 0
								}
							}}
							disabled={readOnly}
						/>
					</Col>
					<Col>
						<TextField
							id={"pstUnv"}
							label={"Cumulative elevation gain (m)"}
							value={parseFloat(tram?.positiveUnevenness)}
							onChange={handleChange('positiveUnevenness', false)}
							InputLabelProps={{shrink: true}}
							margin="normal"
							variant="standard"
							type="number"
							InputProps={{
								inputProps: {
									min: 0
								}
							}}
							disabled={readOnly}
						/>
					</Col>
					<Col >
						<TextField
							id={"ngtUnv"}
							label={"Cumulative elevation loss (m)"}
							value={parseFloat(tram?.negativeUnevenness)}
							onChange={handleChange('negativeUnevenness', false)}
							InputLabelProps={{shrink: true}}
							margin="normal"
							variant="standard"
							type="number"
							InputProps={{
								inputProps: {
									min: 0
								}
							}}
							disabled={readOnly}
						/>
					</Col>
				</Row>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setTram(getEmptyTram());
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (!tram?.name) {
							alertError({
								error: null,
								customMessage:
									"The title is required in at least one of the languages",
							});
						} else {
							setOpen(false);
							onSave({
								...tram,
								longitude: tram?.longitude ? tram.longitude : 0,
								negativeUnevenness: tram?.negativeUnevenness ? tram.negativeUnevenness : 0,
								positiveUnevenness: tram?.positiveUnevenness ? tram.positiveUnevenness : 0,
							});
							setTram(getEmptyTram());
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default EditTramDialog;