import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { ContentRoute } from '../_metronic/layout'
import { shallowEqual, useSelector } from 'react-redux';
import { DashboardPage } from './pages/DashboardPage'
import AdminsPage from './modules/ContentManager/admins/AdminsPage'
import EditAdminsPage from './modules/ContentManager/admins/EditAdminsPage'
import TextsPage from './modules/ContentManager/texts/TextsPage'
import EditTextsPage from './modules/ContentManager/texts/EditTextsPage'
import NotesPage from './modules/ContentManager/notes/NotesPage'
import EditNotesPage from './modules/ContentManager/notes/EditNotesPage'
import LanguagesPage from './modules/ContentManager/languages/LanguagesPage';
import EditLanguagesPage from './modules/ContentManager/languages/EditLanguagesPage';
import EditFoodFamiliesPage from "./modules/ContentManager/foodFamilies/EditFoodFamiliesPage";
import FoodFamiliesPage from "./modules/ContentManager/foodFamilies/FoodFamiliesPage";
import EditFoodsPage from "./modules/ContentManager/foods/EditFoodsPage";
import FoodsPage from "./modules/ContentManager/foods/FoodsPage";
import EditActivitiesPage from "./modules/ContentManager/activities/EditActivitiesPage";
import ActivitiesPage from "./modules/ContentManager/activities/ActivitiesPage";
import EditCompetitionTypesPage from "./modules/ContentManager/competitionTypes/EditCompetitionTypesPage";
import CompetitionTypesPage from "./modules/ContentManager/competitionTypes/CompetitionTypesPage";
import EditCompetitionsPage from "./modules/ContentManager/competitions/EditCompetitionsPage";
import CompetitionsPage from "./modules/ContentManager/competitions/CompetitionsPage";
import EditStructuresPage from "./modules/ContentManager/structures/EditStructuresPage";
import StructuresPage from "./modules/ContentManager/structures/StructuresPage";
import EditVisitTypesPage from "./modules/ContentManager/visitTypes/EditVisitTypesPage";
import VisitTypesPage from "./modules/ContentManager/visitTypes/VisitTypesPage";
import PatientsPage from './modules/ContentManager/patients/PatientsPage'
import EditPatientsPage from './modules/ContentManager/patients/EditPatientsPage'
import EditFoodPlatesPage from "./modules/ContentManager/foodPlates/EditFoodPlatesPage";
import FoodPlatesPage from "./modules/ContentManager/foodPlates/FoodPlatesPage";
import EditVisitsPage from "./modules/ContentManager/visits/EditVisitsPage";
import VisitsPage from "./modules/ContentManager/visits/VisitsPage";
import ProductsPatientPage from "./modules/ContentManager/productsPatient/ProductsPatientPage";
import EditProductsPatientPage from "./modules/ContentManager/productsPatient/EditProductsPatientPage";
import EditSendingsPage from "./modules/ContentManager/sendings/EditSendingsPage";
import SendingsPage from "./modules/ContentManager/sendings/SendingsPage";
import CalendarPage from "./modules/ContentManager/calendar/CalendarPage";
import SportFoodsPage from "./modules/ContentManager/sportFoods/SportFoodsPage";
import EditSportFoodPage from "./modules/ContentManager/sportFoods/EditSportFoodPage";
import DocumentsTagPage from './modules/ContentManager/documentsTag/DocumentsTagPage';
import EditDocumentsTagPage from './modules/ContentManager/documentsTag/EditDocumentsTagPage';
import BrandsPage from './modules/ContentManager/brands/BrandsPage';
import EditBrandPage from './modules/ContentManager/brands/EditBrandPage';

export default function BasePage() {
	const user = useSelector((store) => store.authentication?.user, shallowEqual);
	const isAdmin = user || user?.role !=='admin'

	return (
		<Switch>
			{/* Redirect from root URL to /dashboard. */ }
			<Redirect exact from="/" to="/dashboard" />
			<ContentRoute path="/dashboard" component={DashboardPage} />

			{/* USERS */}
			{/* Administrators */}
			<ContentRoute from="/admins" component={AdminsPage} />
			<ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />
			{/* Patients */}
			<ContentRoute from="/patients" component={PatientsPage} />
			<ContentRoute from="/edit-patient/:id?" component={EditPatientsPage} />

			{/* MANAGEMENT */}
			{/* Visits */}
			<ContentRoute from="/visits" component={VisitsPage} />
			<ContentRoute from="/edit-visit/:id?" component={EditVisitsPage} />
			{/* CALENDAR */}
			<ContentRoute from="/calendar" component={CalendarPage} />
			{/* Competitions */}
			<ContentRoute from="/competitions" component={CompetitionsPage} />
			<ContentRoute from="/edit-competition/:id?" component={EditCompetitionsPage} />
			{/* Structures */}
			<ContentRoute from="/structures" component={StructuresPage} />
			<ContentRoute
				from="/edit-structure/:id?"
				component={EditStructuresPage}
			/>
			{/* Food plates */}
			<ContentRoute
				from="/food-plates"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: FoodPlatesPage
				}
			/>
			<ContentRoute
				from="/edit-food-plate/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditFoodPlatesPage
				}
			/>
			{/* Foods */}
			<ContentRoute from="/foods" component={FoodsPage} />
			<ContentRoute from="/edit-food/:id?" component={EditFoodsPage} />
			{/* Sport Foods */}
			<ContentRoute from="/sport-foods" component={SportFoodsPage} />
			<ContentRoute from="/edit-sport-food/:id?" component={EditSportFoodPage} />
			{/* Activities */}
			<ContentRoute from="/activities" component={ActivitiesPage} />
			<ContentRoute
				from="/edit-activity/:id?"
				component={EditActivitiesPage}
			/>

			{/* COMMUNICATION */}
			{/* Sendings */}
			<ContentRoute from="/sendings" component={SendingsPage} />
			<ContentRoute
				from="/edit-sending/:id?"
				component={EditSendingsPage}
			/>

			{/* MISCELLANY */}
			{/* Brands */}
			<ContentRoute from="/brand" component={BrandsPage} />
			<ContentRoute from="/edit-brand/:id?" component={EditBrandPage} />
			{/* User documents tags */}
			<ContentRoute from="/documents-tag" component={DocumentsTagPage} />
			<ContentRoute from="/edit-documents-tag/:id?" component={EditDocumentsTagPage} />
			{/* Notes */}
			<ContentRoute from="/notes" component={!isAdmin ? DashboardPage : NotesPage} />
			<ContentRoute from="/edit-note/:id?" component={!isAdmin ? DashboardPage : EditNotesPage} />
			{/* Food families */}
			<ContentRoute from="/food-families" component={FoodFamiliesPage} />
			<ContentRoute
				from="/edit-food-family/:id?"
				component={EditFoodFamiliesPage}
			/>			
			{/* Products Patient*/}
			<ContentRoute from="/products-patient" component={ProductsPatientPage} />
			<ContentRoute
				from="/edit-products-patient/:id?"
				component={EditProductsPatientPage}
			/>
			{/* Competition Types */}
			<ContentRoute from="/competition-types" component={CompetitionTypesPage} />
			<ContentRoute
				from="/edit-competition-type/:id?"
				component={EditCompetitionTypesPage}
			/>
			{/* Visit Types */}
			<ContentRoute from="/visit-types" component={VisitTypesPage} />
			<ContentRoute
				from="/edit-visit-type/:id?"
				component={EditVisitTypesPage}
			/>
			{/* Languages */}
			<ContentRoute from='/languages' component={!isAdmin ? DashboardPage : LanguagesPage}/>
			<ContentRoute from='/edit-language/:id?' component={!isAdmin ? DashboardPage : EditLanguagesPage}/>
			{/* Texts */}
			<ContentRoute from="/texts" component={!isAdmin ? DashboardPage : TextsPage} />
			<ContentRoute from="/edit-text/:id" component={!isAdmin ? DashboardPage : EditTextsPage} />
			
			<Redirect to="/error" />
		</Switch>
	)
}
