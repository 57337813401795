import {
	Button,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { alertError } from "../../../../../utils/logger";
import { useSkeleton } from "../../../../hooks/useSkeleton";
import { Col, Row } from "react-bootstrap";
import { getTramsCompetitionUser } from "../../../../../api/tramXuser";
import Table, { buttonsStyle } from "../../../../components/tables/table";
import { getCompetitionById } from "../../../../../api/competition";
import OnlyTextRead from "../../../../components/textFields/OnlyTextRead";
import { BlocksTable, calcCompetitionData, DistributionIntakesTable, getDataTrams, IntakesCompetitionTable, NutritionalBag, tramsTable } from "../../../../../utils/competitionCalcs";
import { getUserDietsByUserIdAndDate } from "../../../../../api/userDiets";
import OnlyNumberRead from "../../../../components/textFields/OnlyNumberRead";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

export default function ViewPatientCompetition(props) {
	const { userId, dataComp, setOpen, competitions, onSave, userCompetitions, setUserCompetitions, changes, setChanges, patient } = props;
    
    const [competition, setCompetition] = useState(null);
    const [preCompetition, setPreCompetition] = useState([]);
    const [dayCompetition, setDayCompetition] = useState([]);
    const [preCompetitionNt, setPreCompetitionNt] = useState(null);
    const [dayCompetitionNt, setDayCompetitionNt] = useState(null);
    const [tramsXuser, setTramsXuser] = useState([]);
	
	const history = useHistory();
	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

    useEffect(() => {
        if (dataComp) {
            if (dataComp?.competitionId) 
                getCompetitionById(dataComp.competitionId)
                    .then((res) => {
                        setCompetition(calcCompetitionData(res.data));
                        disableLoadingData();
                    })
                    .catch((error) => {
                        alertError({
                            error: error,
                            customMessage: "Could not get competition"
                        })
                        setOpen(false);
                    })
            
            getPreCompetition(dataComp);
            getTramsIntakes(dataComp);
        }
    }, [dataComp])

    function getPreCompetition(competition) {
        if (userId && competition?.date && (preCompetition?.length === 0 || dayCompetition?.length === 0)) {
            const competitionDate = new Date(competition.date);
            let date = new Date(competitionDate);
            date.setDate(date.getDate() - 1);
            getUserDietsByUserIdAndDate(userId, date.toISOString())
                .then((res) => {
                    setPreCompetition(res.data.diet.blocks);
                })
                .catch((error) => {
                    console.log(error);
                })
            getUserDietsByUserIdAndDate(userId, competitionDate.toISOString())
                .then((res) => {
                    setDayCompetition(res.data.diet.blocks);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    function getTramsIntakes(competition) {
        if (userId && competition?.date && tramsXuser?.length === 0) {
            const date = new Date(competition?.date);
            getTramsCompetitionUser(competition?.competitionId, userId, date.toISOString())
				.then((res) => {
					if (res.status === 200) {
						setTramsXuser(res.data);
						disableLoadingData();
					}
				})
				.catch((error) => {
					console.log("No hay tramos")
				});
        }
    }

    const Title = ({title}) => {
        return (
            <>
                <br />
                <h3>{title}</h3>
                <hr style={{border: "1px solid grey"}}/>
            </>
        )
    }

    const Subtitle = ({subT}) => {
        return (
            <>
                <br />
                <h4>{subT}</h4>
            </>
        )
    }

    if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
                    <CardHeader title={"View document"}>
                        <div className='mt-5'>
                        <Button
                            onClick={() => {
                                setOpen(false);
                            }}
                            variant="outlined"
                            style={{ marginRight: '20px' }}>
                            Back
                        </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Title title="1. Datos de la competición"/>
                        <Subtitle subT="Datos Básicos" />
                        <Row>
                            <Col sm={4}><OnlyTextRead title="Lugar" element="location" data={competition} /></Col>
                            <Col sm={2}><OnlyTextRead title="Hora de salida" element="departTime" data={competition} /></Col>
                            <Col sm={2}><OnlyTextRead title="Dist. (km)" element="totalKilometers" data={competition} /></Col>
                            <Col sm={2}><OnlyTextRead title="Desnivel + (m)" element="positiveUnevenness" data={competition} /></Col>
                            <Col sm={2}><OnlyTextRead title="Desnivel - (m)" element="negativeUnevenness" data={competition} /></Col>
                        </Row>

                        <Subtitle subT="Previsión meteorológica" />
                        <Row>
                            <Col sm={4}><OnlyTextRead title="Tipo" element="" data={competition} /></Col>
                            <Col sm={4}><OnlyTextRead title="Temperatura" element="" data={competition} /></Col>
                            <Col sm={4}><OnlyTextRead title="Humedad relativa" element="" data={competition} /></Col>
                        </Row>

                        <Subtitle subT="Perfil" />
                        <h7>Imagen</h7>
                        <br />

                        <Title title="2. Previsión cronológica de la carrera"/>
                        <Subtitle subT="Datos básicos"/>
                        <Row>
                            <Col sm={4}><OnlyTextRead title="Tiempo" element="time" data={competition} /></Col>
                            <Col sm={4}><OnlyTextRead title="Ritmo medio" element="medianRythym" data={competition} /></Col>
                            <Col sm={4}><OnlyTextRead title="Hora llegada" element="arrivalTime" data={competition} /></Col>
                        </Row>

                        <Subtitle subT={"Puntos de paso"}/>
                        <Table columns={tramsTable} data={getDataTrams(competition?.trams)} hideSearchTable={true} hidePagination={true}/>
                        
                        <Title title="3. Planificación de carga de hidratos"/>
                        <Subtitle subT={"24h pre-competición"}/>
                        {preCompetitionNt && (
                            <Row>
                                <Col sm={4}><OnlyNumberRead title="Kcals" element="kcals" data={preCompetitionNt} /></Col>
                                <Col sm={2}><OnlyNumberRead title="Carbohydrates" element="carbohydrates" data={preCompetitionNt} /></Col>
                                <Col sm={2}><OnlyNumberRead title="Proteins" element="proteins" data={preCompetitionNt} /></Col>
                                <Col sm={2}><OnlyNumberRead title="Fiber" element="fiber" data={preCompetitionNt} /></Col>
                                <Col sm={2}><OnlyNumberRead title="Fats" element="fats" data={preCompetitionNt} /></Col>
                            </Row>
                        )}
                        <BlocksTable blocks={preCompetition} setNt={setPreCompetitionNt} patient={patient}/>

                        <Subtitle subT="Desayuno pre-competición"/>
                        {dayCompetitionNt && (
                            <Row>
                                <Col sm={4}><OnlyNumberRead title="Kcals" element="kcals" data={dayCompetitionNt} /></Col>
                                <Col sm={2}><OnlyNumberRead title="Carbohydrates" element="carbohydrates" data={dayCompetitionNt} /></Col>
                                <Col sm={2}><OnlyNumberRead title="Proteins" element="proteins" data={dayCompetitionNt} /></Col>
                                <Col sm={2}><OnlyNumberRead title="Fiber" element="fiber" data={dayCompetitionNt} /></Col>
                                <Col sm={2}><OnlyNumberRead title="Fats" element="fats" data={dayCompetitionNt} /></Col>
                            </Row>
                        )}
                        <BlocksTable blocks={dayCompetition} setNt={setDayCompetitionNt} patient={patient}/>
                        
                        <Title title={"4. Tabla de alimentos de la competición"}/>
                        <IntakesCompetitionTable tramsXuser={tramsXuser}/>
                        
                        <Title title={"5. Resumen de la ingesta pautada"}/>
                        {/* {<table></table>} */}
                        
                        <Title title={"6. Mochila nutricional"}/>
                        En la siguiente tabla encontrarás todo lo que debes llevar a la competición según
                        lo previsto para que no te olvides nada.
                        <br/>
                        <NutritionalBag tramsXuser={tramsXuser} trams={competition.trams}/>
                        
                        <Title title={"7. Sugerencia de distribución"}/>
                        Aquí tienes una sugerencia personalizada de distribución de los alimentos. Te aconsejo que lo imprimas, lo plastifiques (con plástico especial o con cello) i lo lleves contigo durante toda la cursa para tenerlo a mano en todo momento y que no se te olvide nada.
                        <br/>
                        {competition?.trams && (<DistributionIntakesTable trams={competition.trams} tramsXuser={tramsXuser}/>)}
                        
                        <Title title={"8. Otras consideraciones"}/>
                        {competition["otherConsiderations"] || ""}
                    </CardBody>
                </Card>
			</>
		);
}
