import React, { useState, useEffect } from 'react'
import { deleteUserSweatTests, getUserSweatTestsByUserId } from '../../../../../api/userSweatTests';
import { alertError, alertSuccess } from '../../../../../utils/logger';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { shallowEqual, useSelector } from 'react-redux';
import { Button, Tooltip } from "@material-ui/core";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
} from "../../../../components/tables/table";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import EditPatientSweatTest from './EditPatientSweatTest';
import EditPatientIsakTest from './EditPatientIsakTest';
import { deleteUserIsakTests, getUserIsakTestsByUserId } from '../../../../../api/userIsakTests';
import { Col, Row } from 'react-bootstrap';
import { formatFloat, TableHeader } from '../../../../../utils/helpers';
import EditBodyWeight from '../../../../components/dialogs/userMeasures/EditBodyWeight';
import EditAbdominalWeight from '../../../../components/dialogs/userMeasures/EditAbdominalWeight';
import { postUserBodyWeights, updateUserBodyWeights, getUserBodyWeightsByUserId, deleteUserBodyWeights } from '../../../../../api/userBodyWeights';
import { deleteUserAbdominalWeights, getUserAbdominalWeightsByUserId, postUserAbdominalWeights, updateUserAbdominalWeights } from '../../../../../api/userAbdominalWeights';
import { calcPerimetralAndFoldThings, calcsIsakTest, losedWeight, sweatRate } from '../../../../../utils/mesuresCalcs';

export default function EditPatientMeasures(props) {
    const { patientId, patient } = props;
    
    const [bodyWeights, setBodyWeights] = useState([]);    
    const [openEditBodyWeight, setOpenEditBodyWeight] = useState(false);
    const [selectedBodyWeight, setSelectedBodyWeight] = useState(null);

    const [abdominalWeights, setAbdominalWeights] = useState([]);    
    const [openEditAbdominalWeight, setOpenEditAbdominalWeight] = useState(false);
    const [selectedAbdominalWeight, setSelectedAbdominalWeight] = useState(null);

    const [sweatTests, setSweatTests] = useState([]);    
    const [openEditSweatTest, setOpenEditSweatTest] = useState(false);
    const [selectedSweatTest, setSelectedSweatTest] = useState(null);
    
    const [isakTests, setIsakTests] = useState([]);    
    const [openEditIsakTest, setOpenEditIsakTest] = useState(false);
    const [selectedIsakTest, setSelectedIsakTest] = useState(null);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
    const [refresh, setRefresh] = useState(false);
    const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

    useEffect(() => {
        if (!openEditBodyWeight) refreshBodyWeights();
    }, [])

    
    useEffect(() => {
        if (!openEditAbdominalWeight) refreshAbdominalWeights();
    }, [])

    useEffect(() => {
        if (!openEditSweatTest) refreshSweatTests();
    }, [openEditSweatTest])

    
    useEffect(() => {
        if (!openEditIsakTest) refreshIsakTests();
    }, [openEditIsakTest])

    useEffect(() => {
        setRefresh(false);
    }, [refresh])

    function refreshTest(getTest, setTest, type) {
        getTest(patientId)
            .then((res) => {
                if (res.status === 200) {
                    setTest(res.data);
                    setRefresh(true)
                }
            })
            .catch((error) => {
                if (error?.request?.status !== 404) {
                    alertError({
                        error: error,
                        customMessage: `Could not get ${type}`
                    })
                }
            })
    }

    function refreshBodyWeights() {
        refreshTest(getUserBodyWeightsByUserId, setBodyWeights, "body weights");
    }

    function refreshAbdominalWeights() {
        refreshTest(getUserAbdominalWeightsByUserId, setAbdominalWeights, "abdominal weights");
    }

    function refreshSweatTests() {
        refreshTest(getUserSweatTestsByUserId, setSweatTests, "sweat tests");
    }

    function refreshIsakTests() {
        refreshTest(getUserIsakTestsByUserId, setIsakTests, "isak tests");
    }

    const bodyWeightColumns = [
        {
            dataField: "date",
            text: "date",
            formatter: dateFormatter
        },
        {
            dataField: "weight",
            text: "weight",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "_id",
            text: "",
            align: "right",
            formatter: bodyWeightbuttonFormatter
        }
    ]

    const abdominalWeightColumns = [
        {
            dataField: "date",
            text: "date",
            formatter: dateFormatter
        },
        {
            dataField: "maxAbdominalGrith",
            text: "Max Abdominal G",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "minAbdominalGrith",
            text: "Min Abdominal G",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "_id",
            text: "",
            align: "right",
            formatter: abdominalWeightbuttonFormatter
        }
    ]

    const sweatTestsColumns = [
        {
            dataField: "date",
            text: "date",
            formatter: dateFormatter
        },
        {
            dataField: "temp",
            text: "Temperature (ºC)",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "intensity",
            text: "Intensity",
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "sweatRate",
            text: "Sweat rate",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "_id",
            text: "",
            align: "right",
            formatter: sweatTestbuttonFormatter
        }
    ]

    const isakTestsColumns = [
        {
            dataField: "date",
            text: "date",
            formatter: dateFormatter
        },
        {
            dataField: "sumSix",
            text: "Sum of 6 skinfolds",
            formatter: formatFloat,
            align: 'center',
            headerAlign: 'center'
        },
        {
            dataField: "sumEight",
            text: "Sum of 8 skinfolds",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "fatMass",
            text: "Fat mass",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "fatFree",
            text: "Fat-free mass",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "_id",
            text: "",
            align: "right",
            formatter: isakTestbuttonFormatter,
            headerStyle: { width: "110px" }
        }
    ]

    function dateFormatter(cell) {
        const date = new Date(cell);
        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }

    function hourFormatter(cell) {
        const date = new Date(cell);
        const hour = date.getHours();
        const min = date.getMinutes();
        const sec = date.getSeconds();
        return `${hour}:${min}:${sec}`;
    }

    function bodyWeightbuttonFormatter(cell, row) {
        const elem = bodyWeights?.find((x) => x._id === cell);

        return (
            <>
                <Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
                            setOpenEditBodyWeight(true);
                            setSelectedBodyWeight(elem);
                        }}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
                                    setOpenConfirmDialog(4);
                                    setSelectedBodyWeight(elem);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
            </>
        )
    }
    
    function abdominalWeightbuttonFormatter(cell, row) {
        const elem = abdominalWeights?.find((x) => x._id === cell);

        return (
            <>
                <Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
                            setOpenEditAbdominalWeight(true);
                            setSelectedAbdominalWeight(elem);
                        }}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
                                    setOpenConfirmDialog(5);
                                    setSelectedAbdominalWeight(elem);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
            </>
        )
    }

    function sweatTestbuttonFormatter(cell, row) {
        const elem = sweatTests?.find((x) => x._id === cell);

        return (
            <>
                <Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
                            setOpenEditSweatTest(true);
                            setSelectedSweatTest(elem);
                        }}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
                                    setOpenConfirmDialog(2);
                                    setSelectedSweatTest(elem);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
            </>
        )
    }

    function isakTestbuttonFormatter(cell, row) {
        const elem = isakTests?.find((x) => x._id === cell);

        return (
            <>
                <Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
                            setOpenEditIsakTest(true);
                            setSelectedIsakTest(elem);
                        }}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
                                    setOpenConfirmDialog(3);
                                    setSelectedIsakTest(elem);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
            </>
        )
    }

    function getBodyWeightsData(tests) {
        let data = [];

        for (let i = 0; i < tests?.length; ++i) {
            let elem = {};
            const actT = tests[i];

            elem._id = actT?._id;
            elem.weight = actT?.weight;
            elem.date = actT?.date;

            data.push(elem);
        }

        return data;
    }

    function getAbdominalWeightsData(tests) {
        let data = [];

        for (let i = 0; i < tests?.length; ++i) {
            let elem = {};
            const actT = tests[i];

            elem._id = actT?._id;
            elem.date = actT?.date;
            elem.maxAbdominalGrith = actT?.maxAbdominalGrith;
            elem.minAbdominalGrith = actT?.minAbdominalGrith;

            data.push(elem);
        }

        return data;
    }

    function getSweatTestsData(tests) {
        let data = [];

        for (let i = 0; i < tests?.length; ++i) {
            let elem = {};
            const actT = tests[i];

            elem._id = actT?._id;
            elem.date = actT?.date;
            elem.temp = actT?.averageTemperature;
            elem.intensity = actT?.trainingIntensity;

            const losedW = losedWeight(actT.preTrainingWeight, actT.postTrainingWeight, 
                actT.intakeWeight, actT.urinations, actT.depositions);
            elem.sweatRate = sweatRate(losedW, actT.trainingDuration);

            data.push(elem);
        }

        return data;
    }

    function getIsakTestsData(tests) {
        let data = [];

        for (let i = 0; i < tests?.length; ++i) {
            let elem = {};
            const actT = tests[i];

            elem._id = actT?._id;
            elem.date = actT?.date;

            const calcs = calcsIsakTest(actT);
            elem.sumSix = calcs.sumSpecificPlecs;
            elem.sumEight = calcs.sumPlecs;
            elem.fatMass = calcs.zFatMass;
            elem.fatFree = calcs.zFatFreeMass;

            data.push(elem);
        }

        return data;
    }

    async function saveTest(post, update, value, refreshTest, type) {
        const id = value?._id;
        if (!id) {
            const res = await post(value)
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: `Could not save ${type}`
                    })
                    return false;
                })
            if (res) {
                alertSuccess({
                    title: "Success!",
                    customMessage: `${type} saved succesfully.`
                })
                refreshTest();
                return true;
            }
            return false;
        }
        else {
            const res = await update(id, value)
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: `Could not update ${type}`
                    })
                })
            if (res) {
                alertSuccess({
                    title: "Success!",
                    customMessage: `${type} saved succesfully.`
                })
                refreshTest();
                return true;
            }
            return false;
        }
    }

    async function saveBodyWeight(bodyWeight) {
        return await saveTest(postUserBodyWeights, updateUserBodyWeights, 
            bodyWeight, refreshBodyWeights, "Body weight")
    }

    async function saveAbdominalWeight(abdominalWeight) {
        return await saveTest(postUserAbdominalWeights, updateUserAbdominalWeights, 
            abdominalWeight, refreshAbdominalWeights, "Abdominal weight")
    }

    function deleteTest(deleteTestById, id, type, refresh) {
        deleteTestById(id)
        .then((res) => {
            if (res.status === 204 || res.status === 200) {
                alertSuccess({
                    title: "Deleted!",
                    customMessage:
                        `${type} deleted successfully.`,
                });
                refresh();
            }
        })
        .catch((error) => {
            alertError({
                error: error,
                customMessage:
                    `Could not delete ${type}.`,
            });
        });
    }

    function deleteBodyWeight(id) {
        deleteTest(deleteUserBodyWeights, id, "Body weight", refreshBodyWeights);
    }

    function deleteAbdominalWeight(id) {
        deleteTest(deleteUserAbdominalWeights, id, "Abdominal weight", refreshAbdominalWeights);
    }

    function deleteSweatTest(id) {
        deleteTest(deleteUserSweatTests, id, "Sweat test", refreshSweatTests);
    }

    function deleteIsakTest(id) {
        deleteTest(deleteUserIsakTests, id, "Isak test", refreshIsakTests);
    }

    function DialogDelete({type, number, id, deleteFunction}) {
        return (
            <ConfirmDialog
                title={
                    `Are you sure you want to delete this ${type}?`
                }
                open={openConfirmDialog === number}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                    deleteFunction(id)
                }}
            />
        )
    }

    if (openEditSweatTest) return(
        <EditPatientSweatTest 
        patientId={patientId}
        sweatTest={selectedSweatTest}
        setSweatTest={setSelectedSweatTest}
        setOpen={setOpenEditSweatTest}
        />
    )
    else if (openEditIsakTest) return(
        <EditPatientIsakTest 
        patientId={patientId}
        patient={patient}
        isakTest={selectedIsakTest}
        setIsakTest={setSelectedIsakTest}
        setOpen={setOpenEditIsakTest}
        />
    )
    return (
        <Card>
            <DialogDelete type="sweat test" number={2} id={selectedSweatTest?._id} deleteFunction={deleteSweatTest}/>
            <DialogDelete type="isak test" number={3} id={selectedIsakTest?._id} deleteFunction={deleteIsakTest}/>
            <DialogDelete type="body weight" number={4} id={selectedBodyWeight?._id} deleteFunction={deleteBodyWeight}/>
            <DialogDelete type="abdominal grith" number={5} id={selectedAbdominalWeight?._id} deleteFunction={deleteAbdominalWeight}/>

            <CardBody>
                
                <Row>
                    <Col>
                        <TableHeader 
                            title="Body weights"
                            onClick={() => {
                                setOpenEditBodyWeight(true);
                                setSelectedBodyWeight(null);
                            }}
                            numberCol={2} 
                        />
                        {!refresh && bodyWeights?.length > 0 && <Table data={getBodyWeightsData(bodyWeights)} columns={bodyWeightColumns}/>}
                    </Col>
                    <Col>
                    {/* Aqui El grafico correspondiente */}
                    </Col>
                </Row>
                <EditBodyWeight
                    open={openEditBodyWeight}
                    setOpen={setOpenEditBodyWeight} 
                    data={selectedBodyWeight}
                    patientId={patientId}
                    onSave={async (bodyWeight) => {
                        return await saveBodyWeight(bodyWeight)
                    }}
                />

                <br />
                <Row>
                    <Col>
                        <TableHeader 
                            title="Abdominal griths"
                            onClick={() => {
                                setOpenEditAbdominalWeight(true);
                                setSelectedAbdominalWeight(null);
                            }}
                            numberCol={2} 
                        />
                        {!refresh && abdominalWeights?.length > 0 && <Table data={getAbdominalWeightsData(abdominalWeights)} columns={abdominalWeightColumns}/>}
                    </Col>
                    <Col>
                    {/* Aqui El grafico correspondiente */}
                    </Col>
                </Row>
                <EditAbdominalWeight
                    open={openEditAbdominalWeight}
                    setOpen={setOpenEditAbdominalWeight} 
                    data={selectedAbdominalWeight}
                    patientId={patientId}
                    onSave={async (abdominalWeight) => {
                        return await saveAbdominalWeight(abdominalWeight)
                    }}
                />
                
                <br />
                <Row>
                    <Col>
                        <TableHeader 
                            title="Sweat rate tests"
                            onClick={() => {
                                setOpenEditSweatTest(true);
                                setSelectedSweatTest(null);
                            }}
                            numberCol={2} 
                        />
                        {!refresh && sweatTests?.length > 0 && <Table data={getSweatTestsData(sweatTests)} columns={sweatTestsColumns}/>}
                    </Col>
                    <Col>
                    {/* Aqui El grafico correspondiente */}
                    </Col>
                </Row>
                
                <br />
                <Row>
                    <Col>
                        <TableHeader 
                            title="ISAK1 Anthropometry"
                            onClick={() => {
                                setOpenEditIsakTest(true);
                                setSelectedIsakTest(null);
                            }}
                            numberCol={2} 
                        />
                        {!refresh && isakTests?.length > 0 && <Table data={getIsakTestsData(isakTests)} columns={isakTestsColumns}/>}
                    </Col>
                    <Col>
                    {/* Aqui El grafico correspondiente */}
                    </Col>
                </Row>

            </CardBody>
        </Card>
    )
}