import { API, authClient } from "../index";

export const getUserAbdominalWeights = () => {
	return authClient().get(`${API}/user-abdominal-weights?getDisabled=true`);
};

export const getUserAbdominalWeightsById = (id) => {
	return authClient().get(`${API}/user-abdominal-weights/${id}`);
};

export const getUserAbdominalWeightsByUserId = (userId) => {
	return authClient().get(`${API}/user-abdominal-weights/user/${userId}`);
};

export const deleteUserAbdominalWeights = (id) => {
	return authClient().delete(`${API}/user-abdominal-weights/${id}`);
};

export const postUserAbdominalWeights = async (userAbdominalWeights) => {
	return authClient().post(`${API}/user-abdominal-weights`, userAbdominalWeights);
};

export const updateUserAbdominalWeights = async (id, userAbdominalWeights) => {
	return authClient().put(`${API}/user-abdominal-weights/${id}`, userAbdominalWeights);
};

export const changeStatusUserAbdominalWeights = async (id, active) => {
	return authClient().put(`${API}/user-abdominal-weights/change-status/${id}`, {
		active,
	});
};