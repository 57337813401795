import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { changeStatusSportFood, deleteSportFood, getSportFoods } from "../../../../api/sportFood";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
} from "../../../components/tables/table";

function getData(foods) {
	let data = [];
	for (let i = 0; i < foods.length; ++i) {
		const elem = {};
		const actF = foods[i];
		elem.name = getNonEmpty(actF.fullName);
		elem.createdAt = actF.createdAt;
		elem.id = actF._id;
		elem.active = actF.active;
		elem.kcals = actF?.kcals || 0;
		elem.carbohydrates = actF?.carbohydrates || 0;
		elem.proteins = actF?.proteins || 0;
		elem.fats = actF?.fats || 0;
		data = data.concat(elem);
	}

	data.sort((a, b) => {
		const nameA = a.name.toLowerCase();
		const nameB = b.name.toLowerCase();
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0;
	});

	return data;
}

export default function SportFoodsPage() {
	const [data, setData] = useState([]);
	const [foodId, setFoodId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-sport-food/" + cell)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setFoodId(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "#00F" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setFoodId(cell);
									setOpenConfirmDialog(2);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const numbersHeaderStyle = { width: '80px' };

	const columns = [
		{
			dataField: "name",
			text: "Full name",
			sort: true,
		},
		{
			dataField: "kcals",
			text: "KCAL",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "carbohydrates",
			text: "CHO",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "proteins",
			text: "PRO",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "fats",
			text: "fat",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{ 
			dataField: "id", 
			text: "", 
			align: 'right',
			headerStyle: {width: '150px'},
			formatter: buttonFormatter 
		},
	];

	useEffect(() => {
		getSportFoods()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get sport foods.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Sport foods list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push("/edit-sport-food")}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${
							foodId?.active ? "disable" : "enable"
						} this food?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusSportFood(foodId.id, !foodId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												foodId?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Sport food ${
												foodId?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											foodId?.active
												? "disable"
												: "enable"
										} food.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={"Are you sure you want to remove this food?"}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteSportFood(foodId)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Sport food removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: "Could not remove food.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
