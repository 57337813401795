import {
	Button,
	MuiThemeProvider,
	TextField,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
	deleteSportFood,
	getSportFoodById,
	postSportFood,
	updateSportFood,
} from "../../../../api/sportFood";
import { checkIsEmpty, formatFixedFloat, } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { Col, Row } from "react-bootstrap";
import MyAutocomplete from "../../../components/MyAutocomplete";
import OnlyText from "../../../components/textFields/OnlyText";
import { formattedValueNumber } from "../../../../utils/numbers";
import { getBrands } from "../../../../api/brand";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyFood() {
	return {
		fullName: { },
		description: "",
		texture: "",
		category: "",
		flavour: "",
		brand: null,
		weight: 0,
		kcals: 0,
		carbohydrates: 0,
		proteins: 0,
		fats: 0,
		water: 0,
		sodium: 0,
		caffeine: 0,
		glu: 0,
		fru: 0,
		active: true
	};
}

export default function EditSportFoodPage() {
	const [food, setFood] = useState(getEmptyFood());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
	const [refresh, setRefresh] = useState(false);
	const [brandOptions, setBrandOptions] = useState([]);
	const foodId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	
	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	const [changes, setChanges] = useState(-2);

	const textureOptions = ["Liquid", "Semisolid", "Solid"];
	const categoryOptions = ["Homemade", "Commercial"];
	const flavourOptions = ["Sweet", "Salty", "Neutral"];

	useEffect(() => {
		getBrands()
			.then((res) => {
				setBrandOptions(res.data);
			})
			.catch((error) => {
				if (error.status !== 404) {
					alertError({
						error: error,
						customMessage: "Could not get brands"
					})
				}
			})
	}, [])
	useEffect(() => {
		if (!foodId) setChanges(changes+2);
		else setChanges(changes+1);
	}, [food])

	useEffect(() => {
		setRefresh(false);
	}, [refresh])

	useEffect(() => {
		if (!foodId) {
			disableLoadingData();
			return;
		}
		getSportFoodById(foodId)
			.then((res) => {
				if (res.status === 200) {
					setFood(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get food.",
				});
				history.push("/sport-foods");
			});
	}, [foodId, disableLoadingData, history]);

	function saveFood() {
		if (checkIsEmpty(food.fullName))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		if (!foodId) {
			postSportFood(food)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Food successfully created.",
						});
						history.push("/sport-foods");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save food.",
					});
				});
		} else {
			updateSportFood(foodId, food)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/sport-foods");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	function reCalcKcals(food) {
		const newKcals = food.proteins * 4 + 
						food.carbohydrates * 4 + 
						food.fats * 9 +
						food.ethanol * 7;

		return newKcals;
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!food[element]) food[element] = {};
			let newText = food[element];
			newText[lang] = event.target.value;
			setFood({ ...food, [element]: newText });
		} else {
			// const newFood = {
			// 	...food,
			// 	[element]: event.target.value
			// }
			// const kcals = reCalcKcals(newFood);
			setFood({
				...food,
				[element]: event.target.value,
				// kcals: kcals
			});
		}
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={(food.fullName && food.fullName[lang]) || ""}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
				/>

				<TextField
					id={`description`}
					label="Description"
					value={(food.description) || ""}
					onChange={handleChange("description", null)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					multiline
					required
				/>
			</>
		);
	};

	function reCalcKcals(food) {
		const newKcals = (food?.proteins||0) * 4 + 
						(food?.carbohydrates||0) * 4 + 
						(food?.fats||0) * 9 +
						(food?.ethanol||0) * 7;

		return newKcals;
	}

	function OnlyNumber(title, element, readOnly) {

		const change = (element) => (event) => {
			let newFood = {
				...food, 
				[element]: formattedValueNumber(event.target.value, true)
			};
			newFood.kcals = reCalcKcals(newFood);
			setFood(newFood);
		}

		return (
			<TextField
				id={`${element}`}
				label={`${title}`}
                onChange={change(element)}
				value={food[element]}
				required
                inputProps={{readOnly: readOnly}}
                className={`${readOnly ? 'readonly' : ''}`}
				margin="normal"
				variant="standard"
				type="number"
			/>
		)
	}

	function foodAutocomplete(title, element, options) {
		return (
			<MyAutocomplete
				options={options}
				getOptionLabel={(option) => option}
				value={food[element] || ""}
				onChange={(event, selected) => {
					setFood({
						...food,
						[element]: selected,
					});
				}}
				placeholder={`Select ${element}`}
				label={`${title}`}
			/>
		)
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
				<CardHeader title={food?._id?'Food: ' + (food.fullName?food.fullName.es:''):'New food'}>
					<div className="mt-5">
						<Button
							onClick={() => {
								if (changes > 0) setOpenConfirmDialog(1);
								else history.push("/sport-foods");
							}}
							variant="outlined"
							style={{ marginRight: "20px" }}
						>
							Back
						</Button>
						<Button
							onClick={() => saveFood()}
							variant="outlined"
							color="primary"
							style={{ marginRight: "20px" }}
						>
							Save food
						</Button>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								history.push("/sport-foods")
							}}
						/>
						{foodId && user?.role.includes("admin") && (
							<>
								<MuiThemeProvider theme={theme}>
									<Button
										onClick={() => setOpenConfirmDialog(2)}
										variant="outlined"
										color="secondary"
										style={{ marginRight: "20px" }}
									>
										Delete food
									</Button>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											marginLeft: "auto",
										}}
									></div>
								</MuiThemeProvider>

								<ConfirmDialog
									title={
										"Are you sure you want to delete this food?"
									}
									open={openConfirmDialog === 2}
									setOpen={setOpenConfirmDialog}
									onConfirm={() => {
										deleteSportFood(foodId)
											.then((res) => {
												if (
													res.status === 204 ||
													res.status === 200
												) {
													alertSuccess({
														title: "Deleted!",
														customMessage:
															"Food deleted successfully",
													});
													history.push("/sport-foods");
												}
											})
											.catch((error) => {
												alertError({
													error: error,
													customMessage:
														"Could not delete food.",
												});
											});
									}}
								/>
							</>
						)}
					</div>
				</CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>

						<Row>
							<Col sm={3}>
							{foodAutocomplete("Texture", "texture", textureOptions)}
							</Col>
							<Col sm={3}>
							{foodAutocomplete("Category", "category", categoryOptions)}
							</Col>
							<Col sm={3}>
							{foodAutocomplete("Flavour", "flavour", flavourOptions)}
							</Col>
							<Col sm={3}>
							<MyAutocomplete
								options={brandOptions}
								getOptionLabel={(option) => option.name}
								value={brandOptions.find((x) => x._id === food?.brand) || ""}
								onChange={(event, selected) => {
									setFood({
										...food,
										brand: selected._id,
									});
								}}
								placeholder={"Select Brand"}
								label={"Brand"}
							/>
							{/* {foodAutocomplete("Brand", "brand", brandOptions)} */}
							</Col>
						</Row>
						
						<Row>
						<Col>{OnlyNumber("Weight", "weight", false)}</Col>
						</Row>
						<br />
						<h4>For {food?.weight || 0} grams of {food.fullName.es}:</h4>
						<Row>
							<Col sm={3}>{OnlyNumber("KCAL", "kcals", true)}</Col>
							<Col sm={3}>{OnlyNumber("CHO", "carbohydrates", false)}</Col>
							<Col sm={3}>{OnlyNumber("PRO", "proteins", false)}</Col>
							<Col sm={3}>{OnlyNumber("FAT", "fats", false)}</Col>
						</Row>
						<Row>
							<Col sm={3}>{OnlyNumber("Water", "water", false)}</Col>
							<Col sm={3}>{OnlyNumber("Sodium", "sodium", false)}</Col>
							<Col sm={3}>{OnlyNumber("Caffeine", "caffeine", false)}</Col>
							<Col sm={3}>
								<Row>
								<Col>{OnlyNumber("GLU RATIO", "glu", false)}</Col>
								<Col>{OnlyNumber("FRU RATIO", "fru", false)}</Col>
								</Row>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</>
		);
}
