import React, { useEffect, useState } from "react";
import {
	Card,
} from "../../../../_metronic/_partials/controls";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { getCompetitions } from '../../../../api/competition'
import { getUserCompetitionsByUserId, postUserCompetitions, updateUserCompetitions } from "../../../../api/userCompetitions";
import FullCalendarView from "../../../components/userDiets/FullCalendarView"
import EditPatientDietDialog from "../../../components/dialogs/userDiets/EditPatientDietDialog";
import { getUserDietsById, getUserDietsByUserIdAndDateWeek, postUserDiets, updateUserDiets } from "../../../../api/userDiets";
import { postTramXUser, updateTramXUser } from "../../../../api/tramXuser";
import _ from 'lodash';
import EditStructureBlockDialog from "../../../components/dialogs/structures/EditStructureBlockDialog";
import { calcUnitsNt, reCalcNutritionalValues } from "../../../../utils/structuresCalcs";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Tooltip, TextField } from "@material-ui/core";
import { Col, Row, Button } from "react-bootstrap";
import { postFoodPlateStructure, updateFoodPlateStructure } from "../../../../api/foodPlateStructure";
import { formatFloat } from "../../../../utils/helpers";
import { getStructureNt } from "../../../../utils/dietCalcs";

export default function EditPatientDiets(props) {
	const { patient, userId, changes, setChanges, userCompetitions, setUserCompetitions} = props;

	const [userDiets, setUserDiets] = useState([]);
	const [weekDiet, setWeekDiet] = useState([]);
	const [week, setWeek] = useState(null);

	const [competitions, setCompetitions] = useState([]);
	const [tramsXuser, setTramsXuser] = useState([]);

    const [structure, setStructure] = useState(null);
    const [openEditBlockDialog, setOpenEditBlockDialog] = useState(false);
	const [selectedBlock, setSelectedBlock] = useState(null);
	const [isMeal, setIsMeal] = useState(false);
    const [blocks, setBlocks] = useState([]);
	const [userDiet, setUserDiet] = useState(null);

	const [selectedDiet, setSelectedDiet] = useState(null);
	const [date, setDate] = useState(null);
    const [openEditPatientDietDialog, setOpenEditPatientDietDialog] = useState(false);
	const [initialDate, setInitialDate] = useState(new Date().toISOString().split("T")[0]);
	const [selectedNtOption, setSelectedNtOption] = useState({_id: 3, value: "g"});
	const [info, setInfo] = useState(false);
	const [ntWeekInfo, setNtWeekInfo] = useState(null);
	const [cInfo, setCInfo] = useState([false, false, false, false, false, false, false]);
	const [tabNumber, setTabNumber] = useState(0);
	// const [wSelectedNtOption, setWSelectedNtOption] = useState({_id: 3, value: "g"});

	const [refresh, setRefresh] = useState(false);
	const [calendarRef, setCalendarRef] = useState(false);
	
	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		setCInfo([false, false, false, false, false, false, false])
	}, [initialDate])

	useEffect(() => {
		setCalendarRef(false);
	}, [calendarRef])

	useEffect(() => {
		if (competitions.length === 0)
			getCompetitions()
			.then((res) => {
				if (res.status === 200) {
					setCompetitions(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get competitions.",
				});
			});
		
		if (userCompetitions === null && userId)
			getUserCompetitionsByUserId(userId)
				.then((res) => {
					if (res.status === 200) {
						setUserCompetitions(res.data);
						disableLoadingData();
					}
				})
				.catch((error) => {
					if (error.request.status !== 404){
						alertError({
							error: error,
							customMessage: "Could not get user competitions.",
						});
					}
				});
	}, [userId, disableLoadingData])

	useEffect(() => {
		if (!openEditBlockDialog && !openEditPatientDietDialog) getDietWeek();
	}, [week, openEditBlockDialog, openEditPatientDietDialog])

	useEffect(() => {
		if (date?.dateStr) {
			const diet = userDiets?.find((x) => new Date(x.date).getDay() === date?.date.getDay());
			setSelectedDiet(diet? diet : {date: date.date});
			if (date?.numBlock >= 0) {
				const block = diet?.diet?.blocks?.find((x) => x.number === date?.numBlock)
				setSelectedBlock(block);
				setOpenEditBlockDialog(true);
				setIsMeal(block?.isIntake)
			} 
			else setOpenEditPatientDietDialog(true);

		}		
	}, [date])

	useEffect(() => {
		setRefresh(false);
	}, [refresh])

	function getDietWeek() {
		if (week) {
			getUserDietsByUserIdAndDateWeek(userId, week)
				.then((res) => {
					if (res.status === 200) {
						setUserDiets(res.data);
						getDietInfo(res.data);
					}
				})
				
		}
	}

	async function getDietInfo(diets) {
		let data = [];
		let totalEnergyReq = 0;
		let totalEnergyIntake = 0;
		let totalCHO = 0;
		let totalPRO = 0;
		let totalFAT = 0;
		let totalKCAL = 0;
		let totalExerciseExp = 0;
		let totalWeight = 0;

		for (let i = 0; i < diets.length; ++i) {
			const actD = diets[i];
			let elem = {};

			elem.date = actD?.date;
			elem.patientNotes = actD?.patientNotes;
			totalWeight += parseFloat(actD?.weight || 0);
			if (actD?.diet?.blocks?.length > 0) {
				const structure = await getStructureNt(actD?.diet, patient);
				totalEnergyReq += parseFloat(structure?.requeriments);
				totalEnergyIntake += structure?.kcalsIntake;
				totalCHO += structure?.carbohydrates;
				totalPRO += structure?.protein;
				totalFAT += structure?.fats;
				totalKCAL += structure?.kcal;
				totalExerciseExp += structure?.totalBurnedKcals;
				elem.diet = structure;
			}
			else elem.diet = actD.diet;

			data.push({...elem});
		}
		// console.log(data);
		setWeekDiet([...data]);
		// console.log(totalEnergyReq, totalEnergyReq/7)
		setNtWeekInfo({
			totalEnergyReq,
			averageEnergyReq: totalEnergyReq/7,
			totalEnergyIntake,
			averageEnergyIntake: totalEnergyIntake/7,
			// averageKCAL: totalKCAL/7,
			averageCHO: totalCHO/7,
			averagePRO: totalPRO/7,
			averageFAT: totalFAT/7,
			totalExerciseExp,
			averageWeight: totalWeight/7,
		})
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			//if (!food[element]) food[element] = {};
			//let newText = food[element];
			//newText[lang] = event.target.value;
			//setFood({ ...food, [element]: newText });
		} else {
			
		}
	};

	function dateFormat(date) {
		const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
		const dayOfWeek = daysOfWeek[date.getDay()];
		
		const day = date.getDate().toString().padStart(2, '0'); // Añade un cero delante si es necesario
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses empiezan en 0, por eso sumamos 1
		const year = date.getFullYear();
		
		return `${dayOfWeek} ${day}/${month}/${year}`;
	  }

	  async function saveRecipe(recipe, dietId) {
		  let savedRecipe = {
			  ...recipe,
			  structure: dietId,
			  typeStructure: "userDiet"
			}
			
		if (recipe?._id) {
			const res = await updateFoodPlateStructure(recipe?._id, savedRecipe)
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not update recipe."
					})
					return null;
				})
			if (res) return recipe?._id;
		}
		else {
			delete savedRecipe._id
			const res = await postFoodPlateStructure(savedRecipe)
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save recipe."
					})
					return null;
				})
				
			if (res) return res.data._id;
		}

		return null;
	}

	  async function updateSt(diet) {
		let structure = {...diet?.diet} || {};
		for (let i = 0; i < structure?.blocks?.length; ++i) {
			let actB = {...structure?.blocks[i]};
			if (actB?.isIntake) {
				if (actB?.intake?.recipes?.length > 0) {
					let recipes = actB?.intake?.recipes;
					for (let j = 0; j < recipes?.length; ++j) {
						let actR = {...actB?.intake?.recipes[j].recipe};
						actR.structure = structure?._id;
						const res = await saveRecipe(actR, diet?._id);
						if (!res) return false;
						actR = res;
						recipes[j].recipe = actR;
					}
					actB = {
						...actB, 
						intake: {
							...actB?.intake, 
							recipes: recipes 
						}
					}
					structure.blocks[i] = actB;
				}
			}
		}

		const res = await updateUserDiets(diet?._id, diet)
			.then((res) => {
				if (res.status === 200) {
					alertSuccess({
						title: "Patient Updated!",
						message: "Diets of the patient succesfully updated"
					})
					return true;
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not update user Diet.",
				});
				return false
			});
		if (res) return true;
		return false;
	}

	async function updateDiets(diet) {

		async function postDiet(diett) {
			const diettt = {
				...diett,
				userId: userId,
				date: date.date.toISOString()
			}
			const res = await postUserDiets(diettt)
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not post userDiets"
					})
					return null;
				})
			return res;
		}

		let newUserDiets = [...userDiets];
		if (!diet?.diet?.blocks) diet = {...diet, diet: {...diet?.diet, blocks: []}};

		if (!diet?._id) {
			const auxDiet = {...diet}
			const blocksDiet = diet?.diet?.blocks ? [...diet.diet.blocks] : [];
			if (auxDiet?.diet?.blocks) delete auxDiet.diet.blocks;
			const res = await postDiet(auxDiet);
			if (!res) return;
			diet._id = res.data._id;
			diet.diet.blocks = blocksDiet;
			newUserDiets.push(diet);
		}

		if (diet?._id) {
			const res = await updateSt(diet);
			if (res) {
				setUserDiet(null);
				setOpenEditPatientDietDialog(false);

				const getDiet = await getUserDietsById(diet._id)
					.catch((error) => {
						alertError({
							error: error,
							customMessage: "There was an error trying to fetch the user diet, please recharge the page"
						})
					});
				newUserDiets[newUserDiets.findIndex((x) => x._id === diet._id)] = getDiet.data || diet;
				setUserDiets(newUserDiets)
				setRefresh(true);
			}
		}
	}

	async function updateUserTrams(tramsXuser) {
		if (tramsXuser?.length > 0) {
			let newIds = [];
			for (let i = 0; i < tramsXuser?.length; ++i) {
				const tram = {
					...tramsXuser[i],
					date: date.date.toISOString()
				};

				if (tram?._id) {
					updateTramXUser(tram?._id, tram)
						.then((res) => {
							
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: `No se ha podido actualizar el tramo ${tram?.name}`
							})
						})
				}
				else {
					await postTramXUser(tram)
						.then((res) => {
							if (res.status === 201) {
								newIds.push({numTram: tram?.numTram, _id: res.data._id});									
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: `No se ha podido actualizar el tramo ${tram?.name}`
							})
						})
				}  
			}

			let newTrams = [...tramsXuser];
			for (let i = 0; i < newIds?.length; ++i) {
				const _id = newIds[i]?._id;
				const num = newIds[i]?.numTram;
				const index = newTrams?.findIndex((x) => x.numTram === num);
				newTrams[index]._id = _id;
			}
			setTramsXuser(newTrams);
		}
	}

	function updateCompetitions(competition) {
		if (userCompetitions?.competitions) {
			let userComps = [..._.cloneDeep(userCompetitions?.competitions)];
			const dt = date.date.toISOString();
			const index = userCompetitions?.competitions.findIndex((x) => {
				return x.date === dt
			})

			if (index === -1) {
				if (competition?.competitionId !== -1)userComps.push(competition);
			}
			else {
				userComps[index] = competition;
				if (competition?.competitionId === -1) userComps.splice(index, 1);
			}
			
			const newUserCompetitions = {...userCompetitions, competitions: userComps};
			updateUserCompetitions(userCompetitions._id, newUserCompetitions)
				.then((res) => {
					if (res.status === 200) {
						setUserCompetitions(newUserCompetitions)
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "There was an error updating user Competitions"
					})
				}) 
		} else {
			const compA = [{...competition}]
			const newUserCompetitions = {
				userId: userId,
				competitions: compA,
				active: true
			}
			postUserCompetitions(newUserCompetitions)
				.then((res) => {
					setUserCompetitions({...newUserCompetitions, _id: res.data._id})
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not update user competitions"
					})
				})
		}
		
	}

	function saveDiet(structure) {
		setUserDiet({
			...userDiet,
			diet: {
				kcal: structure.kcal,
				carbohydrates: structure.carbohydrates,
				protein: structure.protein,
				fats: structure.fats,
				fatskg: structure.fatskg,
				fatspercent: structure.fatspercent,
				blocks: structure.blocks
			},
		});
	}

	async function actualizeStructure(block) {
		const index = userDiet?.diet?.blocks?.findIndex((x) => x.number === selectedBlock?.number)
		let newBlocks = [...userDiet?.diet?.blocks];
		newBlocks[index] = block;
		const newStructure = {
			...userDiet,
			diet: { blocks: newBlocks }
		}

		const res = await updateSt(newStructure);
		if (res) {
			setUserDiet({...newStructure});
			setBlocks(newStructure?.blocks);
			setStructure(newStructure?.diet);
			setRefresh(true);
		}
		return res;
	}

	function OpenInformation({title, arrowTitle}) {
        const arrowStyle = {color: '#000'}

		function text(title, value) {
			return (
				<Col>
				<TextField
					id={`${title}`}
					label={`${title}`}
					value={value}
					InputLabelProps={{
					shrink: true
					}}
					margin="normal"
					variant="standard"
					required
					type="number"
					className='readonly'
				/>
				</Col>
			)
		}

		const w = patient?.selectedWeight || 1;
		const val = selectedNtOption.value;
		const kcals = parseFloat(ntWeekInfo?.totalEnergyIntake || 0)/7;
		const cho = formatFloat(calcUnitsNt(ntWeekInfo?.averageCHO, 
			val, "carbohydrates", kcals, w));
		const pro = formatFloat(calcUnitsNt(ntWeekInfo?.averagePRO,
			val, "proteins", kcals, w));
		const fat = formatFloat(calcUnitsNt(ntWeekInfo?.averageFAT,
			val, "fats", kcals, w));
			// console.log(kcals)

        return (
            <div style={{width: '98%', margin: '0 auto'}}>
                    <Row style={{backgroundColor: '#E1F2CE'}}>
                        <Col sm={11} style={{marginTop: 10}}>
                            <h5 style={{fontWeight: 'bold'}}>{title.toUpperCase()}</h5>
                        </Col>
                        <Col sm={1}>
                            <Tooltip title={arrowTitle}>
                            <Button
								size="small"
                                style={{backgroundColor: 'transparent', borderColor: 'transparent', marginLeft: 40}}
								onClick={() => setInfo(!info)}
							>
                                {info? (<ExpandLess style={arrowStyle}/>) : 
                                (<ExpandMore style={arrowStyle}/>)}
							</Button>
                            </Tooltip>
                        </Col>
                    </Row>
				{info && (
					<>
					<Row>
					{text("Total energy req (kcal)", formatFloat(ntWeekInfo?.totalEnergyReq || 0))}
					{text("Avg energy req (kcal/day)", formatFloat(ntWeekInfo?.averageEnergyReq || 0))}
					{text("Total energy intake (kcal)", formatFloat(ntWeekInfo?.totalEnergyIntake || 0))}
					{text("Avg energy intake (kcal/day)", formatFloat(ntWeekInfo?.averageEnergyIntake || 0))}
					</Row>
					<Row>
					{text("Total EEE (kcal)", formatFloat(ntWeekInfo?.totalExerciseExp) || 0)}
					{text(`Avg CHO intake (${val})`, cho)}
					{text(`Avg PRO intake (${val})`, pro)}
					{text(`Avg FAT intake (${val})`, fat)}
					</Row>
					<Row>
					{text("Avg weight (kg)", formatFloat(ntWeekInfo?.averageWeight || 0))}
					</Row>
					</>
				)}
				<br />
            </div>
        )
    }

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>

					<OpenInformation title="Weekly summary" arrowTitle="Open information" />
					
					{!calendarRef && 
					<FullCalendarView
						setCalendarRef={setCalendarRef}
                        userId={userId}
						setDate={setDate}
						setWeek={setWeek}
						setSelectedBlock={setSelectedBlock}
						blocks={blocks}
						patient={patient}
						userDiets={userDiets}
						refr={refresh}
						date={date}
						setInitialDate={setInitialDate}
						initialDate={initialDate}
						setSelectedNtOption={setSelectedNtOption}
						selectedNtOption={selectedNtOption}
						weekDiet={weekDiet}
						info={cInfo}
						setInfo={setCInfo}
						setTabNumber={setTabNumber}
                    />
					}
                    
                    <EditPatientDietDialog
                        open={openEditPatientDietDialog}
                        setOpen={setOpenEditPatientDietDialog}
                        data={selectedDiet}
                        title={date?.date? dateFormat(date.date) : ""}
                        competitions={competitions}
                        userId={userId}
                        userCompetitions={userCompetitions}
                        setUserCompetitions={setUserCompetitions}
                        tramsXuser={tramsXuser}
                        setTramsXuser={setTramsXuser}
                        changes={changes}
                        setChanges={setChanges}
						date={date}
						saveDiet={saveDiet}
						updateSt={updateSt}
						patient={patient}
						tabNumber={tabNumber}

						//SructureBlockDialog
						openEditBlockDialog={openEditBlockDialog}
						setOpenEditBlockDialog={setOpenEditBlockDialog}
						selectedBlock={selectedBlock}
						setSelectedBlock={setSelectedBlock}
						isMeal={isMeal}
						setIsMeal={setIsMeal}
						blocks={blocks}
						setBlocks={setBlocks}
						userDiet={userDiet}
						setUserDiet={setUserDiet}
						structure={structure}
						setStructure={setStructure}

						onSave={async (diet, tramsXuser, competition) => {
							updateDiets(diet);
							updateUserTrams(tramsXuser);
							if (competition?.competitionId) updateCompetitions(competition);
						}}
                    />

					<EditStructureBlockDialog
						open={openEditBlockDialog}
						setOpen={setOpenEditBlockDialog}
						data={selectedBlock}
						meal={isMeal}
						typeStructure={"userDiet"}
						structureId={userDiet?._id}
						actualizeStructure={actualizeStructure}
						patient={patient}
						
						onSave={(block) => {
							let newBlocks = (blocks?.length > 0)? [...blocks] : [];

							const index = newBlocks.findIndex(
								(x) => x.number === block.number
							);
							
							if (index !== -1) {
								newBlocks[index] = {
									...block,
								};
							} else {
								newBlocks.push({
									...block,
									number: newBlocks.length
								});
							}

							const data = reCalcNutritionalValues(newBlocks);
							const newStructure = {
								...structure,
								kcal: data.kcals,
								carbohydrates: data.carbohydrates,
								protein: data.protein,
								fats: data.fats,
								fatskg: data.fatskg,
								fatspercent: data.fatspercent,
								blocks: newBlocks,
							}

							saveDiet(newStructure)
							setStructure(newStructure);
							setBlocks(newBlocks);
							if (!openEditPatientDietDialog) {
								const newDiet = {
									...selectedDiet,
									diet: newStructure
								}
								updateDiets(newDiet);
							}
							setRefresh(true);
						}}
					/>

                </Card>
			</>
		);
}
