import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import {
	changeStatusFoodPlate,
	deleteFoodPlate,
	getFoodPlates,
} from "../../../../api/foodPlate";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
	substringFormatter,
} from "../../../components/tables/table";
import { recipeCalcs } from "../../../../utils/structuresCalcs";
import { useSkeleton } from "../../../hooks/useSkeleton";

export default function FoodPlatesPage() {
	const [data, setData] = useState([]);
	const [foodPlateId, setFoodPlateId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [previewImage, setPreviewImage] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const [tableFoodPlates, setTableFoodPlates] = useState([]);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

    const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	function getData(foodPlates) {
		let data = [];
		for (let i = 0; i < foodPlates.length; ++i) {
			const elem = {};
			const actR = foodPlates[i];
			elem.description = getNonEmpty(actR.description);
			elem.imageURL = actR.imageURL;
			elem.createdAt = actR.createdAt;
			elem.id = actR._id;
			elem.active = actR.active;
			data = data.concat(elem);
		}
		return data;
	}

	async function getCalculatedData(foodPlates) {
		let data = [];

		for (let i = 0; i < foodPlates.length; ++i) {
			const elem = {};
			const actR = foodPlates[i];
			elem.description = getNonEmpty(actR.description);
			elem.imageURL = actR.imageURL;
			elem.createdAt = actR.createdAt;
			elem.id = actR._id;
			elem.active = actR.active;
			
			const calcs = await recipeCalcs(actR);
			elem.kcals = calcs?.kcals;
			elem.carbohydrates = calcs?.carbohydrates;
			elem.proteins = calcs?.proteins;
			elem.fiber = calcs?.fiber;
			elem.fats = calcs?.fats;

			data.push(elem);
		}

		setTableFoodPlates(data);
		disableLoadingData();
	}

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-food-plate/" + cell)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setFoodPlateId(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "#00F" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setFoodPlateId(cell);
									setOpenConfirmDialog(2);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const numbersHeaderStlye = { width: '80px' };

	const columns = [
		{
			dataField: "description",
			text: "Title",
			sort: true,
			// headerStyle: {width: '450px'}
		},
		{
			dataField: "kcals",
			text: "KCAL",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStlye,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "carbohydrates",
			text: "CHO",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStlye,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "proteins",
			text: "PRO",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStlye,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "fats",
			text: "fat",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStlye,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "fiber",
			text: "FIBER",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStlye,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{ 
			dataField: "id", 
			text: "", 
			headerStyle: {width: '150px'},
			formatter: buttonFormatter 
		},
	];

	useEffect(() => {
		getFoodPlates()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					getCalculatedData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get food plates.",
				});
			});
	}, [refresh]);

	if (isLoadingData) return (<ContentSkeleton />)
	return (
		<>
			<Card>
				<CardHeader title="Food plates list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push("/edit-food-plate")}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={tableFoodPlates} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${
							foodPlateId?.active ? "disable" : "enable"
						} this food plate?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusFoodPlate(
								foodPlateId.id,
								!foodPlateId?.active
							)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												foodPlateId?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Food plate ${
												foodPlateId?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											foodPlateId?.active
												? "disable"
												: "enable"
										} food plate.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={
							"Are you sure you want to remove this food plate?"
						}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteFoodPlate(foodPlateId)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Food plate removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not remove food plate.",
									});
								});
						}}
					/>
					<PreviewDialog
						title={"Preview image"}
						open={openPreviewDialog}
						setOpen={setOpenPreviewDialog}
						src={
							previewImage
								? `${SERVER_URL}/${previewImage}`
								: null
						}
					/>
				</CardBody>
			</Card>
		</>
	);
}
