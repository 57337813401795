import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alertError } from "../../../../utils/logger";
import { Col, Row } from "react-bootstrap";
import { formattedValueNumber } from "../../../../utils/numbers";
import OnlyTextDate from "../../textFields/OnlyTextDate";

function getEmptyWeight(patientId) {
    const today = new Date();
    const date = new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().split('.')[0];
    return {
        userId: patientId,
        date: date,
        maxAbdominalGrith: null,
        minAbdominalGrith: null,
        active: true
    }
}

export default function EditAbdominalWeight(props)  {
    const { open, setOpen, data, onSave, patientId } = props;
    const [abdominalWeight, setAbdominalWeight] = useState(null);

    useEffect(() => {
        if (data) {
			const d = new Date(data?.date);
			const date = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().split('.')[0];
			setAbdominalWeight({...data, date: date});
		}
        else setAbdominalWeight(getEmptyWeight(patientId))
    }, [data, open])

    const handleChange = (element) => (event) => {
        const { value } = event.target;
        const formattedValue = formattedValueNumber(value, true);
        setAbdominalWeight({...abdominalWeight, [element]: formattedValue});
    }

    function close() {
        setOpen(false);
        setAbdominalWeight(null);
    }

    return (
        <Dialog disableBackdropClick={true} 
            fullWidth={true}
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="lg"
            aria-labelledby="table-dialog"
        >
            <DialogTitle id="table-dialog">{"Abdominal grith"}</DialogTitle>
			<DialogContent>
                <Row>
                    <Col>
                    <OnlyTextDate title={"Date"} element={"date"} handleChange={handleChange} data={abdominalWeight} specific={false}/>
                    </Col>
                    <Col>
						<TextField
							id={"maxAbdominalGrith"}
							label={"Max abdominal grith (cm)"}
							value={(abdominalWeight?.maxAbdominalGrith)}
							onChange={handleChange('maxAbdominalGrith')}
							InputLabelProps={{shrink: true}}
							margin="normal"
							variant="standard"
							type="number"
							InputProps={{
								inputProps: {
									min: 0
								}
							}}
						/>
                    </Col>
                    <Col>
						<TextField
							id={"minAbdominalGrith"}
							label={"Min abdominal grith (cm)"}
							value={(abdominalWeight?.minAbdominalGrith)}
							onChange={handleChange('minAbdominalGrith')}
							InputLabelProps={{shrink: true}}
							margin="normal"
							variant="standard"
							type="number"
							InputProps={{
								inputProps: {
									min: 0
								}
							}}
						/>
                    </Col>
                </Row>
				<br /> <br /> <br /> <br /> <br /><br />
				<br /> <br /> <br /> <br /> <br /><br />
            </DialogContent>
            <DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						close();
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={async () => {
                        if (!abdominalWeight?.maxAbdominalGrith || 
                            !abdominalWeight?.minAbdominalGrith) 
                            alertError({
                                error: null,
                                customMessage: "All fields are required."
                            })
                        else {
                            const res = await onSave(abdominalWeight)
                            if (res) close();
                            
                        }
					}}
				>
					Save
				</Button>
			</DialogActions>
        </Dialog>
    )
}