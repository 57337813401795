import { API, authClient } from "../index";

export const getUserStructures = () => {
	return authClient().get(`${API}/user-structures?getDisabled=true`);
};

export const getUserStructuresById = (id) => {
	return authClient().get(`${API}/user-structures/${id}`);
};

export const getUserStructuresByUserId = (userId) => {
	return authClient().get(`${API}/user-structures/user/${userId}`);
};

export const getActiveUserStructuresByUserId = (userId) => {
	return authClient().get(`${API}/user-structures/user/${userId}/active`);
};

export const getUserStructuresByDate = (date) => {
	return authClient().get(`${API}/user-structures/date/${date}`);
};

export const getUserStructuresByUserIdAndDate = (userId, date) => {
	return authClient().get(`${API}/user-structures/user/${userId}/date/${date}`);
};

export const getUserStructuresByUserIdAndDateWeek = (userId, date) => {
	return authClient().get(`${API}/user-structures/user/${userId}/date/${date}/week`);
};

export const deleteUserStructures = (id) => {
	return authClient().delete(`${API}/user-structures/${id}`);
};

export const postUserStructures = async (userStructures) => {
	return authClient().post(`${API}/user-structures`, userStructures);
};

export const updateUserStructures = async (id, userStructures) => {
	return authClient().put(`${API}/user-structures/${id}`, userStructures);
};

export const changeStatusUserStructures = async (id, active) => {
	return authClient().put(`${API}/user-structures/change-status/${id}`, {
		active,
	});
};