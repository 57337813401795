import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alertError } from "../../../../utils/logger";
import { Col, Row } from "react-bootstrap";
import MyAutocomplete from "../../MyAutocomplete";
import { activityCalcs, intensityTypes, runCalcs, zValues } from "../../../../utils/structuresCalcs";
import { formattedValueNumber } from "../../../../utils/numbers";
import { formatFloat } from "../../../../utils/helpers";
import ConfirmDialog from "../ConfirmDialog";

function getEmptyIntensity() {
	return {
		kcals: 0,
        speed: 0,
        time: 0,
        power: 0,
        zValue: null,
        intensityType: ""
	};
}

const EditBlockIntensityDialog = (props) => {
	const { title, data, open, setOpen, onSave, type, activity, activities, patient } = props;

	const [intensity, setIntensity] = useState(getEmptyIntensity());
    const [completeActivity, setCompleteActivity] = useState(null);
    const [firstIntensity, setFirstIntensity] = useState(null);
	const [refresh, setRefresh] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
    const [blur, setBlur] = useState(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		setBlur(false);
	}, [blur]);

	useEffect(() => {
		if (!open || !data) setIntensity(getEmptyIntensity());
		else {
            const completeActivity = activities.find((x) => x._id === activity._id);
            setCompleteActivity(completeActivity);
            const intensity = data;
            setFirstIntensity(intensity);
            // setIntensity(intensity);
            const calcs = activityCalcs(activity, intensity, patient);
            setIntensity({
                ...intensity,
                kcals: calcs.kcals
            });
        }
	}, [data, open]);

    function refreshCalcs(intensity) {
        let newIntensity = {...intensity};
        if (activity?.typeOfActivity === 'Running') {
            const runCalc = runCalcs(intensity);
            if (runCalc?.speed) {
                const pace = runCalc.pace.toString().split(".");
                newIntensity = {
                    ...newIntensity,
                    speed: formattedValueNumber(runCalc.speed, true)
                }
                if (intensity?.calculatedValue === 3)
                    newIntensity.time = formattedValueNumber(runCalc.time, true);
                else if (intensity?.calculatedValue === 2)
                    newIntensity.distance = formattedValueNumber(runCalc.dist, true);
                else if (intensity?.calculatedValue === 1) {
                    newIntensity.paceMinutes = formattedValueNumber(pace[0], true);
                    newIntensity.paceSeconds = formattedValueNumber(pace[1]?.substring(0, 2) || 0, true);
                }
            }
            
        }
        console.log(newIntensity)
        const calcs = activityCalcs(activity, newIntensity, patient);
        setIntensity({
            ...newIntensity,
            kcals: calcs.kcals
        });
        setRefresh(true);
    }

	const handleChange = (element) => (event) => {
		const { value } = event.target;
		let formattedValue = formattedValueNumber(value, true);
		if (formattedValue === " ") return;
        
        let newIntensity = {...intensity}
        newIntensity[element] = formattedValue;
        
        if (activity?.typeOfActivity === "Running" && !newIntensity?.calculatedValue) {
            const pace = parseFloat(newIntensity?.paceMinutes || 0) + parseFloat(newIntensity?.paceSeconds || 0);
            const dist = parseFloat(newIntensity?.distance);
            const time = parseFloat(newIntensity?.time);
            if (pace) {
                if (dist) newIntensity.calculatedValue = 3;
                else if (time) newIntensity.calculatedValue = 2;
            }
            else if (dist && time) newIntensity.calculatedValue = 1;
        } 

        refreshCalcs({...newIntensity});
        setRefresh(true);
	};

    // useEffect(() => {
    //     console.log(intensity)   
    // }, [intensity])

	return (
		<Dialog disableBackdropClick={true}
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>

            <Row>
                {type === "Running" ? (
                    <>
                    <Col>
                        {!blur && 
                        <Row>
                            <Col>
                            <TextField
                                id={`pace`}
                                label="Pace (mm)"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                    }
                                }}
                                onChange={handleChange('paceMinutes')}
                                value={(intensity?.paceMinutes || 0)}
                                margin="normal"
                                className={`${intensity?.calculatedValue === 1 ? "readonly" : ""}`}
                                onFocus={() => {
                                    if (intensity?.calculatedValue === 1) {
                                        setOpenConfirmDialog(1);
                                        setBlur(true);
                                    }
                                }}
                                variant="standard"
                                type="number"
                            />
                            </Col>
                            <Col>
                            <TextField
                                id={`pace`}
                                label="Pace (ss)"
                                InputProps={{
                                    inputProps: {
                                        min: 0
                                    }
                                }}
                                onChange={handleChange('paceSeconds')}
                                value={(intensity?.paceSeconds || 0)}
                                margin="normal"
                                className={`${intensity?.calculatedValue === 1 ? "readonly" : ""}`}
                                onFocus={() => {
                                    if (intensity?.calculatedValue === 1) {
                                        setOpenConfirmDialog(1);
                                        setBlur(true);
                                    }
                                }}
                                variant="standard"
                                type="number"
                            />
                            </Col>
                        </Row>
                        }
                    </Col>
                    <Col>
                    { !blur && 
                        <TextField
                            id={`distance`}
                            label="Distance (km)"
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            onChange={handleChange('distance')}
                            value={(intensity?.distance || 0)}
                            onFocus={() => {
                                if (intensity?.calculatedValue === 2) 
                                    {
                                        setBlur(true);
                                        setOpenConfirmDialog(1);
                                    }
                            }}
                            margin="normal"
                                className={`${intensity?.calculatedValue === 2 ? "readonly" : ""}`}
                            variant="standard"
                            type="number"
                        />
                    }
                    </Col>
                    {/* <Col>
                        <TextField
                            id={`speed`}
                            label="Speed (km/h)"
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            onChange={handleChange('speed')}
                            value={intensity?.speed || 0}
                            margin="normal"
                            variant="standard"
                            type="number"
                        />
                    </Col> */}
                    </>
                ) : type === "Trailrunning" ? (
                    <>
                    <Col>
                    <TextField
                        id={`distance`}
                        label="Distance (km)"
                        InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }}
                        onChange={handleChange('distance')}
                        value={intensity?.distance || 0}
                        margin="normal"
                        variant="standard"
                        type="number"
                    />
                    </Col>
                    <Col>
                    <TextField
                        id={`ascent`}
                        label="Ascent (m)"
                        InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }}
                        onChange={handleChange('ascent')}
                        value={intensity?.ascent || 0}
                        margin="normal"
                        variant="standard"
                        type="number"
                    />
                    </Col>
                    <Col>
                    <TextField
                        id={`descent`}
                        label="Descent (m)"
                        InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }}
                        onChange={handleChange('descent')}
                        value={intensity?.descent || 0}
                        margin="normal"
                        variant="standard"
                        type="number"
                    />
                    </Col>
                    </>
                ) : type !== "Cycling" && (
                    <Col>
                    <MyAutocomplete
                        options={ intensityTypes }
                        getOptionLabel={(option) =>
                            option
                        }
                        value={ intensity?.intensityType || null }
                        onChange={(event, selected) => {
                            const newIntensity = {...intensity, intensityType: selected};
                            refreshCalcs(newIntensity);
                            setRefresh(true)
                        }}
                        placeholder="Select level"
                        label={"Level"}
                    />
                    </Col>
                )}
                <Col>
                    {!blur &&
                    <TextField
                        id={`time`}
                        label="Duration (min)"
                        InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }}
                        onChange={handleChange('time')}
                        value={(intensity?.time || 0)}
                        margin="normal"
                        className={`${intensity?.calculatedValue === 3 ? "readonly" : ""}`} //Solo para running
                        onFocus={() => {
                            if (intensity?.calculatedValue === 3) {
                                setOpenConfirmDialog(1);
                                setBlur(true);
                            }
                        }}
                        variant="standard"
                        type="number"
                    />
                    }
                </Col>
                {type === "Cycling" &&
                <Col>
                    <TextField
                        id={`power`}
                        label="Power (W)"
                        InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }}
                        onChange={handleChange('power')}
                        value={intensity?.power || 0}
                        margin="normal"
                        variant="standard"
                        type="number"
                    />
                </Col>
                }
                <Col>
                    <MyAutocomplete
                        options={ zValues }
                        getOptionLabel={(option) =>
                            option.zValue
                        }
                        value={ zValues.find((x) => x.zValue === intensity?.zValue) }
                        onChange={(event, selected) => {
                            const newIntensity = {...intensity, zValue: selected.zValue};
                            refreshCalcs(newIntensity);
                            setRefresh(true)
                        }}
                        placeholder="Select z value"
                        label={"Level Z"}
                    />
                </Col>
            </Row>

            <Row>
                <Col sm={4}>
                    <TextField
                        id={`kcals`}
                        label="KCAL"
                        inputProps={{ readOnly: true }}
                        value={formatFloat(intensity?.kcals || 0)}
                        margin="normal"
                        variant="standard"
                        type="number"
                        className='readonly'
                    />
                </Col>
            </Row>

            <br /><br /><br /><br /><br /><br /><br /><br />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setIntensity(getEmptyIntensity());
                        setCompleteActivity(null);
                        setFirstIntensity(null);
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (!(intensity?.intensityType || intensity?.power || intensity?.speed)) {
							alertError({
								error: null,
								customMessage:
									"The intensity is required",
							});
                        } else if (!intensity?.time && intensity?.time !== 0) {
                            alertError({
								error: null,
								customMessage:
									"The time is required",
							});
                        } else if (!intensity?.zValue) {
                            alertError({
								error: null,
								customMessage:
									"The z value is required",
							});
                        } else {
							setOpen(false);
							onSave(intensity, firstIntensity);
                            setFirstIntensity(null)
                            setCompleteActivity(null)
							setIntensity(getEmptyIntensity());
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
			<ConfirmDialog
				title={
					"You are trying to modificate a value that is being calculated, so the values are going to restart. Are you sure about it?"
				}
				open={openConfirmDialog === 1}
				setOpen={setOpenConfirmDialog}
				onConfirm={() => {
                    refreshCalcs({...intensity, calculatedValue: 0, 
                        time: 0, distance: 0, 
                        paceMinutes: 0, paceSeconds: 0});
				}}
			/>
		</Dialog>
	);
};


export default EditBlockIntensityDialog;