import { authClient, API } from '../index'

// Count all documents tags
export const countDocumentsTags = () => {
  return authClient().get(`${API}/documents-tag/count`)
}

// Get all documents tags
export const getDocumentsTags = () => {
  return authClient().get(`${API}/documents-tag`)
}

// Get documents tag by id
export const getDocumentsTagById = (id) => {
  return authClient().get(`${API}/documents-tag/${id}`)
}

// Update documents tag
export const updateDocumentsTag = (id, docTag) => {
  return authClient().put(`${API}/documents-tag/${id}`, docTag)
}

// Create documents tag
export const postDocumentsTag = (docTag) => {
  return authClient().post(`${API}/documents-tag`, docTag)
}

// Delete documents tag
export const deleteDocumentsTag = (id) => {
  return authClient().delete(`${API}/documents-tag/${id}`)
}

export const changeStatusDocumentsTag = (id) => {
  return authClient().put(`${API}/documents-tag/change-status/${id}`);
}