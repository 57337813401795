import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alertError } from "../../../utils/logger";
import MultilanguageTabBlock from "../MultilanguageTabBlock";
import { Col, Row } from "react-bootstrap";
import { Autocomplete } from "@material-ui/lab";
import MyAutocomplete from "../MyAutocomplete";
import { formattedValueNumber } from "../../../utils/numbers";
import { formatFloat } from "../../../utils/helpers";

function getEmptyFood() {
	return {
		_id: null,
		fullName: null,
		grams: null,
		kcals: null,
		proteins: null,
		carbohydrates: null,
		fats: null,
		fiber: null
	};
}

const EditFoodDialog = (props) => {
	const { title, data, open, setOpen, onSave, readOnly, foods, selectedFoods } = props;

	const [food, setFood] = useState(getEmptyFood());
	const [firstFood, setFirstFood] = useState(getEmptyFood());
	const [refresh, setRefresh] = useState(false);
	const [foodOptions, setFoodOptions] = useState(foods);
	const [portionOptions, setPortionOptions] = useState([]);
	const [selectedPortion, setSelectedPortion] = useState({_id: -1});

	useEffect(() => {
		if (!open || !data) setFood(getEmptyFood());
		else {
			recalcNutritionalValues({...data, selectedPortion: {_id: data?.selectedPortion}});
			setFirstFood(data);
			actualizePortionOptions(data);
			setSelectedPortion({_id: data?.selectedPortion});
		}
	}, [data, open]);

	useEffect(() => {
		if (selectedFoods?.length > 0) {
			if (JSON.stringify(getEmptyFood()) !== JSON.stringify(food)) {
				let data = [];
				const idFoodAct = firstFood._id;
				
				for (let i = 0; i < foods?.length; ++i) {
					if (idFoodAct === foods[i]._id) {
						data.push(foods[i]);
					}
					else {
						const number = selectedFoods.findIndex((x) => x.foodId === foods[i]._id)
						if (number === -1) data.push(foods[i]);
					}
				}
				setFoodOptions(data);
			}
		}
	}, [selectedFoods, food])

    useEffect(() => {
        setRefresh(false);
    }, [refresh]);

	useEffect(() => {
		if (selectedPortion?._id !== -1 && food?.portions?.length > 0) {
			const portionComplete = food?.portions?.find((x) => x._id === selectedPortion?._id);
			recalcNutritionalValues({...food, grams: portionComplete?.grams * food?.portionQuant});
		}
	}, [selectedPortion, food?.portionQuant, food?.grams])

	const handleChange = (element, lang, isNumber) => (event) => {
		const { value } = event.target;

		if (element === 'grams' && (selectedPortion?._id >= 0) || 
			(element === 'portionQuant' && selectedPortion._id === -1)) return;
		let formattedValue = formattedValueNumber(value, isNumber);
		if (formattedValue === " ") return;
		else if (element === 'grams') {
			recalcNutritionalValues({ ...food, [element]: formattedValue });
		}
		else if (element === "name" || !lang) setFood({ ...food, [element]: formattedValue });
		else {
			if (!food[element]) food[element] = {};
			let newText = food[element];
			newText = formattedValue;
			setFood({ ...food, [element]: newText });
		} 
	};

	function foodCalc(food) {
		const grams = parseFloat(food?.grams) || 0; 
	
		return {
			kcals: food?.kcals*grams/100,
			proteins: food?.proteins*grams/100,
			carbohydrates: food?.carbohydrates*grams/100,
			fats: food?.fats*grams/100,
			fiber: food?.fiber*grams/100
		}
	}

	function recalcNutritionalValues(food) {
		const calcs = foodCalc(food);
		const newFood = {
			...food, 
			kcalsCalc: calcs.kcals,
			proteinsCalc: calcs.proteins,
			carbohydratesCalc: calcs.carbohydrates,
			fatsCalc: calcs.fats,
			fiberCalc: calcs.fiber,
		};
		console.log(newFood)
		setFood(newFood);
	}

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
			</>
		);
	};

	function actualizePortionOptions(food) {
		let data = [];
		
		data.push({
			_id: -1,
			name: "No portion"
		});
		if (food?.portions?.length > 0) {
			const portions = food.portions;
			for (let i = 0; i < portions.length; ++i) {
				let elem = {};
				const act = portions[i];
				
				elem._id = i;
				elem.name = act?.name;
				
				data.push(elem);
			}
		}

		setPortionOptions(data);
	}

	function close() {
		setOpen(false);
		setFood(getEmptyFood());
		setSelectedPortion({_id: -1});
		setPortionOptions([]);
	}

	return (
		<Dialog disableBackdropClick={true} 
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				<MultilanguageTabBlock
					multilanguageTabContent={renderMultilanguageTabContent}
				/>
                <Row>
                    <Col>
						<MyAutocomplete
							options={ foodOptions }
							getOptionLabel={(option) => option.fullName.es}
							value={
								foods?.find(
									(x) => x._id === food._id
								) || null
							}
							onChange={(event, selected) => {
								setFood({
									...food,
									_id: selected?._id,
									fullName: selected?.fullName.es,
									kcals: selected?.kcals,
									proteins: selected?.proteins,
									carbohydrates: selected?.carbohydrates,
									fats: selected?.fats,
									fiber: selected?.fiber,
									portionConsumed: selected?.portionConsumed,
									portions: selected?.portions || [],
									selectedPortion: -1,
									portionQuant: 0
								});
								setSelectedPortion({_id: -1});
								actualizePortionOptions(selected || []);
								setRefresh(true);
							}}
							placeholder="Select food"
							label={"Food"}
						/>
                    </Col>
                </Row>
				<Row>
					<Col sm={8}>
						<MyAutocomplete
							options={ portionOptions }
							getOptionLabel={(option) => option.name}
							value={
								portionOptions?.find(
									(x) => x._id === selectedPortion?._id
								) || null
							}
							onChange={(event, selected) => {
								setSelectedPortion(selected)
								setRefresh(true);
							}}
							placeholder="Select portion"
							label={"Portion"}
						/>
					</Col>
					<Col sm={4}>
						<TextField
							id={"portionQuant"}
							label={"Quantity"}
							value={(food?.portionQuant)}
							onChange={handleChange('portionQuant', false, true)}
							InputLabelProps={{shrink: true}}
							margin="normal"
							variant="standard"
							type="number"
							inputProps={{readOnly: selectedPortion._id === -1}}
							className={`${selectedPortion._id === -1 ? 'readonly' : ''}`}
							InputProps={{
								inputProps: {
									min: 0
								}
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col >
						<TextField
							id={"grams"}
							label={"Grams"}
							value={(food?.grams)}
							onChange={handleChange('grams', false, true)}
							InputLabelProps={{shrink: true}}
							inputProps={{ readOnly: (selectedPortion?._id >= 0)? true : false}}
							margin="normal"
							variant="standard"
							className={(selectedPortion?._id >= 0)? 'readonly' : ''}
							type="number"
							InputProps={{
								inputProps: {
									min: 0
								}
							}}
							disabled={readOnly}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<TextField
							id={`kcals`}
							label="KCAL"
							inputProps={{ readOnly: true }}
							value={formatFloat(food?.kcalsCalc)}
							margin="normal"
							variant="standard"
							type="number"
							className='readonly'
						/>
					</Col>
					<Col>
						<TextField
							id={`carbohydrates`}
							label="CHO"
							inputProps={{ readOnly: true }}
							value={formatFloat(food?.carbohydratesCalc)}
							margin="normal"
							variant="standard"
							type="number"
							className='readonly'
						/>
					</Col>
					<Col>
						<TextField
							id={`proteins`}
							label="PRO"
							inputProps={{ readOnly: true }}
							value={formatFloat(food?.proteinsCalc)}
							margin="normal"
							variant="standard"
							type="number"
							className='readonly'
						/>
					</Col>
					<Col>
						<TextField
							id={`fats`}
							label="FAT"
							inputProps={{ readOnly: true }}
							value={formatFloat(food?.fatsCalc)}
							margin="normal"
							variant="standard"
							type="number"
							className='readonly'
						/>
					</Col>
					<Col>
						<TextField
							id={`fiber`}
							label="FIBER"
							inputProps={{ readOnly: true }}
							value={formatFloat(food?.fiberCalc)}
							margin="normal"
							variant="standard"
							type="number"
							className='readonly'
						/>
					</Col>
				</Row>
				<br /><br /><br /><br /><br /><br />
				<br />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						close();
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (!food?.fullName) {
							alertError({
								error: null,
								customMessage:
									"The title is required in at least one of the languages",
							});
						} else {
							const sp = {...selectedPortion};
							const newFood = {
								...food,
								grams: parseInt(food.grams),
								selectedPortion: sp?._id,
								portionQuant: parseInt(food?.portionQuant),
								grams: food?.grams ? food.grams : 0
							}
							
							onSave(newFood);
							close()
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default EditFoodDialog;