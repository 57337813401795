import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import {
	deleteCompetition,
	getCompetitionById,
	postCompetition,
	updateCompetition,
} from "../../../../api/competition";
import { getCompetitionTypes } from "../../../../api/competitionType";
import { checkIsEmpty, getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import Table, { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { ArrowDownward, ArrowUpward, Delete, Edit, Visibility } from "@material-ui/icons";
import Editor from '../../../components/editor/Editor'
import { Row, Col } from 'react-bootstrap';
import EditTramDialog from "../../../components/dialogs/EditTramDialog";
import _ from 'lodash';
import MyAutocomplete from "../../../components/MyAutocomplete";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyCompetition() {
	let newDate = new Date()
    let date = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
	return {
		fullName: {},
		location: '',
		date: date,
		competitionType: null,
		active: true,
		description: '',
		month: '',
		totalKilometers: '',
		imageURL: null,
		longitude: '',
		latitude: '',
		departTime: '',
		accumulatedPositiveSlope: '',
		accumulatedNegativeSlope: '',
		trams: [],
		meteorologicalPrevision: '',
		temp: '',
		relativeHumidity: '',
		imagePerfil: null,
	};
}

function getEmptyTram() {
	return {
		number: 0,
		name: "",
		longitude: "",
		positiveUnevenness: "",
		negativeUnevenness: "",
		extraInfo: {es: ""}
	}
}

export default function EditCompetitionsPage() {
	const [competitionTypes, setCompetitionTypes] = useState([]);
	const [competition, setCompetition] = useState(getEmptyCompetition());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [selectedImageCompetition, setSelectedImageCompetition] = useState(null);
	const [selectedImagePerfil, setSelectedImagePerfil] = useState(null);
	const [competitionId, setCompetitionId] = useState(useParams().id);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const [openEditTramDialog, setOpenEditTramDialog] = useState(false);
	const [openViewTramDialog, setOpenViewTramDialog] = useState(false);
	const [selectedTram, setSelectedTram] = useState(null);

	const [refresh, setRefresh] = useState(false);

	const [changes, setChanges] = useState(-2);

	useEffect(() => {
		if (!competitionId) setChanges(changes+2);
		else setChanges(changes+1);
	}, [competition])

	const monthOptions = [
		{ _id: '1', fullName: 'January' },
		{ _id: '2', fullName: 'February' },
		{ _id: '3', fullName: 'March' },
		{ _id: '4', fullName: 'April' },
		{ _id: '5', fullName: 'May' },
		{ _id: '6', fullName: 'June' },
		{ _id: '7', fullName: 'July' },
		{ _id: '8', fullName: 'August' },
		{ _id: '9', fullName: 'September' },
		{ _id: '10', fullName: 'October' },
		{ _id: '11', fullName: 'November' },
		{ _id: '12', fullName: 'December' },
	];

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();
	function unevennessFormater(cell) {return parseFloat(cell).toFixed(0);}
	function longitudeFormater(cell) {return parseFloat(cell).toFixed(1);}

	const tramsTable = [
		{ dataField: "name", text: "Name" },
		{ 
			dataField: "longitude", 
			text: "Cumulative distance (km)",
			headerAlign: "center",
			align: "center",
			formatter: longitudeFormater
		},
		{ 
			dataField: "positiveUnevenness", 
			text: "Cumulative elevation gain (m)",
			formatter: unevennessFormater,
			headerAlign: "center",
			align: "center",
		},
		{ 
			dataField: "negativeUnevenness", 
			text: "Cumulative elevation loss (m)",
			formatter: unevennessFormater,
			headerAlign: "center",
			align: "center",
		},
		{ 
			dataField: "partialDistance", 
			text: "Partial distance (km)",
			formatter: longitudeFormater,
			headerAlign: "center",
			align: "center",
		},
		{ 
			dataField: "partialElevationGain", 
			text: "Partial elevation gain (m)",
			formatter: unevennessFormater,
			headerAlign: "center",
			align: "center",
		},
		{ 
			dataField: "partialElevationLoss", 
			text: "Partial elevation loss (m)",
			formatter: unevennessFormater,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "number",
			text: "",
			headerAlign: 'right',
			headerStyle: { width: '200px' },
			formatter: tramsButtonFormater
		},
	];

	useEffect(() => {
		getCompetitionTypes()
			.then((res) => {
				if (res.status === 200) {
					setCompetitionTypes(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get competition types.",
				});
			});
		if (!competitionId) {
			disableLoadingData();
			return;
		}
		getCompetitionById(competitionId)
			.then((res) => {
				if (res.status === 200) {
					let competitionData = res.data;
                    if (competitionData.date) {
                        let competitionDate = new Date(competitionData.date);
                        competitionData.date = new Date(competitionDate.getTime() - competitionDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
                    }
					setCompetition(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get competition.",
				});
				history.push("/competitions");
			});
	}, [competitionId, disableLoadingData, history]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	function copyCompetition() {
		if ((!competitionId && !newCompetition()) || !updateComp()) {
			let comp = _.cloneDeep(competition);
			comp.fullName.es = comp.fullName.es + " - Copy"
			delete comp._id;
			setCompetition(comp);
			setCompetitionId(null);
			setRefresh(true);
		}
	}

	function saveAble() {
		if (checkIsEmpty(competition.fullName)) {
			alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
			return false;
		}
			
		if (!competition?.competitionType) {
			alertError({
				error: null,
				customMessage: "Competition type is required.",
			});
			return false;
		}
			
		return true;
	}

	//Devuelve true si hay error
	function newCompetition() {
		postCompetition(competition, selectedImageCompetition, selectedImagePerfil)
			.then((res) => {
				if (res.status === 201) {
					alertSuccess({
						title: "Saved!",
						customMessage: "Competition successfully created.",
					});

					return false;
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not save competition.",
				});

				return true;
			});
	}

	//Devuelve true si hay error
	function updateComp() {
		updateCompetition(competitionId, competition, selectedImageCompetition, selectedImagePerfil)
			.then((res) => {
				if (res.status === 200) {
					alertSuccess({
						title: "Saved!",
						customMessage: "Changes successfully saved.",
					});
					
					return false;
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not save changes.",
				});
				return true;
			});

	}

	function saveCompetition() {
		if (saveAble()) {
			if (!competitionId && !newCompetition()) history.push("/competitions");
			else if (!updateComp()) history.push("/competitions");
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!competition[element]) competition[element] = {};
			let newText = competition[element];
			newText[lang] = event.target.value;
			setCompetition({ ...competition, [element]: newText });
		} else
			setCompetition({
				...competition,
				[element]: event.target.value,
			});
	};
	

	const handleChangeEditor = (element, lang, value) => {
		if (lang) {
		  if (value === ' ') return
		  if (!competition[element]) competition[element] = {}
		  let newText = competition[element]
		  newText[lang] = value
		  setCompetition({ ...competition, [element]: newText })
		} else setCompetition({ ...competition, [element]: value })
	  }

	  function getTramsData(trams) {

		function sortByNumber(a, b) {
			if (a.number < b.number) return -1;
			if (a.number > b.number) return 1;
			return 0;
		}

		let data = [];

		const salida = {number: 0, name: "SALIDA", longitude: 0, positiveUnevenness: 0, negativeUnevenness: 0, partialDistance: 0, partialElevationGain: 0, partialElevationLoss: 0};
		data.push(salida);

		let tramAnt = salida;
		for (let i = 0; i < trams?.length; ++i) {
			const elem = {};
			const tram = trams[i];
			const longitude = parseFloat(tram?.longitude || 0);
			const positive = parseFloat(tram?.positiveUnevenness || 0);
			const negative = parseFloat(tram?.negativeUnevenness || 0);

			elem.number = tram?.number;
			elem.name = tram?.name;
			elem.longitude = longitude;
			elem.positiveUnevenness = positive;
			elem.negativeUnevenness = negative;
			elem.extraInfo = tram?.extraInfo?.es;

			elem.partialDistance = longitude - parseFloat(tramAnt?.longitude || 0);
			elem.partialElevationGain = positive - parseFloat(tramAnt?.positiveUnevenness || 0);
			elem.partialElevationLoss = negative - parseFloat(tramAnt?.negativeUnevenness || 0);
			
			tramAnt = {...tram};
			data.push(elem)
		}

		data.sort(sortByNumber)

		return data;
	}

	function tramMove(tram, index, newIndex) {
		let trams = [
			...competition.trams
		]
		const tramUp = trams.find(
			(x) => x.number === newIndex
		)
		
		trams[newIndex-1] = {
			...tram,
			number: newIndex
		};
		trams[index-1] = {
			...tramUp,
			number: index
		}

		setRefresh(true)
		setCompetition({
			...competition,
			trams: trams
		})
	}

	function deleteTram(index) {
		let trams = [
			...competition.trams
		]

		for (let i = index + 1; i < trams.length; ++i) {
			trams[i].number = i;
		}
		
		trams.splice(index, 1)
		setCompetition({
			...competition,
			trams: trams,
		})

		setRefresh(true);
	}

	function tramsButtonFormater(cell) {
		const index = cell;
		const tram = competition?.trams?.find(
			(x) => x.number === cell
		)

		return (
			<>
				{cell != 0 && (
					<> 
						<Tooltip title="Edit">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setOpenEditTramDialog(true);
									setSelectedTram(tram);
								}}
							>
								<Edit />
							</Button>
						</Tooltip>
						<Tooltip title="Move up">
							<Button
								size="small"
								style={buttonsStyle}
								disabled={index === 1}
								onClick={() => tramMove(tram, index, index-1)}
							>
								<ArrowUpward />
							</Button>
						</Tooltip>
						<Tooltip title="Move down">
							<Button
								size="small"
								disabled={
									index >= competition.trams?.length
								}
								style={buttonsStyle}
								onClick={() => tramMove(tram, index, index+1)}
							>
								<ArrowDownward />
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {deleteTram(index-1)}}
							>
								<Delete />
							</Button>
						</Tooltip>
					</>
				) 
				}
				
			</>
		)
	}

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={(competition.fullName && competition.fullName[lang]) || ""}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
				/>
				<Editor
				  body={(competition.description && competition.description[lang]) || ''}
				  setBody={new_body => handleChangeEditor('description', lang, new_body)}
				  className='max-height'
				  lang={lang}
				  placeholder={'Enter description here...'}
				  name="Description"
				/>
			</>
		);
	};

	const addNew = () => {
		const number = competition?.trams?.length + 1 || 1;
		const emptyTram = getEmptyTram();
		const newTram = {
			...emptyTram,
			number: number,
		};
		setOpenEditTramDialog(true)
		setSelectedTram(newTram)
		setRefresh(true)
	}

	function getFormattedDate(date) {
		const newDate = new Date(date);
		const year = newDate.getFullYear();
		const month = String(newDate.getMonth() + 1).padStart(2, '0');
		const day = String(newDate.getDate()).padStart(2, '0');

  		return `${year}-${month}-${day}`;
	}

	const openImageInNewTab = (imageSrc) => {
		const imageHTML = `
		  <!DOCTYPE html>
		  <html lang="en">
		  <head>
			<meta charset="UTF-8">
			<meta name="viewport" content="width=device-width, initial-scale=1.0">
			<title>Image Preview</title>
			<style>
			  body {
				margin: 0;
				background-color: #000;
				overflow: hidden;
			  }
			  .container {
				width: 100%;
				height: 100vh;
				overflow: scroll; /* Permite el desplazamiento */
				display: block; /* Evita centrado */
			  }
			  img {
				display: block;
				max-width: 100%;
				max-height: 100%;
				cursor: zoom-in;
				transition: transform 0.2s ease-in-out;
				transform-origin: left top; /* Fija el zoom a la esquina superior izquierda */
			  }
			  img.zoomed {
				cursor: zoom-out;
				transform: scale(3); /* Ajusta el nivel de zoom */
			  }
			</style>
		  </head>
		  <body>
			<div class="container">
			  <img src="${imageSrc}" alt="Image" onclick="toggleZoom(this)" />
			</div>
			<script>
			  function toggleZoom(image) {
				if (image.classList.contains('zoomed')) {
				  image.classList.remove('zoomed');
				} else {
				  image.classList.add('zoomed');
				}
			  }
			</script>
		  </body>
		  </html>
		`;
		const newWindow = window.open();
		newWindow.document.write(imageHTML);
		newWindow.document.close();
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title={competition?._id?'Competition: ' + (competition.fullName?competition.fullName.es:''):'New competition'}>
						<div className="mt-5">
							<Button
								onClick={() => {
									if (changes > 0) setOpenConfirmDialog(1);
									else history.push("/competitions");
								}}
								variant="outlined"
								style={{ marginRight: "20px" }}
							>
								Back
							</Button>
							<Button
								onClick={() => {
									setOpenConfirmDialog(3);
								}}
								variant="outlined"
								style={{ marginRight: "20px" }}
							>
								Copy competition
							</Button>
							<Button
								onClick={() => saveCompetition()}
								variant="outlined"
								color="primary"
								style={{ marginRight: "20px" }}
							>
								Save competition
							</Button>
							<ConfirmDialog
								title={
									"Are you sure you want to go back? You will lose all your changes"
								}
								open={openConfirmDialog === 1}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									history.push("/competitions")
								}}
							/>
							<ConfirmDialog
								title={
									"Are you sure you want to copy this competition? You will save all your changes"
								}
								open={openConfirmDialog === 3}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									if (saveAble()) copyCompetition();
								}}
							/>
							{competitionId && user?.role.includes("admin") && (
								<>
									<MuiThemeProvider theme={theme}>
										<Button
											onClick={() => setOpenConfirmDialog(2)}
											variant="outlined"
											color="secondary"
											style={{ marginRight: "20px" }}
										>
											Delete competition
										</Button>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												marginLeft: "auto",
											}}
										></div>
									</MuiThemeProvider>

									<ConfirmDialog
										title={
											"Are you sure you want to delete this competition?"
										}
										open={openConfirmDialog === 2}
										setOpen={setOpenConfirmDialog}
										onConfirm={() => {
											deleteCompetition(competitionId)
												.then((res) => {
													if (
														res.status === 204 ||
														res.status === 200
													) {
														alertSuccess({
															title: "Deleted!",
															customMessage:
																"Competition deleted successfully",
														});
														history.push("/competitions");
													}
												})
												.catch((error) => {
													alertError({
														error: error,
														customMessage:
															"Could not delete competition.",
													});
												});
										}}
									/>
								</>
							)}
						</div>
					</CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<Row>
							<Col>
								<TextField
									id={`location`}
									label={`Location`}
									value={
										(competition.location) ||
										""
									}
									onChange={handleChange('location')}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="standard"
									type="text"
									required
								/>
							</Col>
							<Col sm={3}>
								<MyAutocomplete
									options={competitionTypes}
									getOptionLabel={(option) =>
										getNonEmpty(option.fullName)
									}
									value={
										competitionTypes?.find(
											(x) => x._id === competition.competitionType
										) || null
									}
									onChange={(event, selected) => {
										setCompetition({
											...competition,
											competitionType: selected?._id,
										});
									}}
									placeholder="Select competition type"
									label={"Competition type"}
								/>
							</Col>
							<Col>
								<TextField
									id={`competitionDate`}
									label="Date"
									value={getFormattedDate(competition.date)}
									onChange={handleChange('date')}
									InputLabelProps={{
										shrink: true
									}}
									margin="normal"
									variant="standard"
									type='date'
									required
								/>
							</Col>
							<Col >
								<TextField
									id={`departTime`}
									label={`Start time`}
									value={
										(competition.departTime) ||
										""
									}
									onChange={handleChange('departTime')}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="standard"
									type="text"
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={2}>
								<TextField
									id={`totalKilometers`}
									label={`Total distance (km)`}
									value={
										(competition.totalKilometers) ||
										""
									}
									onChange={handleChange('totalKilometers')}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="standard"
									type="number"
									InputProps={{
										inputProps: {
											min: 1, max:200
										},
									}}
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`accumulatedPositiveSlope`}
									label={`Total elevation gain (m)`}
									value={
										(competition.accumulatedPositiveSlope) ||
										""
									}
									onChange={handleChange('accumulatedPositiveSlope')}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="standard"
									type="text"
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`accumulatedNegativeSlope`}
									label={`Total elevation loss (m)`}
									value={
										(competition.accumulatedNegativeSlope) ||
										""
									}
									onChange={handleChange('accumulatedNegativeSlope')}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="standard"
									type="text"
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`meteorologicalPrevision`}
									label={`Weather prediction`}
									value={
										(competition.meteorologicalPrevision) ||
										""
									}
									onChange={handleChange('meteorologicalPrevision')}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="standard"
									type="text"
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`temp`}
									label={`Temperature (ºC)`}
									value={
										(competition.temp) ||
										""
									}
									onChange={handleChange('temp')}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="standard"
									type="text"
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`relativeHumidity`}
									label={`Relative humidity`}
									value={
										(competition.relativeHumidity) ||
										""
									}
									onChange={handleChange('relativeHumidity')}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="standard"
									type="text"
								/>
							</Col>
						</Row>
						
						 &nbsp;
						 &nbsp;
						
						<br />
						<label htmlFor={"upload-image-perfil"} className="ml-0">
							<input
								style={{ display: "none" }}
								id={"upload-image-perfil"}
								name={"upload-image-perfil"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedImagePerfil(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedImagePerfil ||
								(competition.imagePerfil &&
									competition.imagePerfil !== "")
									? "Change image perfil"
									: "Upload image perfil"}
							</Button>
						</label>
						{(selectedImagePerfil ||
  (competition.imagePerfil && competition.imagePerfil !== "")) && (
  <>
    <Tooltip title={"Open in new tab"}>
      <Button
        size="small"
        onClick={() =>
          openImageInNewTab(
            selectedImagePerfil
              ? URL.createObjectURL(selectedImagePerfil)
              : `${SERVER_URL}/${competition.imagePerfil}`
          )
        }
        style={{
          ...buttonsStyle,
          marginRight: "15px",
        }}
      >
        <Visibility />
      </Button>
    </Tooltip>
    <PreviewDialog
      title={"Preview image"}
      open={openPreviewDialog === 2}
      setOpen={setOpenPreviewDialog}
      src={
        selectedImagePerfil
          ? URL.createObjectURL(selectedImagePerfil)
          : `${SERVER_URL}/${competition.imagePerfil}`
      }
    />
    <span>
      {selectedImagePerfil
        ? selectedImagePerfil?.name
        : competition.imagePerfil &&
          competition.imagePerfil !== ""
        ? competition.imagePerfil?.split(/-(.*)/s)[1]
        : ""}
    </span>
    <Tooltip title={"Delete image"}>
      <Button
        size="small"
        onClick={() => {
          setSelectedImagePerfil(null);
          setCompetition({
            ...competition,
            imagePerfil: null,
          });
        }}
        style={{
          ...buttonsStyle,
          marginRight: "15px",
        }}
      >
        <Delete />
      </Button>
    </Tooltip>
  </>
)}


						<br />

						<FormControlLabel
							control={
								<Checkbox
									checked={competition.active}
									onChange={() =>
										setCompetition({
											...competition,
											active: !competition.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
					<CardHeader title="Competition Sections"> 
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => addNew()}
							>
								Add new
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh && (
							<Table
								columns={tramsTable}
								data={getTramsData(competition?.trams)}
							/>
						)}
					</CardBody>
					<EditTramDialog 
						open={openEditTramDialog || openViewTramDialog}
						setOpen={
							openViewTramDialog
								? setOpenViewTramDialog
								: setOpenEditTramDialog}
						data={selectedTram}
						readOnly={openViewTramDialog}
						onSave={(tram) => {
							let trams = [];
							if (competition?.trams) 
								trams = [
									...competition?.trams
								];

							const number = trams.findIndex(
								(x) => x.number === tram.number
							)

							if (number !== -1) {
								trams[number] = {
									...tram,
								};
							} else {
								trams.push({
									...tram,
								})
							}

							setRefresh(true)
							setCompetition({
								...competition,
								trams: trams
							})
						}}
					/>
				</Card>
			</>
		);
}
