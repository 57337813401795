import { API, authClient } from "../index";

export const getUserBodyWeights = () => {
	return authClient().get(`${API}/user-body-weights?getDisabled=true`);
};

export const getUserBodyWeightsById = (id) => {
	return authClient().get(`${API}/user-body-weights/${id}`);
};

export const getUserBodyWeightsByUserId = (userId) => {
	return authClient().get(`${API}/user-body-weights/user/${userId}`);
};

export const deleteUserBodyWeights = (id) => {
	return authClient().delete(`${API}/user-body-weights/${id}`);
};

export const postUserBodyWeights = async (userBodyWeights) => {
	return authClient().post(`${API}/user-body-weights`, userBodyWeights);
};

export const updateUserBodyWeights = async (id, userBodyWeights) => {
	return authClient().put(`${API}/user-body-weights/${id}`, userBodyWeights);
};

export const changeStatusUserBodyWeights = async (id, active) => {
	return authClient().put(`${API}/user-body-weights/change-status/${id}`, {
		active,
	});
};