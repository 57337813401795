import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alertError } from "../../../utils/logger";
import { Col, Row } from "react-bootstrap";
import MyAutocomplete from "../MyAutocomplete";
import { Autocomplete } from "@material-ui/lab";

export default function EditDocumentTag(props)  {
    const { open, setOpen, data, onSave, tagOptions } = props;
    const [tag, setTag] = useState(null);

    useEffect(() => {
        setTag(data);
    }, [data])

    const handleChange = (element) => (event) => {
        setTag({...tag, [element]: event.target.value});
    }

    function close() {
        setOpen(false);
        setTag(null);
    }

    return (
        <Dialog disableBackdropClick={true} 
            fullWidth={true}
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="lg"
            aria-labelledby="table-dialog"
        >
            <DialogTitle id="table-dialog">{"Tag"}</DialogTitle>
			<DialogContent>
                <Row>
                    <Col>
						{/* <MyAutocomplete 
							options={  tagOptions }
							getOptionLabel={(option) => option.name}
							value={ tag?.name }
							onChange={(event, selected) => {
								setTag({
									...tag,
									name: selected?.name
								})
							}}
							placeholder="Select tag"
                            label={"Tag name"}
						/> */}
						<Autocomplete
							disablePortal
							disableClearable
							freeSolo
							options={tagOptions}
							getOptionLabel={(option) => option.name || ""}
							value={tag}
							inputValue={tag?.name || ""}
							onChange={(event, selected) => {
								setTag({
									name: selected?.name || "",
								});
							}}
							onInputChange={(event, newInputValue) => {
								setTag({ name: newInputValue });
							}}
							renderOption={(option, props) => (
								<li
									{...props}
									style={{
										color: props?.selected ? "gray" : "inherit",
										opacity: props?.selected ? 0.5 : 1,
									}}
								>
									{option.name}
								</li>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									margin="normal"
									variant="standard"
									color="green"
									InputLabelProps={{
										shrink: true,
									}}
									required
									placeholder="Select tag"
									label={"Tag name"}
								/>
							)}
						/>
                    </Col>
                </Row>
				<br /> <br /> <br /> <br /> <br />
				<br /> <br /> <br /> <br /> <br />
            </DialogContent>
            <DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						close();
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (!tag?.name) {
							alertError({
								error: null,
								customMessage:
									"The name is required",
							});
						} else {
							onSave(tag);
							close();
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
        </Dialog>
    )
}