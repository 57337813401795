import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls'
import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme
} from '@material-ui/core'
import { useSkeleton } from '../../../../hooks/useSkeleton'
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import Editor from '../../../../components/editor/Editor'
import { Col, Row } from 'react-bootstrap';
import Table, { buttonsStyle, dateFormatter } from '../../../../components/tables/table';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import { alertError } from '../../../../../utils/logger';
import EditDocumentTag from '../../../../components/dialogs/EditDocumentTag';
import { postDocumentsTag } from '../../../../../api/documentsTag';
import { SERVER_URL } from '../../../../../api';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyDocument() {
	return {
		title: "",
		description: "",
		fileURL: null,
		active: true
	}
}

export default function EditPatientDocument(props) {
	const { data, setOpen, onDelete, onSave, newDocument, allTags, setAllTags } = props;
	const [document, setDocument] = useState(getEmptyDocument());
	const [selectedFile, setSelectedFile] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
	const [openEditTag, setOpenEditTag] = useState(false);
	const [selectedTag, setSelectedTag] = useState(null);
	const [tagOptions, setTagOptions] = useState([]);
	const [refresh, setRefresh] = useState(false);

	const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const [changes, setChanges] = useState(-2);

	useEffect(() => {
		if (data) {
			setDocument(data);
			disableLoadingData();
		} else setDocument(getEmptyDocument()); //Aqui no deberia entrar
	}, [data, disableLoadingData])

	useEffect(() => {
		if (changes <= 0) setChanges(changes+1);
	}, [document, selectedFile])

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		if (allTags?.length > 0) getTagOptions(allTags);
	}, [allTags])

	function getTagOptions(allTags) {
		let data = [];
		for (let i = 0; i < allTags?.length; ++i) 
			data.push({name: allTags[i].name});
		
		setTagOptions(data);
	}

	function getDataTags(document) {
		let data = [];
		for (let i = 0; i < document?.tags?.length; ++i) 
			data.push({name: document.tags[i].name});
		
		return data;
	}

	const tagsColumns = [
		{
			dataField: "name",
			text: "Name"
		},
		{
			dataField: "name",
			text: "",
			align: 'right',
			formatter: buttonFormatter
		}
	]

	function buttonFormatter(cell, row) {
		const tag = document?.tags?.find((x) => x.name === cell)
        return (
			<>
                <Tooltip title="Edit tag">
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={() => {
                            setOpenEditTag(true);
                            setSelectedTag(tag);
                        }}
                    >
                        <Edit />
                    </Button>
                </Tooltip>
                <Tooltip title="Delete tag">
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={() => {
							deleteTag(cell);
                        }}
                    >
                        <Delete />
                    </Button>
                </Tooltip>
            </>
		)
	}

	function deleteTag(name) {
		let newTags = [...{...document}.tags];
		const index = newTags.find((x) => x.name === name);
		newTags.splice(index, 1);
		setDocument({...document, tags: newTags});
		setRefresh(true);
	}
	
	const handleChange = (element, lang) => (event) => {
		setDocument({ ...document, [element]: event.target.value })
	}
	
	const handleChangeEditor = (element, lang, value) => {
		if (lang) {
		if (value === ' ') return
		if (!document[element]) document[element] = {}
		let newText = document[element]
		newText[lang] = value
		setDocument({ ...document, [element]: newText })
		} else setDocument({ ...document, [element]: value })
	}

	async function addTagToBD(tag) {
		if (!tag?._id) {
			const res = await postDocumentsTag(tag)
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save tag"
					})
					return false;
				})
			
			if (res.status === 201) {
				let newTags = [...allTags];
				newTags.push(tag);
				setAllTags([...newTags]);
				return true;
			}
			return false;
		}
		else {
			alertError({
				error: null,
				customMessage: "Tag exists on BD"
			})
			return false;
		}
		return false;
	} 

	async function onSaveTag(tag) {
		let tags = [...{...document}.tags];

		function addToDocument(tag) {
			const index = tags?.findIndex((x) => x.name === tag.name);
			if (index === -1) tags.push(tag);
			else tags[index] = tag;
			setDocument({...document, tags: tags});
		}
		
		const index = allTags?.findIndex((x) => x.name === tag.name);
		if (index === -1) {
			const res = await addTagToBD(tag);
			if (res) addToDocument(tag);
		}
		else addToDocument(tag);

		setRefresh(true);
	}

	function getDate(date) {
		if (date) return dateFormatter(date);
		return '';
	}

	function getType(url) {
		if (url) {
			const splitted = url.split('.');
			return splitted[splitted.length-1];
		}
		return '';
	}

	console.log(`${SERVER_URL}/${document?.fileURL}`)

	if (isLoadingData)
		return <ContentSkeleton />
	else return (
		<>
		<Card>
		<CardHeader title={"Edit document"}>
			<div className='mt-5'>
			<Button
				onClick={() => {
				if (changes > 0) setOpenConfirmDialog(1);
				else setOpen(false);
				}}
				variant="outlined"
				style={{ marginRight: '20px' }}>
				Back
			</Button>
			<Button
				onClick={async () => {
					if (!document?.title) {
						alertError({
							error: "Title is needed",
							customMessage: "Could not save document"
						})
					} else {
						const res = await onSave(document, selectedFile);
						if (res) setOpen(false);
					}
				}}
				variant="outlined"
				color="primary"
				style={{ marginRight: '20px' }}>
				Save document
			</Button>
			<ConfirmDialog
				title={
				"Are you sure you want to go back? You will lose all your changes"
				}
				open={openConfirmDialog === 1}
				setOpen={setOpenConfirmDialog}
				onConfirm={async () => {setOpen(false);}}
			/>
				{!newDocument && user?.role.includes("admin") && (
					<>
					<MuiThemeProvider theme={theme}>
						<Button
						onClick={() => setOpenConfirmDialog(2)}
						variant="outlined"
						color="secondary"
						style={{ marginRight: "20px" }}
						>
						Delete document
						</Button>
						<div
						style={{
							display: "flex",
							flexDirection: "row",
							marginLeft: "auto",
						}}
						></div>
					</MuiThemeProvider>

					<ConfirmDialog
						title={
						"Are you sure you want to delete this document?"
						}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={async () => {
							const res = await onDelete(document);
							if (res) setOpen(false);
						}}
					/>
					</>
				)}
			</div>
		</CardHeader>
			<CardBody>
			<TextField
				id={`title`}
				label="Title"
				value={document?.title || ""}
				onChange={handleChange('title', false)}
				InputLabelProps={{
				shrink: true
				}}
				margin="normal"
				variant="standard"
				required
			/>
			<Editor
				body={document?.description || ""}
				setBody={new_body => handleChangeEditor('description', false, new_body)}
				className='max-height'
				placeholder={'Enter document description here...'}
				name="Description"
			/>
			<br />
			<Row>
				<Col>	
				<TextField
					id={`patientNotes`}
					label="Patient notes"
					value={document?.patientNotes || ""}
					InputLabelProps={{
					shrink: true
					}}
					margin="normal"
					variant="standard"
					inputProps={{ readOnly: true }}
					className='readonly'
				/>
				</Col>
			</Row>
			<Row>
				<Col>
					<TextField
						id={`dateUplaod`}
						label="Upload date"
						inputProps={{ readOnly: true }}
						value={getDate(document?.uploadAt)}
						margin="normal"
						variant="standard"
						type="text"
						className='readonly'
					/>
				</Col>
				<Col>
					<TextField
						id={`docDate`}
						label="Document date"
						inputProps={{ readOnly: true }}
						value={getDate(document?.docDate)}
						margin="normal"
						variant="standard"
						type="text"
						className='readonly'
					/>
				</Col>
			</Row>
			<br />
			<Row>
				<Col>				
				<label htmlFor={"upload"} className="ml-0">
					<input
						style={{ display: "none" }}
						id={"upload"}
						name={"upload"}
						type="file"
						accept={"/*"}
						onChange={(e) => {
							setSelectedFile(e.target.files[0]);
							setDocument({
								...document,
								uploadAt: new Date(),
								docDate: e.target.files[0].lastModified
							})
						}}
					/>
					<Button
						style={{ marginRight: "15px" }}
						color="secondary"
						component="span"
						variant="outlined"
					>
						{selectedFile ||
						(document?.fileURL&&
							document?.fileURL!== "")
							? "Change file"
							: "Upload file"}
					</Button>
				</label>
				{(selectedFile ||
					(document?.fileURL &&
						document?.fileURL !== "")) && (
					<>
						<span>
							{selectedFile
								? selectedFile?.name
								: document?.fileURL &&
									document?.fileURL !== ""
								? document?.fileURL?.split(
										/-(.*)/s
									)[1]
								: ""}
						</span>
						<Tooltip title={"Delete file"}>
							<Button
								size="small"
								onClick={() => {
									setSelectedFile(null);
									setDocument({
										...document,
										fileURL: null,
										uploadAt: null,
										docDate: null
									})
								}}
								style={{
									...buttonsStyle,
									// marginRight: "15px",
								}}
							>
								<Delete />
							</Button>
						</Tooltip>
						{ (selectedFile?.type === "application/pdf" || 
							getType(document?.fileURL) === "pdf"
						) && (
							<Tooltip title={"View"}>
								<Button
									size="small"
									onClick={() => {
										const pdfUrl = selectedFile
										? URL.createObjectURL(selectedFile) 
										: `${SERVER_URL}/${document.fileURL}`;
										if (pdfUrl) window.open(pdfUrl, '_blank');
									}}
									style={{
										...buttonsStyle,
										marginRight: "30px",
									}}
								>
									<Visibility />
								</Button>
							</Tooltip>
						)}
					</>
				)}
				</Col>
			</Row>

			
			<FormControlLabel
				control={
				<Checkbox
					checked={document?.active}
					onChange={() =>
					setDocument({
						...document,
						active: !document?.active,
					})
					}
					name="checkActive"
				/>
				}
				label="Active"
			/>
			</CardBody>
			
			<CardHeader title="Tags">
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            setOpenEditTag(true);
                            setSelectedTag(null);
                        }}
                    >
                        Add new
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
			<CardBody>
				{!refresh && <Table data={getDataTags(document)} columns={tagsColumns}/>}
				{openEditTag && 
					<EditDocumentTag 
					open={openEditTag} 
					setOpen={setOpenEditTag} 
					data={selectedTag}
					tagOptions={tagOptions}
					onSave={(tag) => {
						onSaveTag(tag);
					}}
					/>
				}
			</CardBody>
			
		</Card>
		</>
	);
}
