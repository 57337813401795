import {
	Button,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { alertError, alertSuccess } from "../../../../../utils/logger";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../../hooks/useSkeleton";
import { Col, Row } from "react-bootstrap";
import Table, { buttonsStyle } from "../../../../components/tables/table";
import { ArrowDownward, ArrowUpward, Delete, Edit, LocalDining } from "@material-ui/icons";
import EditPatientTramIntakeDialog from "../../../../components/dialogs/userCompetitions/EditPatientTramIntakeDialog";
import MyAutocomplete from "../../../../components/MyAutocomplete";
import { getStructureById, getStructures } from "../../../../../api/structure";
import EditStructureBlockDialog from "../../../../components/dialogs/structures/EditStructureBlockDialog";
import { InfoPatientCompetition } from "../../../../../utils/competitionCalcs";
import { getTramsCompetitionUser, postTramXUser, updateTramXUser } from "../../../../../api/tramXuser";
import _ from 'lodash';
import { postUserCompetitions, updateUserCompetitions } from "../../../../../api/userCompetitions";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyCompetition() {
	return {
		competitionId: null,
        competitionName: null,
	};
}

export default function EditPatientCompetition(props) {
	const { userId, setOpen, dataComp, competitions, onSave, userCompetitions, 
		setUserCompetitions, changes, setChanges, tramsXuser, setTramsXuser,
		patient, 
	} = props;
	const history = useHistory();

	const [competition, setCompetition] = useState(getEmptyCompetition());
    const [competitionOptions, setCompetitionOptions] = useState([]);
    const [competitionIndex, setCompetitionIndex] = useState(-1);

    const [openEditTramUserDialog, setOpenEditTramUserDialog] = useState(false);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);

	const [refresh, setRefresh] = useState(false);
	
	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

    useEffect(() => {
		if (!dataComp) {setCompetition(getEmptyCompetition());disableLoadingData();}
		else {
            setCompetition(dataComp)
            setCompetitionIndex(userCompetitions?.competitions?.findIndex(
                (x) => x.competitionId === dataComp.competitionId));
			const date = new Date(dataComp.date);
			getTramsCompetitionUser(dataComp.competitionId, userId, date.toISOString())
				.then((res) => {
					if (res.status === 200) {
						setTramsXuser(res.data)
					}
				})
				.catch((error) => {
					if (error.status !== 404) {
						alertError({
							error: error,
							customMessage: "Could not get user trams"
						})
					}
				})
            disableLoadingData();
        }
        
	}, [dataComp]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh])

    useEffect(() => {
        if (competitions?.length !== 0) actualizeCompetitionOptions(competitions)
    }, [competitions])

	function actualizeCompetitionOptions(competitions) {
        let data = [];

        for (let i = 0; i < competitions?.length; ++i) {
            let elem = {};
            const act = competitions[i];

            elem.competitionId = act._id;
            elem.competitionName = act.fullName.es;

            data.push(elem);
        }

        setCompetitionOptions(data);
    }

	async function updateUserTrams(tramsXuser) {
		if (tramsXuser?.length > 0) {
			let newIds = [];
			for (let i = 0; i < tramsXuser?.length; ++i) {
				const tram = {
					...tramsXuser[i],
					date: new Date(competition?.date).toISOString()
				};

				if (tram?._id) {
					await updateTramXUser(tram?._id, tram)
						.then((res) => {
							
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: `No se ha podido actualizar el tramo ${tram?.name}`
							})
						})
				}
				else {
					await postTramXUser(tram)
						.then((res) => {
							if (res.status === 201) {
								newIds.push({numTram: tram?.numTram, _id: res.data._id});									
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: `No se ha podido actualizar el tramo ${tram?.name}`
							})
						})
				}  
			}

			let newTrams = [...tramsXuser];
			for (let i = 0; i < newIds?.length; ++i) {
				const _id = newIds[i]?._id;
				const num = newIds[i]?.numTram;
				const index = newTrams?.findIndex((x) => x.numTram === num);
				newTrams[index]._id = _id;
			}
			setTramsXuser(newTrams);
		}
	}

	async function updateCompetitions(competition) {
		if (userCompetitions?.competitions) {
			let userComps = [..._.cloneDeep(userCompetitions?.competitions)];
			const dt = new Date(competition?.date).toISOString();
			const index = userCompetitions?.competitions.findIndex((x) => {
				return x.date === dt
			})

			if (index === -1) {
				if (competition?.competitionId !== -1)userComps.push(competition);
			}
			else {
				userComps[index] = competition;
				if (competition?.competitionId === -1) userComps.splice(index, 1);
			}
			
			const newUserCompetitions = {...userCompetitions, competitions: userComps};
			updateUserCompetitions(userCompetitions._id, newUserCompetitions)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Success!",
							customMessage: "User competitions updated successfully"
						})
						setUserCompetitions(newUserCompetitions)
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "There was an error updating user Competitions"
					})
				}) 
		} else {
			const compA = [{...competition}]
			const newUserCompetitions = {
				userId: userId,
				competitions: compA,
				active: true
			}
			postUserCompetitions(newUserCompetitions)
				.then((res) => {
					alertSuccess({
						title: "Success!",
						customMessage: "User competitions updated successfully"
					})
					setUserCompetitions({...newUserCompetitions, _id: res.data._id})
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not update user competitions"
					})
				})
		}
		
	}

	async function saveCompetition() {
		await updateUserTrams(tramsXuser);
		if (competition?.competitionId) await updateCompetitions(competition);
		close();
	}

	function close() {
		setOpen(false);
	}

    if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<Card>
				<CardHeader title={"Edit competition"}>
					<div className='mt-5'>
						<Button
							onClick={() => {
							// console.log(changes)
							if (changes > 0) setOpenConfirmDialog(1);
							else close();
							}}
							variant="outlined"
							style={{ marginRight: '20px' }}>
							Back
						</Button>
						<Button
							onClick={() => {
								saveCompetition();
							}}
							variant="outlined"
							color="primary"
							style={{ marginRight: '20px' }}>
							Save competition
						</Button>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								close();
							}}
							onDecline={() => {
								setChanges(0);
							}}
						/>
					</div>
				</CardHeader>
				<InfoPatientCompetition 
				userId={userId}
				openEditTramUserDialog={openEditTramUserDialog}
				setOpenEditTramUserDialog={setOpenEditTramUserDialog}
				competition={competition}
				setCompetition={setCompetition}
				competitions={competitions}
				tramsXuser={tramsXuser}
				setTramsXuser={setTramsXuser}
				date={competition?.date}
				/>
			</Card>
		);
}
