import React, { useState, useEffect } from 'react'
import { AppBar, Tabs, Tab } from '@material-ui/core'

function DietTabLock({ dietTabContent, number }) {

	const [tab, setTab] = useState(number || 0)

	useEffect(() => {
		setTab(number || 0);
	}, [number])

	const handleChangeTabs = async (_, newValue) => {
		setTab(newValue)
	}

	return (
		<>
			<AppBar position="static" color="default" key="appbar">
				<Tabs
					value={tab}
					onChange={handleChangeTabs}
					variant="scrollable"
					scrollButtons="auto"
					key="tabs"
				>
					<Tab
						key="TAB1"
						label="DIET" />
					<Tab
						key="TAB2"
						label="COMPETITION" />
					<Tab
						key="TAB3"
						label="NOTES" />
				</Tabs>
			</AppBar>
			<div key={`TABCONTENT${tab}`}>
				{dietTabContent[tab]}
			</div>
		</>
	)
}

export default DietTabLock
