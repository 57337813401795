import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { alertError, alertSuccess } from "../../../../../utils/logger";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
} from "../../../../components/tables/table";
import { changeStatusUserStructures, deleteUserStructures, getUserStructuresByUserId, updateUserStructures } from '../../../../../api/userStructures';
import { shallowEqual, useSelector } from "react-redux";
import EditPatientStructure from "./EditPatientStructure";
import { calcUnitsNt, deleteAllBlocksRecipesFromBD, getDataBlocks } from "../../../../../utils/structuresCalcs";
import { formatFloat, formatInteger, removeHtmlTags } from "../../../../../utils/helpers";
import { useSkeleton } from "../../../../hooks/useSkeleton";
import MyAutocomplete from "../../../../components/MyAutocomplete";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

export default function EditPatientStructures(props) {
    const { patientId, userStructures, setUserStructures, patient } = props;
    const [refresh, setRefresh] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
    const [openEditStructure, setOpenEditStructure] = useState(false);
    const [columnsStructures, setColumnsStructures] = useState([]);
    const [selectedStructure, setSelectedStructure] = useState(null);
    const [numberStructure, setNumberStructure] = useState(0);
    const [unitsType, setUnitsType] = useState({_id: 1, value: "g/kg"})
    const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
    const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();


    useEffect(() => {
		if (!openEditStructure) refreshStructures(); 
	}, [openEditStructure]);

    useEffect(() => {
        setRefresh(false);
    }, [refresh])

    function refreshStructures() {
        getUserStructuresByUserId(patientId)
            .then((res) => {
                if (res.status === 200) {
                    const structures = res.data;
                    const newStructures = structures.map(structure => ({ ...structure }));
                    setUserStructures(newStructures);
                    getData(newStructures, unitsType);
                }
            })
            .catch((error) => {
                if (error?.request?.status && error.request.status != 404) {
                    alertError({
                        error: error,
                        customMessage: "Could not get structures.",
                    });
                }
            });
    }

    const ntOptions = [
		{_id: 1, value: "g/kg"},
		{_id: 2, value: "%"},
        {_id: 3, value: "g"} 
	];

    async function getData(structures, unitsType) {
        let data = [];
        for (let i = 0; i < structures.length; ++i) {
            const elem = {};
            const actS = structures[i];
            elem.fullName = actS.fullName.es;
            elem.createdAt = actS.createdAt;
            elem._id = actS._id;
            elem.active = actS.active;
            elem.description = removeHtmlTags(actS?.description?.es || "There is no description");
            elem.number = actS?.number;

			const calcs = await getDataBlocks(actS?.blocks || [], patient);
            const kcals = calcs.kcals;
			elem.requeriments = calcs?.requeriments;
            elem.energetics = calcs?.energetics;
            elem.carbohydrates = calcUnitsNt(calcs?.carbohydrates, unitsType.value, 
                "carbohydrates", kcals, patient?.selectedWeight || 1);
            elem.proteins = calcUnitsNt(calcs?.proteins, unitsType.value, 
                "proteins", kcals, patient?.selectedWeight || 1);
            elem.fats = calcUnitsNt(calcs?.fats, unitsType.value, 
                "fats", kcals, patient?.selectedWeight || 1);
			elem.fiber = calcs?.fiber;
            data = data.concat(elem);
        }
		setColumnsStructures([...data]);
		disableLoadingData();
        setRefresh(true);
    }

    const columns = [
		{ 
            dataField: "fullName",
            text: "Full Name",
            headerStyle: { width: "200px" },
            sort: true 
        },
        {
            dataField: "description",
            text: "description",
        },
		{
			dataField: "requeriments",
			text: "energy req.",
			headerAlign: 'center',
			align: 'center',
			formatter: formatInteger,
            headerStyle: { width: '100px' }
		},
		{
			dataField: "energetics",
			text: "energy intake",
			headerAlign: 'center',
			align: 'center',
			formatter: formatInteger,
            headerStyle: { width: '100px' }
		},
		{
			dataField: "carbohydrates",
			text: `cho (${unitsType.value})`,
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloatNt,
            headerStyle: { width: '80px' }
		},
		{
			dataField: "proteins",
			text: `prot (${unitsType.value})`,
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloatNt,
            headerStyle: { width: '80px' }
		},
		{
			dataField: "fats",
			text: `fat (${unitsType.value})`,
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloatNt,
            headerStyle: { width: '80px' }
		},
		{
			dataField: "fiber",
			text: "fiber",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat,
            headerStyle: { width: '80px' }
		},
		{ 
            dataField: "_id", 
            text: "", 
            align: 'right', 
			headerStyle: {width: '230px'},
            formatter: buttonFormatter 
        },
	];

    function formatFloatNt(cell) {
        if (unitsType?.value === "g/kg") return formatFloat(cell);
        return formatInteger(cell);
    }

    function buttonFormatter(cell, row) {
		const elem = userStructures.find((item) => item._id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() =>{
                            setOpenEditStructure(true);
                            setSelectedStructure(elem);
                            setNumberStructure(elem?.number);
						}}
					>
						<EditIcon />
					</Button>
				</Tooltip>
                <Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={row?.number === 0}
						onClick={() => {moveStructure(elem, true)}}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={
							row?.number >= userStructures?.length - 1
						}
						style={buttonsStyle}
						onClick={() => {moveStructure(elem, false)}}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setSelectedStructure(elem);
                                    setNumberStructure(elem?.number);
									setOpenConfirmDialog(2);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "#00F" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setSelectedStructure(elem);
                                    setNumberStructure(elem?.number);
									setOpenConfirmDialog(1);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

    async function moveStructure(structure, up) {
        const number = structure?.number;
        const newNumber = number + (up ? -1 : 1);

        let newStructures = [...userStructures];
        const index1 = userStructures.findIndex((x) => x.number === number);
        const index2 = userStructures.findIndex((x) => x.number === newNumber);
        const aux = {...newStructures[number]};
        newStructures[index1] = {...newStructures[index2], number: number};
        newStructures[index2] = {...aux, number: newNumber};


        let res = await updateStructure(newStructures[index1]);
        if (res) res = await updateStructure(newStructures[index2]);
        if (res) {
            alertSuccess({
                title: "Saved!",
                customMessage: "Structures saved."
            })
            setUserStructures([...newStructures]);
            getData(newStructures, unitsType);
        }        
    }

    async function updateStructure(structure) {
        if (structure?._id) {
            const res = await updateUserStructures(structure._id, structure)
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not update structure"
                    })        
                    return false;
                })
            return true;
        }
        else {
            alertError({
                error: null,
                customMessage: "Could not update structure"
            })
            return false;
        }
    }

    async function deleteStructure(structure) {
		const res = await deleteAllBlocksRecipesFromBD(structure);
		if (!res) return;
        deleteUserStructures(structure?._id)
            .then((res) => {
                if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                        title: "Deleted!",
                        customMessage:
                            "Structure deleted successfully",
                    });
                    refreshStructures();
                }
            })
            .catch((error) => {
                alertError({
                    error: error,
                    customMessage:
                        "Could not delete structure?.",
                });
            });
	}

    if (isLoadingData) return (<ContentSkeleton />)
    else if (openEditStructure) return (
        <EditPatientStructure
            structure={selectedStructure}
            setStructure={setSelectedStructure}
            userId={patientId}
            setOpen={setOpenEditStructure}
            patient={patient}
            number={numberStructure}
            getNumberStructures={() => {
                return (userStructures?.length || 0);
            }}
        />
    )
    else return (
        <>
            <Card>
                <CardBody>
                    <MyAutocomplete
                        options={ ntOptions }
                        getOptionLabel={(option) =>
                            option.value
                        }
                        value={ ntOptions.find((option) => option._id === unitsType?._id) || null }
                        onChange={(event, selected) => {
                            setUnitsType(selected);
                            getData(userStructures, selected);
                        }}
                        placeholder="Select units"
                        label={"Type of units"}
                    />
                </CardBody>
                <CardHeader title="User Structures"> 
                <CardHeaderToolbar>
                    <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                        setOpenEditStructure(true);
                        setSelectedStructure(null);
                        setNumberStructure(userStructures?.length || 0);
                    }}
                    >
                    Add new
                    </button>
                </CardHeaderToolbar>
                </CardHeader>
                <ConfirmDialog
                    title={
                    "Are you sure you want to delete the structure?"
                    }
                    open={openConfirmDialog === 1}
                    setOpen={setOpenConfirmDialog}
                    onConfirm={async () => {
                        deleteStructure(selectedStructure)
                    }}
                />

                <ConfirmDialog
                    title={`Are you sure you want to ${
                        selectedStructure?.active ? "disable" : "enable"
                    } this structure?`}
                    open={openConfirmDialog === 2}
                    setOpen={setOpenConfirmDialog}
                    onConfirm={() => {
                        changeStatusUserStructures(
                            selectedStructure._id,
                            !selectedStructure?.active
                        )
                            .then((res) => {
                                if (res.status === 200) {
                                    refreshStructures();
                                    alertSuccess({
                                        title: `${
                                            selectedStructure?.active
                                                ? "Disabled!"
                                                : "Enabled!"
                                        }`,
                                        customMessage: `Structure ${
                                            selectedStructure?.active
                                                ? "disabled"
                                                : "enabled"
                                        } successfully`,
                                    });
                                    setRefresh(true);
                                }
                            })
                            .catch((error) => {
                                alertError({
                                    error: error,
                                    customMessage: `Could not ${
                                        selectedStructure?.active
                                            ? "disable"
                                            : "enable"
                                    } structure.`,
                                });
                            });
                    }}
                />
                <CardBody>
                    {!refresh && <Table data={columnsStructures} columns={columns} />}
                </CardBody>
            </Card>
        </>
    )
}


