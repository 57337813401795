import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import Table, { dateFormatter, buttonsStyle } from '../../../components/tables/table'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import { getUsers, deleteUser, changeStatusUser } from '../../../../api/user'
import { Button, Tooltip, Switch, FormControlLabel } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { alertError, alertSuccess } from '../../../../utils/logger'
import { useHistory } from 'react-router-dom'
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { shallowEqual, useSelector } from 'react-redux';
import FiltersCard from '../../../components/filters/Filter';

function getData(admins) {
  let data = []
  for (let i = 0; i < admins.length; ++i) {
    if (admins[i].role === 'admin') {
      const elem = {}
      elem.name = admins[i].fullName
      elem.email = admins[i].email
      elem.createdAt = admins[i].createdAt
      elem.id = admins[i]._id
      elem.active = admins[i].active;
      data = data.concat(elem)
    }
  }
  return data
}

const initialFilters = {
	active: false
}

export default function AdminsPage() {
  const [data, setData] = useState([])
  const [adminId, setAdminId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [users, setUsers] = useState([])
  const [filteredData, setFilteredData] = useState([]);
  const [collapsed, setCollapsed] = useState(true)
  const [filterOptions, setFilterOptions] = useState(initialFilters)
  const history = useHistory()
  const user = useSelector((store) => store.authentication?.user, shallowEqual)

  function buttonFormatter(cell) {
		const elem = data.find(item => item._id === cell)
		return (
			<>
				<Tooltip title='Edit'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-admin/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role === 'admin' && <>
					<Tooltip title={elem?.active ? "Disable" : "Enable"}>
						<Button
							style={buttonsStyle} size="small"
							onClick={() => {
								setAdminId(elem)
								setOpenConfirmDialog(1)
							}}>
							{elem?.active ? <ToggleOffIcon/> : <ToggleOnIcon style={{ color: 'red' }} />}
						</Button>
					</Tooltip>
					<Tooltip title='Delete'>
						<Button
							style={buttonsStyle}
							size='small'
							onClick={() => {
								setAdminId(cell);
								setOpenConfirmDialog(2);
							}}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>}
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Full name', sort: true },
		{ 
			dataField: 'email', 
			text: 'Email', 
			sort: true 
		},
		{ 
			dataField: 'createdAt', 
			text: 'Created at', 
			formatter: dateFormatter,
			headerAlign: 'center',
			align: 'center',
			headerStyle: { width: '125px' },
			sort: true 
		},
		{ 
			dataField: 'id', 
			text: '',
			align: 'right',
			headerStyle: { width: '150px' },
			formatter: buttonFormatter 
		}
	]

	useEffect(() => {
		getUsers().then((res) => {
			if (res.status === 200) {
				setData(res.data)
				setFilteredData(res.data)
				setUsers(res.data)
				setRefresh(false)
			}
			}).catch(error => {
				alertError({ error: error, customMessage: 'Could not get administrators.' })
			})
	}, [refresh])

	const handleSearch = async () => {
		if (!data.length) return
		setFilteredData(data.filter(item => {
		  	let filter = true
			if (filterOptions.active) filter = filter && item.active
			if (filter) return item
			return false
		}))
	}

	const handleClearFilters = () => {
		setFilterOptions(initialFilters)
		setRefresh(true)
	}

	const renderFiltersContent = () => {
		return <>
			<br/>
			<FormControlLabel
				control={<Switch checked={filterOptions.active} onChange={() => setFilterOptions({ ...filterOptions, active: !filterOptions.active })} name="checkActive" />}
				label={filterOptions.active ? "Only active" : "All active/inactive"}
			/>
		</>
	}

  return (
    <>
      <Card>
        <CardHeader title='Administrators list'>
          <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.push('/edit-admin')}
              >
                Add new
              </button>
            </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
			<FiltersCard 
				filtersContent={renderFiltersContent}
				collapsed={collapsed} 
				setCollapsed={setCollapsed}
				handleClearFilters={handleClearFilters}
				handleSearch={handleSearch}
			/>

			<Table data={getData(filteredData, users)} columns={columns} />

          	<ConfirmDialog
				title={`Are you sure you want to ${adminId?.active ? 'disable' : 'enable'} this admin?`}
				open={openConfirmDialog === 1}
				setOpen={setOpenConfirmDialog}
				onConfirm={() => {
					changeStatusUser(adminId.id, !adminId?.active)
						.then((res) => {
							if (res.status === 200) {
								alertSuccess({ title: `${adminId?.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Admin ${adminId?.active ? 'disabled' : 'enabled'} successfully` })
								setRefresh(true);
							}
						})
						.catch((error) => {
							alertError({ error: error, customMessage: `Could not ${adminId?.active ? 'disable' : 'enable'} admin.` })
						});
				}}
			/>
			<ConfirmDialog
				title={'Are you sure you want to remove this admin?'}
				open={openConfirmDialog === 2}
				setOpen={setOpenConfirmDialog}
				onConfirm={() => {
					deleteUser(adminId)
						.then((res) => {
							if (res.status === 204 || res.status === 200) {
								alertSuccess({
									title: 'Deleted!',
									customMessage: 'Admin removed successfully.',
								});
								setRefresh(true);
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: 'Could not remove admin.',
							});
						});
				}}
			/>
        </CardBody>
      </Card>
    </>
  );
}
