import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
    CardBody
} from "../../../../_metronic/_partials/controls";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { Button, Col, Row } from "react-bootstrap";
import { TextField, Tooltip } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import MyAutocomplete from "../../../components/MyAutocomplete";
import { formatFloat } from "../../../../utils/helpers";
import OnlyTextMult from "../../../components/textFields/OnlyTextMult";

export default function EditPatientPhysiology(props) {
	const { userId, patient, setPatient, handleChange, handleChangeSelector} = props;
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);

    const [weightInfo, setWeightInfo] = useState(false);
    const [BMRInfo, setBMRInfo] = useState(false);
    const [TEEInfo, setTEEInfo] = useState(false);

	const history = useHistory();

	const [refresh, setRefresh] = useState(false);
	
	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
        disableLoadingData();
	}, [userId, disableLoadingData])


	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

    const formulaTMBOptions = [
        { _id: '0', fullName: 'Mifflin – St. Jeor (1990)' },
        { _id: '1', fullName: 'Ten Haaf' },
        { _id: '2', fullName: 'Cunningham (1991)' },
      ];

    function OpenInformation(title, arrowTitle, info, setInfo) {
        const arrowStyle = {color: '#000'}
        return (
            <>
                <br/>
                    <Row style={{backgroundColor: '#E1F2CE'}}>
                        <Col sm={11} style={{marginTop: 10}}>
                            <h5 style={{fontWeight: 'bold'}}>{title.toUpperCase()}</h5>
                        </Col>
                        <Col sm={1}>
                            <Tooltip title={arrowTitle}>
                            <Button
								size="small"
                                style={{backgroundColor: 'transparent', borderColor: 'transparent', marginLeft: 40}}
								onClick={() => setInfo(!info)}
							>
                                {info? (<ExpandLess style={arrowStyle}/>) : 
                                (<ExpandMore style={arrowStyle}/>)}
							</Button>
                            </Tooltip>
                        </Col>
                    </Row>
                <br/>
            </>
        )
    }

    const backgroundStyle = {backgroundColor: '#A7D971'}

    function Title(title) {
        return (
            <Row style={backgroundStyle}>
                <Col style={{marginTop: 8}}>
                    <h5 style={{fontWeight: 'bold'}}>{title}</h5>
                </Col>
            </Row>
        )
    }

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<Card>
				<CardBody>
                    {Title('BASIC ANTHROPOMETRIC INFORMATION')}
                    <Row>
                        <Col>
                        <TextField
                            id={`height`}
                            label="Height (cm)"
                            value={patient?.height}
                            onChange={handleChange('height')}
                            InputLabelProps={{
                            shrink: true
                            }}
                            margin="normal"
                            variant="standard"
                            type='number'
                            required
                        />
                        </Col>
                        <Col>
                        <TextField
                            id={`actualWeight`}
                            label="Current weight (kg)"
                            value={patient?.actualWeight}
                            onChange={handleChange('actualWeight')}
                            InputLabelProps={{
                            shrink: true
                            }}
                            margin="normal"
                            variant="standard"
                            type='number'
                            required
                        />
                        </Col>
                        <Col>
                        <TextField
                            label="Current IMC (kg/m2)"
                            value={formatFloat(patient?.imc)}
                            InputLabelProps={{
                            shrink: true
                            }}
                            inputProps={{ readOnly: true }}
                            margin="normal"
                            variant="standard"
                            className='readonly'
                            type='number'
                        />
                        </Col>
                        <Col>
                        <TextField
                            label="Selected Weight (kg)"
                            value={patient?.selectedWeight}
                            onChange={handleChange('selectedWeight')}
                            InputLabelProps={{
                            shrink: true
                            }}
                            margin="normal"
                            type='number'
                            variant="standard"
                        />
                        </Col>
                        <Col>
                        <TextField
                            label="Selected IMC (kg/m2)"
                            value={patient?.selectedImc}
                            InputLabelProps={{
                            shrink: true
                            }}
                            inputProps={{ readOnly: true }}
                            margin="normal"
                            variant="standard"
                            className='readonly'
                            type="number"
                        />
                        </Col>
                    </Row>
                    {OpenInformation("Additional weight information", "Open weight information", 
                        weightInfo, setWeightInfo)}
                    
                    {weightInfo && (
                        <>
                            <Row>    
                                <Col>
                                <OnlyTextMult title="Comments" element={"basicComments"} handleChange={handleChange} data={patient}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2}>
                                <TextField
                                    id={`idealWeight`}
                                    label="Ideal weight (kg)"
                                    value={patient?.idealWeight}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                    inputProps={{ readOnly: true }}
                                    margin="normal"
                                    variant="standard"
                                    className='readonly'
                                    type='number'
                                />
                                </Col>
                                <Col sm={2}>
                                <TextField
                                    label="Ideal IMC (kg/m2)"
                                    value={patient?.idealImc}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                    inputProps={{ readOnly: true }}
                                    margin="normal"
                                    variant="standard"
                                    className='readonly'
                                    type='number'
                                />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2}>
                                <TextField
                                    id={`objectiveWeight`}
                                    label="Target weight (kg)"
                                    value={patient?.objectiveWeight}
                                    onChange={handleChange('objectiveWeight')}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                    margin="normal"
                                    type='number'
                                    variant="standard"
                                />
                                </Col>
                                <Col sm={2}>
                                <TextField
                                    label="Target IMC (kg/m2)"
                                    value={patient?.imcObjective}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{ readOnly: true }}
                                    margin="normal"
                                    type='number'
                                    variant="standard"
                                    className='readonly'
                                />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2}>
                                <TextField
                                    id={`adjustedWeight`}
                                    label="Adjusted weight (kg)"
                                    value={patient?.adjustedWeight}
                                    onChange={handleChange('adjustedWeight')}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                    inputProps={{ readOnly: true }}
                                    margin="normal"
                                    variant="standard"
                                    className='readonly'
                                    type='number'
                                />
                                </Col>
                                <Col sm={2}>
                                <TextField
                                    label="Adjusted IMC (kg/m2)"
                                    value={patient?.adjustedImc}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                    inputProps={{ readOnly: true }}
                                    margin="normal"
                                    variant="standard"
                                    className='readonly'
                                    type='number'
                                />
                                </Col>
                            </Row>
                        </>
                    )}
                    
                    <br />
                    {Title('BODY COMPOSITION')}
                    <Row>
                        <Col sm={3}>
                        <TextField
                            id={`fatPercentage`}
                            label="Current fat percentage (%)"
                            value={patient?.fatPercentage}
                            onChange={handleChange('fatPercentage')}
                            InputLabelProps={{
                            shrink: true
                            }}
                            margin="normal"
                            variant="standard"
                            type='number'
                            required
                            inputProps={{ min: 0, max: 100 }}
                        />
                        </Col>
                        <Col sm={2}>
                        <TextField
                            id={`fatKilograms`}
                            label="Fat mass (kg)"
                            value={patient?.fatKilograms}
                            onChange={handleChange('fatKilograms')}
                            InputLabelProps={{
                            shrink: true
                            }}
                            inputProps={{ readOnly: true }}
                            margin="normal"
                            variant="standard"
                            className='readonly'
                            type='number'
                        />
                        </Col>
                        <Col sm={2}>
                        <TextField
                            id={`fatFreeMass`}
                            label="Fat free mass (kg)"
                            value={patient?.fatFreeMass}
                            InputLabelProps={{
                            shrink: true
                            }}
                            inputProps={{ readOnly: true }}
                            margin="normal"
                            variant="standard"
                            className='readonly'
                            type='number'
                        />
                        </Col>
                    </Row>
                    <br />
                    {Title('BASAL ENERGY REQUIREMENTS')}
                    <Row>
                        <Col sm={4}>
                        <MyAutocomplete
                            options={formulaTMBOptions}
                            getOptionLabel={(option) =>
                            option.fullName
                            }
                            value={formulaTMBOptions.find(option => option._id === patient?.formulaTMB) || null}
                            onChange={handleChangeSelector('formulaTMB')}
                            placeholder="Select BMR equation"
                            label={"BMR formula"}
                        />
                        </Col>
                        <Col sm={2}>
                        <TextField
                            id={`tmb`}
                            label="BMR (kcal/day)"
                            value={patient?.tmb}
                            onChange={handleChange('tmb')}
                            InputLabelProps={{
                            shrink: true
                            }}
                            inputProps={{ readOnly: true }}
                            margin="normal"
                            variant="standard"
                            className='readonly'
                            type='number'
                        />
                        </Col>
                    </Row>
                    {OpenInformation("Additional BMR units", "Open BMR units", 
                        BMRInfo, setBMRInfo)}
                    {BMRInfo && (
                        <>
                            <Row>
                                <Col sm={2}>
                                    <TextField
                                        id={`tmbkcalkgh`}
                                        label="kcal/kg"
                                        value={patient?.tmbkcalkgh}
                                        onChange={handleChange('tmbkcalkgh')}
                                        InputLabelProps={{
                                        shrink: true
                                        }}
                                        inputProps={{ readOnly: true }}
                                        margin="normal"
                                        variant="standard"
                                        className='readonly'
                                        type='number'
                                    />          
                                </Col>
                                <Col sm={2}>
                                <TextField
                                    id={`tmbkcalh`}
                                    label="kcal/h"
                                    value={patient?.tmbkcalh}
                                    onChange={handleChange('tmbkcalh')}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                    inputProps={{ readOnly: true }}
                                    margin="normal"
                                    variant="standard"
                                    className='readonly'
                                        type='number'
                                />          
                                </Col>
                                <Col sm={2}>
                                    <TextField
                                        id={`tmbmlkgmin`}
                                        label="mL/kg/min"
                                        value={patient?.tmbmlkgmin}
                                        onChange={handleChange('tmbmlkgmin')}
                                        InputLabelProps={{
                                        shrink: true
                                        }}
                                        inputProps={{ readOnly: true }}
                                        margin="normal"
                                        variant="standard"
                                        className='readonly'
                                        type='number'
                                    />          
                                </Col>
                            </Row>
                        </>
                    )}
                    <br />
                    {Title('TOTAL ENERGY EXPENDITURE (REST DAY)')}
                    <Row>
                        <Col sm={3}>
                            <TextField
                                id={`pal`}
                                label="PAL"
                                value={patient?.pal}
                                onChange={handleChange('pal')}
                                InputLabelProps={{
                                shrink: true
                                }}
                                margin="normal"
                                variant="standard"
                                type="number"
                            />    
                        </Col>
                        <Col sm={3}>
                            <TextField
                                id={`teeRest`}
                                label="TEE (kcal/day)"
                                value={patient?.teeRest}
                                onChange={handleChange('teeRest')}
                                InputLabelProps={{
                                shrink: true
                                }}
                                inputProps={{ readOnly: true }}
                                margin="normal"
                                variant="standard"
                                className='readonly'
                                type="number"
                            />
                        </Col>
                    </Row>

                    {OpenInformation("Additional TEE units", "Open TEE units", 
                        TEEInfo, setTEEInfo)}
                    {TEEInfo && (
                        <>
                            <Row>
                                <Col sm={2}>
                                <TextField
                                    id={`gerkcalkgh`}
                                    label="kcal/kg"
                                    value={patient?.gerkcalkgh}
                                    onChange={handleChange('gerkcalkgh')}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                    inputProps={{ readOnly: true }}
                                    margin="normal"
                                    variant="standard"
                                    className='readonly'
                                    type="number"
                                />          
                                </Col>
                                <Col sm={2}>
                                <TextField
                                    id={`gerkcalh`}
                                    label="kcal/h"
                                    value={patient?.gerkcalh}
                                    onChange={handleChange('gerkcalh')}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                    inputProps={{ readOnly: true }}
                                    margin="normal"
                                    variant="standard"
                                    className='readonly'
                                    type="number"
                                />          
                                </Col>
                                <Col sm={2}>
                                <TextField
                                    id={`germlkgmin`}
                                    label="mL/kg/min"
                                    value={patient?.germlkgmin}
                                    onChange={handleChange('germlkgmin')}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                    inputProps={{ readOnly: true }}
                                    margin="normal"
                                    variant="standard"
                                    className='readonly'
                                    type="number"
                                />          
                                </Col>
                            </Row>
                        </>
                    )}

                    <br />
                    

                </CardBody>
			</Card>
		);
}
