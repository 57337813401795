import React, { useState, useEffect } from 'react'
import { alertError, alertSuccess } from '../../../../utils/logger'
import EditVisitDialog from '../../../components/dialogs/EditVisitDialog';
import { 
    Card, 
    CardHeader, 
    CardHeaderToolbar ,
    CardBody
} from '../../../../_metronic/_partials/controls';
import {
    Button,
    Tooltip
  } from '@material-ui/core';
  import { Delete, Edit, ToggleOff, ToggleOn } from '@material-ui/icons';
import { useHistory } from 'react-router-dom'
import Table, { buttonsStyle, dateFormatter } from '../../../components/tables/table';
import { getVisitTypes } from '../../../../api/visitType';
import { useSkeleton } from '../../../hooks/useSkeleton'
import { deleteVisit, getVisitsByPatientId, postVisit, updateVisit } from '../../../../api/visit';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getPatients } from '../../../../api/user';

export default function EditPatientVisits(props) {
    const { patientId, visits, setVisits } = props;
    const [users, setUsers] = useState([]);
    const [visitTypes, setVisitTypes] = useState([]);
    const [openEditVisitDialog, setOpenEditVisitDialog] = useState(false);
    const [visit, setVisit] = useState(getEmptyVisit());
    const [refresh, setRefresh] = useState(false);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
    const [selectedVisit, setSelectedVisit] = useState(null);

    const history = useHistory()
    const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton();

    function getEmptyVisit() {
        let newDate = new Date()
        let date = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
        return {
            fullName: '',
            visitDate: date,
            user: patientId,
            visitType: null,
            patientFeedback: '',
            expectedGoals: '',
            active: true,
        };
    }

    useEffect(() => {
        getVisitTypes()
            .then((res) => {
            if (res.status === 200) {
                setVisitTypes(res.data);
            }
            })
            .catch((error) => {
            alertError({
                error: error,
                customMessage: "Could not get visit types.",
            });
            });
            
        getVisitsByPatientId(patientId)
            .then((res) => {
            if (res.status === 200) {
                setVisits(res.data);
            }
            })
            .catch((error) => {
            alertError({
                error: error,
                customMessage: "Could not get visits.",
            });
            });

        if (users?.length === 0)
            getPatients()
                .then((res) => {
                    if (res.status === 200) {
                        setUsers(res.data);
                    }
                })
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not get patients.",
                    });
                }); 
    }, [patientId, disableLoadingData, history])

    useEffect(() => {
        setRefresh(false);
    }, [refresh])

    const visitsTable = [
        {
            dataField: "visitDate",
            text: "date",
            formatter: dateFormatter,
        },
        {
            dataField: "visitType",
            text: "Visit type",
			// headerStyle: { width: '300px' },
			// headerAlign: "center",
			// align: "center",
        },
        {
            dataField: "_id",
            text: "",
            formatter: visitsButtonFormatter,
			headerStyle: { width: '150px' },
			headerAlign: "right",
			align: "right",
        }
    ];

    function visitsButtonFormatter(cell) {
        const visit = visits.find((item) => item._id === cell)
        
        return (
            <>
                <Tooltip title="Edit">
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={() => {
                            setOpenEditVisitDialog(true)
                            setVisit(visit)
                        }}
                    >
                        <Edit />
                    </Button>
                </Tooltip>
                <Tooltip title={visit?.active? "Disable": "Enable"}>
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={() => {
                            let newVisits = [...visits];
                            const index = newVisits?.findIndex((x) => x._id === cell);
                            if (index !== -1) {
                                newVisits[index] = {
                                    ...visit,
                                    active: !visit.active
                                }
                                actualizeBD(newVisits[index], newVisits);
                            }
                            setRefresh(true);
                        }}
                    >
                        {visit?.active? (<ToggleOff />): (<ToggleOn />)}
                    </Button>
                </Tooltip>
                <Tooltip title="Delete">
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={async () => {
                            setOpenConfirmDialog(1);
                            setSelectedVisit(visit);
                        }}
                    >
                        <Delete />
                    </Button>
                </Tooltip>
            </>
        )
    }

    async function deleteVisitFromBD(visit) {
        const res = await deleteVisit(visit?._id)
            .catch((error) => {
                alertError({
                    error: error,
                    customMessage: "Could not erase visit"
                });
                return false;
            })   
        
        alertSuccess({
            title: "Deleted!",
            customMessage: "Visit deleted successfully"
        })
        return true;
    }

    function addNewVisit() {
        setOpenEditVisitDialog(true)
        setVisit(getEmptyVisit())
        setRefresh(true)
      }

    function getVisitsData(visits) {
        function sortByDate(a, b) {
            return 1;
        }

        function getInfoVisits(visit) {
            const elem = {};

            elem.fullName = visit?.fullName;

            elem.visitDate = visit?.visitDate;
            elem.visitType = visit?.visitType?.fullName?.es || visit?.visitType;
            elem._id = visit?._id;
            elem.user = patientId;
            elem.active = visit?.active;

            return elem;
        }

        let data = [];

        for (let i = 0; i < visits?.length; ++i) {
            const elem = getInfoVisits(visits[i]);
            data.push(elem);
        }

        data.sort(sortByDate)

        return data;
    }

    
    async function actualizeBD(visit, newVisits) {
        if (visit?._id) {
            await updateVisit(visit._id, visit)
                .then((res) => {
                    alertSuccess({title: "Saved!", customMessage: "Visit updated correctly!"})
                    setVisits([...newVisits]);
                })
                .catch((error) => {
                alertError({
                    error: error,
                    customMessage: `Could not update the visit of: ${visit.visitDate}`
                })
                return false;
                })
        }
        else {
            await postVisit(visit)
                .then((res) => {
                    alertSuccess({title: "Saved!", customMessage: "Visit saved correctly!"})
                    setVisits((prevVisits) => {
                        const updatedVisits = [...prevVisits];
                        updatedVisits.push({
                            ...visit,
                            _id: res.data.id,
                        }); 
                        return updatedVisits;
                    });
                })
                .catch((error) => {
                alertError({
                    error: error,
                    customMessage: `Could not post the visit of: ${visit.visitDate}`
                })
                return false;
                })
        }
    }

    return (
        <>
            <Card>
                <CardHeader title="Visits"> 
                <CardHeaderToolbar>
                    <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => addNewVisit()}
                    >
                    Add new
                    </button>
                </CardHeaderToolbar>
                </CardHeader>
                <ConfirmDialog
                    title={
                    "Are you sure you want to delete the visit?"
                    }
                    open={openConfirmDialog === 1}
                    setOpen={setOpenConfirmDialog}
                    onConfirm={async () => {
                        let newVisits = [...visits];
                        const index = newVisits?.findIndex((x) => x._id === selectedVisit?._id);
                        const res = await deleteVisitFromBD(newVisits[index]);
                        if (res) {
                            newVisits.splice(index, 1);
                            setVisits(newVisits);
                            setRefresh(true);
                        }
                    }}
                />
                <CardBody>
                {!refresh && (
                    <Table
                        columns={visitsTable}
                        data={getVisitsData(visits)}
                    />
                )}
                </CardBody>
                { openEditVisitDialog && (
                        <EditVisitDialog
                        open={openEditVisitDialog}
                        setOpen={setOpenEditVisitDialog}
                        visitTypes={visitTypes}
                        data={visit}
                        readOnly={false}
                        userId={patientId}
                        users={users}
                        predefPatient={true}
                        onSave={async (visit) => {
                            let newVisits = visits;
                            const number = visits.findIndex((x) => x._id === visit._id)
                            if (number !== -1) newVisits[number] = visit;
                                
                            actualizeBD(visit, newVisits)
                            setRefresh(true)
                        }}
                        />
                )}
                
            </Card>
        </>
    )
}


