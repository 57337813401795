import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
	deleteBrand,
	getBrandById,
	postBrand,
	updateBrand,
} from "../../../../api/brand";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyBrand() {
	return {
		name: null,
		active: true,
	};
}

export default function EditBrandPage() {
	const [brand, setBrand] = useState(getEmptyBrand());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);

	const brandId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	const [changes, setChanges] = useState(-2);

	useEffect(() => {
		if (!brandId) setChanges(changes+2);
		else setChanges(changes+1);
	}, [brand])

	useEffect(() => {
		if (!brandId) {
			disableLoadingData();
			return;
		}
		getBrandById(brandId)
			.then((res) => {
				if (res.status === 200) {
					setBrand(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get brand.",
				});
				history.push("/brand");
			});
	}, [brandId, disableLoadingData, history]);

	function saveBrand() {
		if (!brand.name)
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		if (!brandId) {
			postBrand(brand)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Brand successfully created.",
						});
						history.push("/brand");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save brand.",
					});
				});
		} else {
			updateBrand(brandId, brand)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/brand");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		setBrand({
			...brand,
			[element]: event.target.value,
		});
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`name`}
					label="Name"
					value={
						(brand.name) || ""
					}
					onChange={handleChange("name")}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
				<CardHeader title={brand?._id?'Brand: ' + (brand.name? brand.name:''):'New brand'}>
					<div className="mt-5">
						<Button
							onClick={() => {
								if (changes > 0) setOpenConfirmDialog(1);
								else history.push("/brand");
							}}
							variant="outlined"
							style={{ marginRight: "20px" }}
						>
							Back
						</Button>
						<Button
							onClick={() => saveBrand()}
							variant="outlined"
							color="primary"
							style={{ marginRight: "20px" }}
						>
							Save brand
						</Button>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								history.push("/brand")
							}}
						/>
						{brandId && user?.role.includes("admin") && (
							<>
								<MuiThemeProvider theme={theme}>
									<Button
										onClick={() => setOpenConfirmDialog(2)}
										variant="outlined"
										color="secondary"
										style={{ marginRight: "20px" }}
									>
										Delete brand
									</Button>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											marginLeft: "auto",
										}}
									></div>
								</MuiThemeProvider>

								<ConfirmDialog
									title={
										"Are you sure you want to delete this brand?"
									}
									open={openConfirmDialog === 2}
									setOpen={setOpenConfirmDialog}
									onConfirm={() => {
										deleteBrand(brandId)
											.then((res) => {
												if (
													res.status === 204 ||
													res.status === 200
												) {
													alertSuccess({
														title: "Deleted!",
														customMessage:
															"Brand deleted successfully",
													});
													history.push("/brand");
												}
											})
											.catch((error) => {
												alertError({
													error: error,
													customMessage:
														"Could not delete brand.",
												});
											});
									}}
								/>
							</>
						)}
					</div>
				</CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={brand.active}
									onChange={() =>
										setBrand({
											...brand,
											active: !brand.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
			</>
		);
}
